import type {
  ControllerFieldState,
  ControllerRenderProps,
} from 'react-hook-form';

import { YStack } from 'tamagui';

import { BodyM } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { TextInput } from '@south-street-app/molecules';

import { CheckFieldValidation } from './CheckFieldValidation';

const min8CharsRegex = /^.{8,}$/;
const atLeastOneNumberRegex = /.*\d.*/;
const atLeastOneSpecialCharRegex = /[!@#$^&*]/;
const atLeastOneUppercaseCharRegex = /.*[A-Z].*/;
const atLeastOneLowercaseCharRegex = /.*[a-z].*/;

type PasswordFormType = {
  password: string;
};

const passwordRules = {
  required: {
    value: true,
    message: en_US.passwordIsRequired,
  },
  pattern: {
    value: /^(?=.*\d)(?=.*[!@#$^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    message: en_US.passwordMustContain,
  },
};

const PasswordForm = ({
  field: { onChange, value },
  fieldState: { error, invalid },
}: {
  field: ControllerRenderProps<PasswordFormType, 'password'>;
  fieldState: ControllerFieldState;
}) => (
  <>
    <TextInput
      placeholder={en_US.password}
      onChangeText={onChange}
      value={value}
      autoCapitalize={'none'}
      secureTextEntry={true}
    />
    {invalid ? (
      <BodyM paddingTop={'$1'} fontWeight={'$2'} color={'$accentRed300'}>
        {error?.message}
      </BodyM>
    ) : null}
    <YStack paddingTop={'$3'}>
      <CheckFieldValidation
        isValid={min8CharsRegex.test(value)}
        text={en_US.passwordRules.eightCharacters}
      />
      <CheckFieldValidation
        isValid={atLeastOneUppercaseCharRegex.test(value)}
        text={en_US.passwordRules.oneUppercaseCharacter}
      />
      <CheckFieldValidation
        isValid={atLeastOneLowercaseCharRegex.test(value)}
        text={en_US.passwordRules.oneLowercaseCharacter}
      />
      <CheckFieldValidation
        isValid={atLeastOneNumberRegex.test(value)}
        text={en_US.passwordRules.oneNumber}
      />
      <CheckFieldValidation
        isValid={atLeastOneSpecialCharRegex.test(value)}
        text={en_US.passwordRules.oneSpecialCharacter}
      />
    </YStack>
  </>
);

export { PasswordForm, passwordRules };
export type { PasswordFormType };
