import { SafeAreaView, StyleSheet } from 'react-native';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { useMedia, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { HeadingXL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { useMobileTradeStore } from '@south-street-app/stores';

import { FirmMultiplePreviewTradeOrder } from './FirmMultiplePreviewTradeOrder';
import { FirmSinglePreviewOrder } from './FirmSinglePreviewOrder';
import { MultiplePreviewTradeOrder } from './MultiplePreviewTradeOrder';
import { SinglePreviewTradeOrder } from './SinglePreviewTradeOrder';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: getColorTokenValue('$mono100'),
  },
});

const PreviewTradeOrderScreen = () => {
  const { proposal, shares } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      shares: state.shares,
    })),
  );
  const { desktop } = useMedia();

  return (
    <SafeAreaView style={styles.container}>
      <YStack
        space={'$7'}
        paddingHorizontal={'$4'}
        paddingVertical={desktop ? '$4' : '$7'}
        flex={1}
        {...(desktop && {
          width: 800,
          marginHorizontal: '$auto',
          justifyContent: 'flex-start',
        })}
      >
        <HeadingXL fontWeight={'$3'}>{en_US.confirmTrade}</HeadingXL>

        {proposal.components.length === 1 ? (
          shares.length >= 1 ? (
            <FirmSinglePreviewOrder />
          ) : (
            <SinglePreviewTradeOrder />
          )
        ) : shares.length >= 1 ? (
          <FirmMultiplePreviewTradeOrder />
        ) : (
          <MultiplePreviewTradeOrder />
        )}
      </YStack>
    </SafeAreaView>
  );
};

export { PreviewTradeOrderScreen };
