const sizesSpaces = {
  hairline: 1,
  0: 0,
  px: 1,
  '0.5': 2,
  1: 4,
  '1.5': 6,
  2: 8,
  '2.5': 10,
  3: 12,
  '3.5': 14,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
  8: 32,
  9: 36,
  '9.5': 37,
  10: 40,
  11: 44,
  12: 48,
  13: 51,
  14: 56,
  15: 60,
  16: 64,
  18: 72,
  20: 80,
  21: 82,
  22: 90,
  24: 96,
  '24.5': 100,
  25: 104,
  26: 109,
  28: 112,
  30: 120,
  32: 128,
  33: 133,
  36: 144,
  40: 160,
  43: 172,
  44: 176,
  48: 192,
  49: 196,
  52: 208,
  56: 224,
  60: 240,
  70: 280,
  72: 288,
  80: 320,
  96: 384,
  150: 600,
  200: 800,
  'fit-content': 'fit-content',
  auto: 'auto',
  half: '50%',
  quarter: '25%',
  '3/4': '75%',
  '100%': '100%',
  true: 16,
};

export { sizesSpaces };
