import type { AvatarProps, IMessage } from 'react-native-gifted-chat';

import { YStack } from 'tamagui';

import { getInitials } from '@shared/utils';
import { BodyM } from '@south-street-app/atoms';
import { useMobileTradeStore } from '@south-street-app/stores';

const ChatAvatar = (props: AvatarProps<IMessage>) => {
  const chatTrader = useMobileTradeStore((state) => state.chatTrader);

  return props.position === 'left' ? (
    <YStack
      backgroundColor={'$brand500'}
      height={'$6'}
      width={'$6'}
      borderRadius={'$xl'}
      alignItems={'center'}
      justifyContent={'center'}
      marginRight={'$3'}
    >
      <BodyM color={'$mono100'} fontSize={'$1'} fontWeight={'$2'}>
        {getInitials(chatTrader)}
      </BodyM>
    </YStack>
  ) : null;
};

export { ChatAvatar };
