import { Platform, StyleSheet } from 'react-native';

import { YStack } from 'tamagui';

import { LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';

const styles = StyleSheet.create({
  pressStyle: {
    opacity: 0.7,
  },
});

type PreviewTradeButtonProps = {
  isReadyToPreview: boolean;
  onPress: () => void;
};

const PreviewTradeButton = ({
  isReadyToPreview,
  onPress,
}: PreviewTradeButtonProps) => (
  <YStack
    backgroundColor={isReadyToPreview ? '$brand500' : '$mono300'}
    disabled={!isReadyToPreview}
    borderRadius={'$xl'}
    padding={'$4'}
    {...(Platform.OS === 'web' ? { cursor: 'pointer' } : undefined)}
    alignItems={'center'}
    pressStyle={styles.pressStyle}
    onPress={onPress}
  >
    <LabelL
      fontSize={'$4'}
      fontWeight={'$2'}
      letterSpacing={'$2'}
      color={'$mono100'}
    >
      {en_US.previewTrade}
    </LabelL>
  </YStack>
);

export { PreviewTradeButton };
