import type {
  ControllerRenderProps,
  ControllerFieldState,
} from 'react-hook-form';

import { BodyM } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { TextInput } from '@south-street-app/molecules';

type PasswordFormType = {
  password: string;
};

const passwordRules = {
  required: {
    value: true,
    message: en_US.passwordIsRequired,
  },
  minLength: 8,
};

const RenderPassword = ({
  field: { onChange, value },
  fieldState: { error, invalid },
  onSubmit,
}: {
  field: ControllerRenderProps<PasswordFormType, 'password'>;
  fieldState: ControllerFieldState;
  onSubmit: () => void;
}) => (
  <>
    <TextInput
      placeholder={en_US.password}
      onChangeText={onChange}
      value={value}
      autoCapitalize={'none'}
      secureTextEntry={true}
      onSubmitEditing={onSubmit}
      autoFocus={true}
    />
    {invalid || error ? (
      <BodyM paddingTop={'$1'} fontWeight={'$2'} color={'$accentRed300'}>
        {error?.message}
      </BodyM>
    ) : null}
  </>
);

export { RenderPassword, passwordRules };
export type { PasswordFormType };
