import type { ReactNode } from 'react';

import type { LayoutChangeEvent } from 'react-native';
import { ScrollView, StyleSheet, SafeAreaView } from 'react-native';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';

type ScreenWrapperScrollProps = {
  children: ReactNode;
  onLayout?: (event: LayoutChangeEvent) => void;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: getColorTokenValue('$mono100'),
  },
});

const ScreenWrapperScroll = ({
  children,
  onLayout,
}: ScreenWrapperScrollProps) => (
  <SafeAreaView onLayout={onLayout} style={styles.container}>
    <ScrollView showsVerticalScrollIndicator={false}>{children}</ScrollView>
  </SafeAreaView>
);

export { ScreenWrapperScroll };
