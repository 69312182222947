import type { ToastProps } from 'react-native-toast-message';

import { StyleSheet, View } from 'react-native';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import {
  ErrorToast,
  InfoToast,
  SuccessToast,
} from 'react-native-toast-message';

import { BodyL } from '@south-street-app/atoms';

const styles = StyleSheet.create({
  customToast: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: getColorTokenValue('$mono800'),
    width: '98%',
  },
});

const toastConfig = {
  success: (props: ToastProps) => <SuccessToast {...props} />,
  error: (props: ToastProps) => <ErrorToast {...props} />,
  info: (props: ToastProps) => <InfoToast {...props} />,
  debug: (props: ToastProps) => <InfoToast {...props} />,
  warning: (props: ToastProps) => <InfoToast {...props} />,

  tomatoToast: ({ text1 }: { text1?: string }) => (
    <View style={styles.customToast}>
      <BodyL color={'$mono100'}>{text1}</BodyL>
    </View>
  ),
};

export { toastConfig };
