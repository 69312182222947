import type { NavigationProp } from '@react-navigation/native';
import type { PrivateStackNavigatorParamList } from '@south-street-app/navigation/types';

import { useCallback } from 'react';

import { useNavigation } from '@react-navigation/native';
import { XStack, YStack } from 'tamagui';

import { BodyXL, HeadingXL, LabelL } from '@south-street-app/atoms';
import { ButtonLarge } from '@south-street-app/molecules';
import { useGlobalBottomSheetStore } from '@south-street-app/stores';

const TradeInProgressBottomSheetContent = () => {
  const navigation =
    useNavigation<NavigationProp<PrivateStackNavigatorParamList>>();
  const closeBottomSheet = useGlobalBottomSheetStore(
    (state) => state.closeBottomSheet,
  );
  const handleOnPressTradeInProgress = useCallback(() => {
    closeBottomSheet();

    return navigation.navigate('HomeTab', { screen: 'HomeScreen' });
  }, [closeBottomSheet, navigation]);

  return (
    <YStack justifyContent={'center'} padding={'$4'} flex={1}>
      <XStack
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        paddingTop={'$4'}
        paddingBottom={'$2'}
      >
        <HeadingXL maxWidth={'$60'} fontWeight={'$3'}>
          {'Trade request in progress'}
        </HeadingXL>
      </XStack>
      <BodyXL>
        {`The app was closed before the trade was completed. Please check trade history to see the final status of your trade request (it may take a minute or two).
      
You won't be able to request a new trade until your current trade request is complete`}
      </BodyXL>
      <XStack justifyContent={'space-between'} space={'$3'} paddingTop={'$9'}>
        <ButtonLarge
          onPress={handleOnPressTradeInProgress}
          variant={'primary'}
          flex={1}
        >
          <LabelL fontWeight={'$3'} color={'$mono100'}>
            {'Got it'}
          </LabelL>
        </ButtonLarge>
      </XStack>
    </YStack>
  );
};

export { TradeInProgressBottomSheetContent };
