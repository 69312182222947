import type { PasswordFormType } from './PasswordForm';
import type { PublicStackNavigationProps } from '@south-street-app/navigation/types';

import { StyleSheet } from 'react-native';

import { useAuthStore } from '@utility-nyc/react-auth-storage';
import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { useForm, Controller } from 'react-hook-form';
import { SafeAreaView } from 'react-native-safe-area-context';
import { YStack } from 'tamagui';

import { HeadingS } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { ButtonLarge } from '@south-street-app/molecules';
import { useSetPassword } from '@utility-nyc/react-query-sdk';

import { AgreementsFooter } from './AgreementsFooter';
import { PasswordForm, passwordRules } from './PasswordForm';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: getColorTokenValue('$mono200'),
  },
});

const SetPasswordScreen = ({
  route,
  navigation,
}: PublicStackNavigationProps<'SetPasswordScreen'>) => {
  const { signature, code } = route.params;

  const decodedSignature = decodeURIComponent(signature);
  const decodedCode = decodeURIComponent(code);

  const login = useAuthStore((state) => state.setTokens);

  const { mutate: sendCredentials, isLoading } = useSetPassword();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isDirty },
  } = useForm<PasswordFormType>({
    mode: 'onBlur',
    defaultValues: {
      password: '',
    },
  });
  const handleOnSubmit = handleSubmit((data) => {
    sendCredentials(
      {
        data: {
          signature: decodedSignature,
          code: decodedCode,
          credential: data.password,
          confirmCredential: data.password,
        },
      },
      {
        onSuccess: (res) => {
          login({
            accessToken: res.data.tokens.accessToken,
            refreshToken: res.data.tokens.refreshToken,
          });

          navigation.navigate('LoginPhoneCodeScreen', {
            email: res.data.email,
            password: data.password,
          });
        },
        onError: (error) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (error.response?.data?.error?.message?.includes('expired')) {
            setError('password', {
              type: 'manual',
              message: en_US.invitationExpired,
            });
          } else {
            setError('password', {
              type: 'manual',
              message: en_US.somethingWentWrong,
            });
          }
        },
      },
    );
  });

  return (
    <SafeAreaView style={styles.container}>
      <YStack
        flex={1}
        justifyContent={'space-between'}
        paddingHorizontal={'$4'}
      >
        <YStack space={'$4'}>
          <HeadingS fontWeight={'$2'}>{en_US.setPassword}</HeadingS>
          <Controller
            control={control}
            name={'password'}
            rules={passwordRules}
            render={PasswordForm}
          />
        </YStack>
        <YStack gap={'$4'}>
          <AgreementsFooter />
          <ButtonLarge
            disabled={isLoading || !isValid || !isDirty}
            loading={isLoading}
            onPress={handleOnSubmit}
          >
            {en_US.continue}
          </ButtonLarge>
        </YStack>
      </YStack>
    </SafeAreaView>
  );
};

export { SetPasswordScreen };
