import { useFonts } from 'expo-font';

import { fonts } from '@shared/assets';

const useCustomFonts = () =>
  useFonts({
    InterBold: fonts.InterBold,
    InterRegular: fonts.InterRegular,
    InterSemibold: fonts.InterSemibold,
  });

export { useCustomFonts };
