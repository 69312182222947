import { useCallback } from 'react';

import { useMedia, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { en_US } from '@south-street-app/configs';
import { SlideButton } from '@south-street-app/molecules';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';
import { useGetCurrentEndUser } from '@utility-nyc/react-query-sdk';

import { DisplayTradeInfo } from './DisplayTradeInfo';

const SinglePreviewTradeOrder = () => {
  const { data } = useGetCurrentEndUser();
  const { desktop } = useMedia();
  const {
    createTradeRequest,
    setReceivedQuotedPrice,
    proposal,
    tradeType,
    isTwilioConnected,
  } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      isTwilioConnected: state.isTwilioConnected,
      createTradeRequest: state.createTradeRequest,
      setReceivedQuotedPrice: state.setReceivedQuotedPrice,
      tradeType: state.tradeType,
    })),
  );

  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );

  const firstComponent = proposal.components[0];

  const handleOnCompleted = useCallback(() => {
    try {
      if (data?.data.id) {
        setReceivedQuotedPrice(false);

        createTradeRequest({
          components: [
            {
              productId: firstComponent.product.id,
              amount: firstComponent.amount,
              type: firstComponent.type,
            },
          ],
          shares: [
            {
              amount: firstComponent.amount,
              type: firstComponent.type,
              associatedId: data.data.id,
            },
          ],
          endUserId: data.data.id,
          type: tradeType,
        });

        openBottomSheet({
          type: 'requestTrade',
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    createTradeRequest,
    firstComponent,
    openBottomSheet,
    data,
    setReceivedQuotedPrice,
    tradeType,
  ]);

  return (
    <YStack
      flex={1}
      justifyContent={'space-between'}
      {...(desktop && {
        width: 800,
        marginHorizontal: '$auto',
        justifyContent: 'flex-start',
        gap: '$16',
      })}
    >
      <DisplayTradeInfo order={proposal.components[0]} />
      <SlideButton
        isLoading={!isTwilioConnected}
        label={
          isTwilioConnected
            ? en_US.submitTradeRequest
            : en_US.connectingToTradeNetwork
        }
        onCompleted={handleOnCompleted}
      />
    </YStack>
  );
};

export { SinglePreviewTradeOrder };
