import { useEffect, useMemo } from 'react';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useMedia, YStack } from 'tamagui';

import { HeadingM, LabelS } from '@south-street-app/atoms';

const AnimatedYStack = Animated.createAnimatedComponent(YStack);

const SelectorItem = ({
  label,
  selected,
  onPress,
  width,
}: {
  label: string;
  selected: boolean;
  onPress?: () => void;
  width?: number;
}) => {
  const animatedValue = useSharedValue(0);
  const { desktop } = useMedia();
  const LabelToUse = desktop ? HeadingM : LabelS;
  const AnimatedLabel = Animated.createAnimatedComponent(LabelToUse);

  useEffect(() => {
    if (selected) {
      animatedValue.value = withTiming(1, { duration: 100 });
    } else {
      animatedValue.value = withTiming(0, { duration: 100 });
    }
  }, [selected, animatedValue]);

  const brand500Color = useMemo(() => getColorTokenValue('$brand500'), []);
  const mono200Color = useMemo(() => getColorTokenValue('$mono200'), []);

  const animatedStyle = useAnimatedStyle(() => ({
    backgroundColor: desktop
      ? 'transparent'
      : interpolateColor(
          animatedValue.value,
          [0, 1],
          [mono200Color, brand500Color],
        ),
  }));

  const animatedColorStyle = useAnimatedStyle(() => ({
    color: interpolateColor(animatedValue.value, [0, 1], ['black', 'white']),
  }));

  return (
    <AnimatedYStack
      paddingHorizontal={'$5'}
      paddingVertical={'$5'}
      alignItems={'center'}
      style={[animatedStyle]}
      borderRadius={desktop ? '$none' : '$xl'}
      flex={1}
      zIndex={1}
      onPress={onPress}
      width={width}
    >
      <AnimatedLabel fontWeight={'$3'} style={animatedColorStyle}>
        {label}
      </AnimatedLabel>
    </AnimatedYStack>
  );
};

export { SelectorItem };
