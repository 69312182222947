import type { GetProps } from 'tamagui';

import { styled, Button as Frame } from 'tamagui';
type ButtonProps = GetProps<typeof Button>;

const BASE_BUTTON_VARIANTS = {
  variant: {
    primary: {
      backgroundColor: '$brand500',
      pressStyle: {
        backgroundColor: '$brand600',
      },
    },
    secondary: {
      backgroundColor: '$mono100',
      color: '$brand500',
      fontWeight: '$2',
      borderColor: '$brand500',
      borderWidth: '$px',
      pressStyle: {
        borderColor: '$brand600',
      },
    },
    disabled: {
      backgroundColor: '$mono300',
    },
  },
} as const;

const Button = styled(Frame, {
  unstyled: true,
  color: '$mono100',
  borderRadius: '$xl',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$3',
  fontWeight: '$4',
  variants: BASE_BUTTON_VARIANTS,
  flexDirection: 'row',
  defaultVariants: {
    variant: 'primary',
  },
});

export { Button, BASE_BUTTON_VARIANTS };
export type { ButtonProps };
