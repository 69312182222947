import type { SizeTokens } from 'tamagui';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { YStack } from 'tamagui';

import { BodyM } from '@south-street-app/atoms';

import { Dot } from './Dot';

type SpinnerProps = {
  label: string;
  labelMaxWidth?: SizeTokens;
  animationSpeed?: 'slow' | 'medium' | 'fast';
};

const dots = [
  { x: 0, y: -18 },
  { x: 13, y: -13 },
  { x: 18, y: 0 },
  { x: 13, y: 13 },
  { x: 0, y: 18 },
  { x: -13, y: 13 },
  { x: -18, y: 0 },
  { x: -13, y: -13 },
];

const animationSpeedMap = {
  slow: 200,
  medium: 150,
  fast: 100,
};

const Spinner = ({
  label,
  labelMaxWidth = '$32',
  animationSpeed = 'slow',
}: SpinnerProps) => (
  <YStack alignItems={'center'} space={'$4'}>
    <YStack alignItems={'center'} paddingTop={'$10'}>
      <YStack
        borderRadius={getSpaceTokenValue('$24.5')}
        backgroundColor={'$mono200'}
        justifyContent={'center'}
        alignItems={'center'}
        height={getSpaceTokenValue('$24.5')}
        width={getSpaceTokenValue('$24.5')}
      >
        {dots.map((dot, index) => (
          <Dot
            key={index}
            {...dot}
            delay={index * animationSpeedMap[animationSpeed]}
          />
        ))}
      </YStack>
    </YStack>
    {label ? (
      <BodyM
        textAlign={'center'}
        maxWidth={labelMaxWidth}
        color={'$mono900'}
        fontSize={'$4'}
        lineHeight={'$5'}
      >
        {label}
      </BodyM>
    ) : null}
  </YStack>
);

export { Spinner };
