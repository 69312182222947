import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { BackendInternalErrorProvider } from '../backend-internal-error/BackendInternalErrorProvider';

// Create a client
const queryClient = new QueryClient();

const QueryProvider = ({ children }: { children: ReactNode }) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // Handle global errors
    queryClient.setDefaultOptions({
      queries: {
        onError: (error) => {
          // Handle the error here (e.g., logging, displaying an error message, etc.)
          console.error('An error occurred:', error);

          if (
            error &&
            typeof error === 'object' &&
            'response' in error &&
            error.response &&
            typeof error.response === 'object' &&
            'status' in error.response &&
            error.response.status &&
            typeof error.response.status === 'number' &&
            error.response.status >= 500 &&
            error.response.status < 600
          ) {
            setIsError(true);
          }
        },
        onSuccess: () => {
          setIsError(false);
        },
      },
    });
  }, []);

  const onRefetch = useCallback(() => {
    queryClient.refetchQueries();
  }, []);

  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <BackendInternalErrorProvider isError={isError} onRefetch={onRefetch}>
        {children}
      </BackendInternalErrorProvider>
    </QueryClientProvider>
  );
};

export { QueryProvider, queryClient };
