const account = {
  user: {
    name: 'John Smith',
    email: 'John_Smith@email.com',
    companyName: 'Name',
  },
  contactUs: `To reset password please call +12128240734`,
  contactUsEmailMessage: 'For other issues and questions please email us at ',
  contactUsEmail: 'mbstrading@sssnyc.com',
  privacyPolicy: {
    headerTitle: 'Privacy Policy',
    title: 'Privacy Policy',
    description: '',
    linkLabel: 'Go to Privacy Policy',
    url: 'https://www.southstreetsecurities.com/',
  },
  termsAndConditions: {
    headerTitle: 'Terms and Conditions',
    title: 'Terms and Conditions',
    description: '',
    linkLabel: 'Go to Terms and Conditions',
    url: 'https://www.southstreetsecurities.com/',
  },
  about: {
    headerTitle: 'About',
    title: 'South Street Securities',
    description: `Please visit the link below to learn more about South Street's mortgage trading business.`,
    linkLabel: 'SSS mortgage trading',
    url: 'https://www.southstreetsecurities.com/',
  },
};

export { account };
