import type { TradeRequestComponentDto } from '@utility-nyc/react-query-sdk';

import { capitalizeFirstLetter } from './capitalizeFirstLetter';
import { formatToMoney } from './formatToMoney';
import { getShortMonthName } from './getShortMonthName';

const formattedTradeDetails = (component: TradeRequestComponentDto) =>
  `${capitalizeFirstLetter(component.type)} $${formatToMoney(
    component.amount,
  )} ${component.product.name} ${component.product.coupon} ${getShortMonthName(
    component.product.month,
  )}`;

export { formattedTradeDetails };
