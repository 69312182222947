import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { type CheckboxProps, Checkbox as TamaguiCheckbox } from 'tamagui';

import { Icon } from '@south-street-app/atoms';

const Checkbox = ({
  size,
  checked = false,
  id,
  ...checkboxProps
}: CheckboxProps) => (
  <TamaguiCheckbox
    padding={'$1'}
    id={id}
    style={{
      backgroundColor: checked
        ? getColorTokenValue('$brand300')
        : getColorTokenValue('$mono100'),
    }}
    size={size}
    borderWidth={checked ? '$0' : '$px'}
    checked={checked ? true : false}
    backgroundColor={checked ? '$brand300' : '$mono100'}
    onCheckedChange={checkboxProps.onCheckedChange}
    {...checkboxProps}
  >
    <TamaguiCheckbox.Indicator padding={'$1'}>
      <Icon iconName={'checkIcon'} size={'$5'} color={'$mono100'} />
    </TamaguiCheckbox.Indicator>
  </TamaguiCheckbox>
);

export { Checkbox };
