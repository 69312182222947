import { useAuthStore } from '@utility-nyc/react-auth-storage';

type NavigationExperience = {
  isAuthenticated: boolean;
};

const useNavigationExperience = (): NavigationExperience => {
  const isAuthenticated = useAuthStore(
    (state) => !!state.accessToken && !!state.refreshToken,
  );

  return {
    isAuthenticated,
  };
};

export { useNavigationExperience };
