import type { PublicStackNavigationProps } from '@south-street-app/navigation/types';

import { useForm, Controller } from 'react-hook-form';
import { YStack, useMedia } from 'tamagui';

import { BodyL, BodyM, HeadingS } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import {
  TextInput,
  ButtonLarge,
  ScreenWrapperKAV,
} from '@south-street-app/molecules';
import { useVerifyOtp } from '@utility-nyc/react-query-sdk';

import { ResendcodeButton } from './ResendCodeButton';

type PasswordFormType = {
  code: string;
};

const LoginPhoneCodeScreen = ({
  route,
}: PublicStackNavigationProps<'LoginPhoneCodeScreen'>) => {
  const { email, password } = route.params;
  const { desktop } = useMedia();
  const { mutate } = useVerifyOtp();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, isDirty },
  } = useForm<PasswordFormType>({
    mode: 'onBlur',
    defaultValues: {
      code: '',
    },
  });

  const handleOnSubmit = handleSubmit((data) => {
    mutate(
      {
        data: {
          code: data.code,
        },
      },
      {
        onError() {
          setError('code', {
            type: 'manual',
            message: en_US.incorrectCode,
          });
        },
      },
    );
  });

  return (
    <ScreenWrapperKAV>
      <YStack
        flex={1}
        paddingHorizontal={'$4'}
        justifyContent={desktop ? 'center' : 'space-between'}
        paddingTop={desktop ? '$0' : '$15'}
        {...(desktop && {
          width: '$150',
          margin: '$auto',
          gap: '$7',
        })}
      >
        <YStack space={'$4'}>
          <HeadingS fontWeight={'$2'}>{en_US.sixDigitCode}</HeadingS>
          <BodyL>{en_US.codeSent}</BodyL>
          <Controller
            control={control}
            name={'code'}
            rules={{
              required: {
                value: true,
                message: en_US.enter6DigitCode,
              },
              minLength: 6,
            }}
            render={({
              field: { onChange, value },
              fieldState: { error, invalid },
            }) => (
              <>
                <TextInput
                  onChangeText={onChange}
                  value={value}
                  autoCapitalize={'none'}
                  keyboardType={'number-pad'}
                  returnKeyType={'done'}
                  onSubmitEditing={handleOnSubmit}
                  autoFocus={true}
                />
                {invalid ? (
                  <BodyM
                    paddingTop={'$1'}
                    fontWeight={'$2'}
                    color={'$accentRed300'}
                  >
                    {error?.message}
                  </BodyM>
                ) : null}
              </>
            )}
          />
          <ResendcodeButton email={email} password={password} />
        </YStack>
        <ButtonLarge disabled={!isValid || !isDirty} onPress={handleOnSubmit}>
          {en_US.continue}
        </ButtonLarge>
      </YStack>
    </ScreenWrapperKAV>
  );
};

export { LoginPhoneCodeScreen };
