import { useCallback } from 'react';

import { Platform } from 'react-native';

import { useMedia, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { HeadingXL, LabelL } from '@south-street-app/atoms';
import { ButtonLarge } from '@south-street-app/molecules';
import { useGlobalBottomSheetStore } from '@south-street-app/stores';

const BackendErrorBottomSheetContent = () => {
  const { desktop } = useMedia();
  const { closeBottomSheet, resetBottomSheet } = useGlobalBottomSheetStore(
    useShallow((state) => ({
      closeBottomSheet: state.closeBottomSheet,
      resetBottomSheet: state.resetBottomSheet,
    })),
  );

  const handleOnClose = useCallback(() => {
    resetBottomSheet();

    closeBottomSheet();
  }, [closeBottomSheet, resetBottomSheet]);

  return (
    <YStack
      justifyContent={'center'}
      alignItems={desktop ? 'center' : 'flex-start'}
      padding={'$4'}
      {...(Platform.OS === 'web'
        ? { width: '$fit-content', marginHorizontal: '$auto' }
        : undefined)}
      flex={1}
    >
      <XStack
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        paddingTop={'$4'}
        paddingBottom={'$2'}
      >
        <HeadingXL
          maxWidth={'$70'}
          textAlign={desktop ? 'center' : undefined}
          fontWeight={'$3'}
        >
          {'Oops, something went wrong'}
        </HeadingXL>
      </XStack>
      {/* Commented until we handle special BE error cases */}
      {/*  <BodyXL>{error}</BodyXL> */}
      <XStack
        {...(Platform.OS === 'web' ? { width: '$100%' } : undefined)}
        justifyContent={desktop ? 'center' : 'space-between'}
        space={'$3'}
        paddingTop={'$9'}
      >
        <ButtonLarge
          width={desktop ? '$100%' : undefined}
          onPress={handleOnClose}
          variant={'primary'}
          flex={1}
        >
          <LabelL
            width={desktop ? '$100%' : undefined}
            fontWeight={'$3'}
            color={'$mono100'}
          >
            {'Got it'}
          </LabelL>
        </ButtonLarge>
      </XStack>
    </YStack>
  );
};

export { BackendErrorBottomSheetContent };
