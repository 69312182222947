import { useCallback, useState } from 'react';

import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMedia, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { HeadingXL, LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { ButtonLarge, ScreenWrapperScroll } from '@south-street-app/molecules';
import { useMobileTradeStore } from '@south-street-app/stores';

import { DisplayMultipleTradeInfo } from './DisplayMultipleTradeInfo';
import { DisplaySingleTradeInfo } from './DisplaySingleTradeInfo';

const TradeConfirmationScreen = () => {
  const [height, setHeight] = useState(0);
  const { desktop } = useMedia();
  const navigation = useNavigation();
  const { counterProposal, clearFirmEndUsers } = useMobileTradeStore(
    useShallow((state) => ({
      counterProposal: state.counterProposal,
      clearFirmEndUsers: state.clearFirmEndUsers,
    })),
  );
  const { bottom } = useSafeAreaInsets();

  const isMoreThanOneComponent = counterProposal.components.length > 1;

  const handleGoHome = useCallback(() => {
    clearFirmEndUsers();

    navigation.navigate('HomeTab', { screen: 'HomeScreen' });
  }, [navigation, clearFirmEndUsers]);

  return (
    <ScreenWrapperScroll
      onLayout={(event) => setHeight(event.nativeEvent.layout.height)}
    >
      <YStack
        space={'$7'}
        height={isMoreThanOneComponent ? undefined : height - bottom}
        paddingVertical={'$7'}
        paddingHorizontal={'$4'}
        justifyContent={'space-between'}
        flex={1}
        {...(desktop && {
          width: 800,
          marginHorizontal: '$auto',
          justifyContent: 'flex-start',
          gap: '$8',
        })}
      >
        <YStack>
          <HeadingXL fontWeight={'$3'}>
            {en_US.congratulationsTradeWasExecuted}
          </HeadingXL>
          {isMoreThanOneComponent ? (
            <DisplayMultipleTradeInfo counterProposal={counterProposal} />
          ) : (
            <DisplaySingleTradeInfo order={counterProposal.components[0]} />
          )}
        </YStack>
        <YStack>
          <ButtonLarge onPress={handleGoHome} variant={'primary'}>
            <LabelL fontWeight={'$3'} color={'$mono100'}>
              {en_US.goHome}
            </LabelL>
          </ButtonLarge>
        </YStack>
      </YStack>
    </ScreenWrapperScroll>
  );
};

export { TradeConfirmationScreen };
