// This is the base Tamagui config for this monorepo
// It is used by the tamagui CLI to generate typings based on this project's configuration

import { createDesignSystemConfig } from '@utility-nyc/react-native-ui-config';
import { typedKeys } from '@utility-nyc/typescript-functions';
import { createTamagui } from 'tamagui';

import { sizesSpaces, font, themes, radius, media } from './src/index';

const keyOfFirstTheme = typedKeys(themes)[0];
const colors = themes[keyOfFirstTheme];

const tamaguiConfig = createDesignSystemConfig({
  colors,
  themes,
  sizesSpaces,
  font,
  radius,
});

type TamaguiConfig = typeof tamaguiConfig;

declare module 'tamagui' {
  // overrides TamaguiCustomConfig so your custom types
  // work everywhere you import `tamagui`

  interface TamaguiCustomConfig extends TamaguiConfig {
    media: typeof media;
  }

  // This is to enforce only allowing token values from the theme
  // More info: https://tamagui.dev/docs/core/configuration#strict-tokens
  interface ThemeValueFallback {
    value: never;
  }
}

const tamaguiCustomConfig = createTamagui({
  ...tamaguiConfig,
  media,
});

export default tamaguiCustomConfig;
