// useCouponColor.js
import type { ColorTokens } from 'tamagui';

import { useRef } from 'react';

const backgroundColors = [
  '$brand100',
  '$brand200',
  '$brand300',
] as ColorTokens[];
const textColors = ['$mono900', '$mono900', '$mono100'] as ColorTokens[];

const useCouponColor = () => {
  const colorIndex = useRef<number>(0);
  const couponColors = useRef<
    Record<
      string,
      Record<number, { backgroundColor: ColorTokens; textColor: ColorTokens }>
    >
  >({});

  const getColorForCoupon = (
    selectedProduct: string,
    currentCoupon: number,
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!couponColors.current[selectedProduct]) {
      colorIndex.current = 0;

      couponColors.current = {
        ...couponColors.current,
        [selectedProduct]: {
          [currentCoupon]: {
            backgroundColor: backgroundColors[0],
            textColor: textColors[0],
          },
        },
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (couponColors.current[selectedProduct][currentCoupon]) {
      return couponColors.current[selectedProduct][currentCoupon];
    }

    colorIndex.current = (colorIndex.current + 1) % backgroundColors.length;

    couponColors.current = {
      ...couponColors.current,
      [selectedProduct]: {
        ...couponColors.current[selectedProduct],
        [currentCoupon]: {
          backgroundColor: backgroundColors[colorIndex.current],
          textColor: textColors[colorIndex.current],
        },
      },
    };

    return couponColors.current[selectedProduct][currentCoupon];
  };

  return { getColorForCoupon };
};

export { useCouponColor };
