import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { TradingNavigatorParamList } from '@south-street-app/navigation/types';
import type { TradeRequestComponentDto } from '@utility-nyc/react-query-sdk';

import { useCallback, useEffect, useState } from 'react';

import { FlatList, Keyboard, StyleSheet, TouchableOpacity } from 'react-native';

import { useMedia, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { MINIMUM_TRADE_AMOUNT } from '@shared/services';
import { capitalizeFirstLetter, moneyStringToNumber } from '@shared/utils';
import { HeadingM, Icon, LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { InitialsCircle } from '@south-street-app/molecules';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';

import { PreviewTradeButton } from './PreviewTradeButton';
import { SwapButtom } from './SwapButton';
import { TradeItem } from './TradeItem';
import { combinedProductName } from './combinedProductName';

type FirmSwapTradeOrderProps = {
  navigation: NativeStackNavigationProp<
    TradingNavigatorParamList,
    'TradeOrder'
  >;
};

type SwapTradeItemProps = {
  topTradeInput: Omit<TradeRequestComponentDto, 'price'>;
  handleTopTradeInputOnChangeValue: (value: string, userId: string) => void;
  handleOpenProductList: () => void;
  bottomTradeInput: Omit<TradeRequestComponentDto, 'price'>;
  handleBottomTradeInputOnChangeValue: (value: string, userId: string) => void;
  handleSwap: () => void;
  userName: string;
  userId: string;
  deleteUser: (userId: string) => void;
};

const styles = StyleSheet.create({
  flatlist: {
    gap: 16,
  },
});

const SwapTradeItem = ({
  bottomTradeInput,
  handleBottomTradeInputOnChangeValue,
  handleOpenProductList,
  topTradeInput,
  handleTopTradeInputOnChangeValue,
  handleSwap,
  userName,
  userId,
  deleteUser,
}: SwapTradeItemProps) => {
  const deleteUserById = () => {
    deleteUser(userId);
  };

  const handleTopInputChange = (value: string) => {
    handleTopTradeInputOnChangeValue(value, userId);
  };

  const handleBottomInputChange = (value: string) => {
    handleBottomTradeInputOnChangeValue(value, userId);
  };

  return (
    <YStack
      padding={'$4'}
      borderWidth={'$px'}
      borderColor={'$mono200'}
      borderRadius={'$l'}
      space={'$4'}
    >
      <XStack
        alignItems={'center'}
        borderBottomWidth={'$px'}
        justifyContent={'space-between'}
        borderBottomColor={'$mono200'}
        paddingBottom={'$2'}
      >
        <XStack gap={'$2'} alignItems={'center'}>
          <InitialsCircle usePadding={false} name={userName} />
          <LabelL fontWeight={'$4'} color={'$black'}>
            {userName}
          </LabelL>
        </XStack>
        <TouchableOpacity onPress={deleteUserById} activeOpacity={0.6}>
          <Icon iconName={'deleteIcon'} color={'$mono400'} size={'$5'} />
        </TouchableOpacity>
      </XStack>
      <TradeItem
        label={capitalizeFirstLetter(topTradeInput.type)}
        product={combinedProductName(topTradeInput)}
        onChangeValue={handleTopInputChange}
      />
      <YStack paddingVertical={'$1'}>
        <SwapButtom onPress={handleSwap} />
      </YStack>
      <TradeItem
        disabled={false}
        label={capitalizeFirstLetter(bottomTradeInput.type)}
        product={combinedProductName(bottomTradeInput)}
        onPressChooseProduct={handleOpenProductList}
        onPressChangeProduct={handleOpenProductList}
        onChangeValue={handleBottomInputChange}
        isProductChangeable={true}
      />
    </YStack>
  );
};

const FirmSwapTradeOrder = ({ navigation }: FirmSwapTradeOrderProps) => {
  const { desktop } = useMedia();
  const { proposal, setProposal, firmEndUsers, deleteEndUserById, setShares } =
    useMobileTradeStore(
      useShallow((state) => ({
        proposal: state.proposal,
        setProposal: state.setProposal,
        firmEndUsers: state.firmEndUsers,
        deleteEndUserById: state.deleteEndUserById,
        setShares: state.setShares,
      })),
    );

  const [topAmounts, setTopAmounts] = useState<Record<string, string>>(
    firmEndUsers.reduce<Record<string, string>>((acc, item) => {
      acc[item.id] = '0';

      return acc;
    }, {}),
  );

  const [bottomAmounts, setBottomAmounts] = useState<Record<string, string>>(
    firmEndUsers.reduce<Record<string, string>>((acc, item) => {
      acc[item.id] = '0';

      return acc;
    }, {}),
  );

  const setTopAmountForUser = (value: string, userId: string) => {
    setTopAmounts((prev) => ({
      ...prev,
      [userId]: value,
    }));
  };

  const setBottomAmountForUser = (value: string, userId: string) => {
    setBottomAmounts((prev) => ({
      ...prev,
      [userId]: value,
    }));
  };

  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );

  const firstProduct = proposal.components[0].product;

  const [topTradeInput, setTopTradeInput] = useState<
    Omit<TradeRequestComponentDto, 'price'>
  >({
    type: 'OFFER',
    product: {
      name: firstProduct.name,
      coupon: firstProduct.coupon,
      month: firstProduct.month,
      bidPrice: firstProduct.bidPrice,
      askPrice: firstProduct.askPrice,
      id: firstProduct.id,
      settlementDate: firstProduct.settlementDate,
      cusip: firstProduct.cusip,
      maturityDate: firstProduct.maturityDate,
    },
    amount: 0,
  });
  const [bottomTradeInput, setBottomTradeInput] = useState<
    Omit<TradeRequestComponentDto, 'price'>
  >({
    type: 'BID',
    product: {
      name: '-',
      coupon: 0,
      month: 1,
      bidPrice: { par: 0, fraction: 0 },
      askPrice: { par: 0, fraction: 0 },
      id: '',
      settlementDate: '',
      cusip: '',
      maturityDate: '',
    },
    amount: 0,
  });

  useEffect(() => {
    if (
      proposal.components.length > 1 &&
      proposal.components[1].product.name !== bottomTradeInput.product.name
    ) {
      setBottomTradeInput({
        ...bottomTradeInput,
        product: {
          ...bottomTradeInput.product,
          name: proposal.components[1].product.name,
          coupon: proposal.components[1].product.coupon,
          month: proposal.components[1].product.month,
          bidPrice: proposal.components[1].product.bidPrice,
          askPrice: proposal.components[1].product.askPrice,
          id: proposal.components[1].product.id,
          settlementDate: proposal.components[1].product.settlementDate,
          cusip: proposal.components[1].product.cusip,
          maturityDate: proposal.components[1].product.maturityDate,
        },
      });
    }
  }, [bottomTradeInput, proposal.components]);

  const handleSwap = useCallback(() => {
    setTopTradeInput({
      ...topTradeInput,
      type: bottomTradeInput.type,
    });

    setBottomTradeInput({
      ...bottomTradeInput,
      type: topTradeInput.type,
    });
  }, [topTradeInput, bottomTradeInput]);

  const handlePreviewTrade = useCallback(() => {
    const topTradeInputProposal = {
      ...topTradeInput,
      amount: Object.values(topAmounts).reduce(
        (acc, item) => acc + moneyStringToNumber(item),
        0,
      ),
    };
    const bottomTradeInputProposal = {
      ...bottomTradeInput,
      amount: Object.values(bottomAmounts).reduce(
        (acc, item) => acc + moneyStringToNumber(item),
        0,
      ),
    };
    const bidPrice = topTradeInputProposal.product.bidPrice
      ? {
          par: moneyStringToNumber(
            topTradeInputProposal.product.bidPrice.par.toString(),
          ),
          fraction: topTradeInputProposal.product.bidPrice.fraction,
        }
      : undefined;
    const askPrice = bottomTradeInputProposal.product.askPrice
      ? {
          par: moneyStringToNumber(
            bottomTradeInputProposal.product.askPrice.par.toString(),
          ),
          fraction: bottomTradeInputProposal.product.askPrice.fraction,
        }
      : undefined;
    const shares = firmEndUsers.map((firmEndUser) => [
      {
        amount: moneyStringToNumber(topAmounts[firmEndUser.id]),
        associatedId: firmEndUser.id,
        type: topTradeInput.type,
      },
      {
        amount: moneyStringToNumber(bottomAmounts[firmEndUser.id]),
        associatedId: firmEndUser.id,
        type: bottomTradeInput.type,
      },
    ]);

    setShares(shares.flatMap((share) => share));

    setProposal({
      ...proposal,
      components: [
        {
          ...topTradeInputProposal,
          price: bidPrice,
        },
        {
          ...bottomTradeInputProposal,
          price: askPrice,
        },
      ],
    });

    navigation.navigate('PreviewTradeOrder');
  }, [
    bottomTradeInput,
    navigation,
    proposal,
    setProposal,
    topTradeInput,
    topAmounts,
    bottomAmounts,
    firmEndUsers,
    setShares,
  ]);

  const handleOpenProductList = useCallback(() => {
    Keyboard.dismiss();

    openBottomSheet({
      type: 'productList',
    });
  }, [openBottomSheet]);

  const onDeleteUserById = (userId: string) => {
    if (firmEndUsers.length === 1) {
      navigation.goBack();

      setProposal({
        ...proposal,
        components: [proposal.components[0]],
      });
    }

    deleteEndUserById(userId);

    const newTopAmounts = { ...topAmounts };
    const newBottomAmounts = { ...bottomAmounts };

    delete newTopAmounts[userId];

    delete newBottomAmounts[userId];

    setTopAmounts(newTopAmounts);

    setBottomAmounts(newBottomAmounts);
  };

  return (
    <YStack
      flex={1}
      justifyContent={'space-between'}
      {...(desktop && {
        justifyContent: 'flex-start',
        width: 800,
        gap: '$16',
        marginHorizontal: '$auto',
      })}
    >
      <HeadingM fontWeight={'$3'} paddingTop={'$2'} paddingBottom={'$10'}>
        {en_US.swap}
      </HeadingM>
      <FlatList
        data={firmEndUsers}
        keyExtractor={(item) => item.id}
        contentContainerStyle={styles.flatlist}
        renderItem={({ item }) => (
          <SwapTradeItem
            deleteUser={onDeleteUserById}
            userName={item.name}
            userId={item.id}
            bottomTradeInput={bottomTradeInput}
            handleBottomTradeInputOnChangeValue={setBottomAmountForUser}
            handleOpenProductList={handleOpenProductList}
            topTradeInput={topTradeInput}
            handleTopTradeInputOnChangeValue={setTopAmountForUser}
            handleSwap={handleSwap}
          />
        )}
      />
      <PreviewTradeButton
        onPress={handlePreviewTrade}
        isReadyToPreview={
          Object.values(bottomAmounts).every(
            (value) => moneyStringToNumber(value) >= MINIMUM_TRADE_AMOUNT,
          ) &&
          Object.values(topAmounts).every(
            (value) => moneyStringToNumber(value) >= MINIMUM_TRADE_AMOUNT,
          )
        }
      />
    </YStack>
  );
};

export { FirmSwapTradeOrder };
