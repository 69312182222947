import type { StateStorage } from 'zustand/middleware';

import AsyncStorage from '@react-native-async-storage/async-storage';

const ZustandStorage: StateStorage = {
  setItem: (name, value) => AsyncStorage.setItem(name, value),
  getItem: async (name) => {
    const value = await AsyncStorage.getItem(name);

    return value ?? null;
  },
  removeItem: (name) => AsyncStorage.removeItem(name),
};

export { ZustandStorage };
