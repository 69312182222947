import type { BottomSheetHeaderProps } from './types';

import { XStack, YStack } from 'tamagui';

import { HeadingS, LabelM } from '@south-street-app/atoms';

const BottomSheetHeader = ({
  title,
  subtitle,
  showClose,
}: BottomSheetHeaderProps) =>
  title || subtitle || showClose ? (
    <XStack
      paddingLeft={'$1'}
      paddingRight={'$1'}
      marginBottom={'$1'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      {title || subtitle ? (
        <YStack>
          {title ? <HeadingS>{title}</HeadingS> : null}
          {subtitle ? <LabelM lineHeight={'$1'}>{subtitle}</LabelM> : null}
        </YStack>
      ) : null}
    </XStack>
  ) : null;

export { BottomSheetHeader };
