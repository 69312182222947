import type { SelectorItemData } from '@south-street-app/molecules';
import type {
  ListProductResponseDto,
  ProductDto,
} from '@utility-nyc/react-query-sdk';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Platform, StyleSheet, View } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { Spacer, useMedia, XStack, YStack } from 'tamagui';

import { getProductsByTypeOfTrade } from '@shared/utils';
import { useCouponColor } from '@south-street-app/hooks';
import { LoadingLine, Selector } from '@south-street-app/molecules';
import { useMobileTradeStore } from '@south-street-app/stores';
import { useGetInitialEndUserState } from '@utility-nyc/react-query-sdk';

import { ProductListItem } from './ProductListItem';
import { ProductListTitles } from './ProductListTitles';

const styles = StyleSheet.create({
  webListContainer: {
    flexDirection: 'column',
    flex: 1,
    paddingHorizontal: getSpaceTokenValue('$4'),
    paddingBottom: getSpaceTokenValue('$4'),
  },
});

type ProductListProps = {
  products: SelectorItemData[];
  onChangeProduct: (product: string, item: ProductDto) => void;
  isSwap?: boolean;
};

const ProductList = ({
  products,
  onChangeProduct,
  isSwap = false,
}: ProductListProps) => {
  const [selectedProduct, setSelectedProduct] = useState(
    products.length ? products[0].label : '',
  );
  const proposal = useMobileTradeStore((state) => state.proposal);
  const { desktop } = useMedia();
  const listRef = useRef<FlashList<ProductDto>>(null);
  const { data, isRefetching, refetch } = useGetInitialEndUserState({
    query: { refetchInterval: 10000 },
  });

  const { getColorForCoupon } = useCouponColor();

  useEffect(() => {
    if (selectedProduct) {
      listRef.current?.scrollToIndex({ animated: true, index: 0 });
    }
  }, [selectedProduct]);

  const ItemSeparator = useCallback(
    () =>
      Platform.select({
        native: <Spacer height={'$1'} />,
        web: (
          <YStack height={'$1'} width={'$100%'} backgroundColor={'$mono200'} />
        ),
      }),
    [],
  );
  const renderItem = useCallback(
    ({ item, index }: { item: ProductDto; index: number }) => {
      const { backgroundColor, textColor } = getColorForCoupon(
        selectedProduct,
        item.coupon,
      );

      return (
        <ProductListItem
          item={item}
          onPress={() => onChangeProduct(selectedProduct, item)}
          index={index}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      );
    },
    [getColorForCoupon, onChangeProduct, selectedProduct],
  );

  const listForProduct: ListProductResponseDto | undefined = useMemo(
    () =>
      data?.data.products.find((product: ListProductResponseDto) =>
        product.name.includes(selectedProduct),
      ),
    [data, selectedProduct],
  );

  const selectedProductData = useMemo(
    () =>
      listForProduct?.products.filter((product: ProductDto) =>
        getProductsByTypeOfTrade(product, selectedProduct, proposal, isSwap),
      ),

    [listForProduct, proposal, selectedProduct, isSwap],
  );

  const productsData = useMemo(
    () =>
      data?.data.products.map((product) => ({
        ...product,
        products: desktop
          ? product.products
          : product.products.filter((thisProduct: ProductDto) =>
              getProductsByTypeOfTrade(
                thisProduct,
                selectedProduct,
                proposal,
                isSwap,
              ),
            ),
      })),
    [data, selectedProduct, proposal, isSwap, desktop],
  );

  const onRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  if (!listForProduct || !selectedProductData) {
    return null;
  }

  return (
    <YStack flex={1} backgroundColor={'$mono100'}>
      <YStack padding={'$4'}>
        <Selector
          noSelection={desktop}
          data={products}
          onSelected={setSelectedProduct}
        />
      </YStack>
      {!desktop ? <ProductListTitles /> : null}
      <YStack flex={1} paddingTop={'$3'} backgroundColor={'$mono200'}>
        {desktop ? (
          <XStack
            overflow={'scroll'}
            backgroundColor={'$mono200'}
            flex={1}
            paddingTop={'$3'}
          >
            {productsData?.map((product) => (
              <View key={product.id} style={styles.webListContainer}>
                <ProductListTitles />
                {product.products.map((item, index) => (
                  <>
                    {renderItem({ item, index })}
                    <ItemSeparator />
                  </>
                ))}
              </View>
            ))}
          </XStack>
        ) : (
          <FlashList
            ref={listRef}
            data={selectedProductData}
            contentContainerStyle={{
              paddingHorizontal: getSpaceTokenValue('$4'),
            }}
            estimatedItemSize={60}
            ItemSeparatorComponent={ItemSeparator}
            renderItem={renderItem}
            refreshing={false}
            onRefresh={onRefresh}
          />
        )}
      </YStack>
      {isRefetching ? <LoadingLine /> : null}
    </YStack>
  );
};

export { ProductList };
