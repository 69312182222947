import { useEffect } from 'react';

import { Platform } from 'react-native';

const useFontsWebLoader = () => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      const link = document.createElement('link');

      link.href =
        'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap';

      link.rel = 'stylesheet';

      document.head.appendChild(link);
    }
  }, []);
};

export { useFontsWebLoader };
