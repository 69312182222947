import type { SelectorItemData } from './types';
import type { ColorTokens } from 'tamagui';

import { useCallback, useEffect, useRef, useState } from 'react';

import type { LayoutChangeEvent } from 'react-native';
import { FlatList } from 'react-native';

import {
  getColorTokenValue,
  getSpaceTokenValue,
} from '@utility-nyc/react-native-ui-config';
import { XStack } from 'tamagui';

import { SelectorItem } from './SelectorItem';

const transparentBackground: ColorTokens = '$transparent';

const Selector = ({
  data,
  onSelected,
  noSelection = false,
}: {
  data: SelectorItemData[];
  onSelected: (selected: string) => void;
  noSelection?: boolean;
}) => {
  const [selected, setSelected] = useState(0);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const listRef = useRef<FlatList>(null);

  useEffect(() => {
    listRef.current?.scrollToIndex({ index: selected, animated: true });
  }, [selected]);

  const handleOnPress = (index: number) => {
    if (noSelection) {
      return;
    }

    setSelected(index);

    onSelected(data[index].label);
  };

  const setWidthOnLayout = useCallback(
    (event: LayoutChangeEvent) => {
      if (data.length <= 3) {
        setWidth(event.nativeEvent.layout.width / data.length);
      }
    },
    [data.length],
  );

  return (
    <XStack
      justifyContent={'space-between'}
      alignItems={'center'}
      backgroundColor={noSelection ? transparentBackground : '$mono200'}
      overflow={'hidden'}
      borderRadius={noSelection ? '$true' : '$xl'}
      onLayout={setWidthOnLayout}
    >
      <FlatList
        ref={listRef}
        data={data}
        renderItem={({ item, index }) => (
          <SelectorItem
            key={index}
            label={item.label}
            selected={noSelection ? false : selected === index}
            onPress={() => handleOnPress(index)}
            width={width}
          />
        )}
        keyExtractor={(_item, index) => index.toString()}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          backgroundColor: noSelection
            ? transparentBackground
            : getColorTokenValue('$mono200'),
          borderRadius: noSelection
            ? getSpaceTokenValue('$4')
            : getSpaceTokenValue('$10'),
        }}
      />
    </XStack>
  );
};

export { Selector };
