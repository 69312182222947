import type { CouponType } from '../types';

const MINIMUM_TRADE_AMOUNT = 25000;

const MOCKCONTENT_PRODUCTS = [
  { label: 'UMBS 30yr' },
  { label: 'GNMAII' },
  { label: 'UMBS 15yr' },
];

const MOCKCONTENT_PRODUCT_DATA: {
  [key: string]: {
    type: CouponType;
    coupon: string;
    month: string;
    quote: string;
  }[];
} = {};

MOCKCONTENT_PRODUCT_DATA[MOCKCONTENT_PRODUCTS[0].label] = [
  { type: 'regular', coupon: '1.5', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '1.5', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '1.5', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '1.5', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '1.5', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '2.0', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '2.0', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '2.0', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '2.0', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '2.0', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '2.5', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '2.5', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '2.5', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '2.5', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '2.5', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.0', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.0', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.0', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '3.0', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '3.0', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.5', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.5', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.5', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '3.5', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '3.5', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.0', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.0', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.0', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '4.0', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '4.0', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.5', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.5', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.5', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '4.5', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '4.5', month: 'Dec / Jan', quote: '95-30+/01+' },
];

MOCKCONTENT_PRODUCT_DATA[MOCKCONTENT_PRODUCTS[1].label] = [
  { type: 'regular', coupon: '2.0', month: 'Feb', quote: '95-30/31' },
  { type: 'regular', coupon: '2.0', month: 'Mar', quote: '95-30/31' },
  { type: 'regular', coupon: '2.0', month: 'Apr', quote: '95-30/31' },
  { type: 'roll', coupon: '2.0', month: 'Feb / Mar', quote: '95-30/31' },
  { type: 'roll', coupon: '2.0', month: 'Mar / Apr', quote: '95-30/31' },
  { type: 'regular', coupon: '2.5', month: 'Feb', quote: '95-30/31' },
  { type: 'regular', coupon: '2.5', month: 'Mar', quote: '95-30/31' },
  { type: 'regular', coupon: '2.5', month: 'Apr', quote: '95-30/31' },
  { type: 'roll', coupon: '2.5', month: 'Feb / Mar', quote: '95-30/31' },
  { type: 'roll', coupon: '2.5', month: 'Mar / Apr', quote: '95-30/31' },
  { type: 'regular', coupon: '3.0', month: 'Feb', quote: '95-30/31' },
  { type: 'regular', coupon: '3.0', month: 'Mar', quote: '95-30/31' },
  { type: 'regular', coupon: '3.0', month: 'Apr', quote: '95-30/31' },
  { type: 'roll', coupon: '3.0', month: 'Feb / Mar', quote: '95-30/31' },
  { type: 'roll', coupon: '3.0', month: 'Mar / Apr', quote: '95-30/31' },
  { type: 'regular', coupon: '3.5', month: 'Feb', quote: '95-30/31' },
  { type: 'regular', coupon: '3.5', month: 'Mar', quote: '95-30/31' },
  { type: 'regular', coupon: '3.5', month: 'Apr', quote: '95-30/31' },
  { type: 'roll', coupon: '3.5', month: 'Feb / Mar', quote: '95-30/31' },
  { type: 'roll', coupon: '3.5', month: 'Mar / Apr', quote: '95-30/31' },
  { type: 'regular', coupon: '4.0', month: 'Feb', quote: '95-30/31' },
  { type: 'regular', coupon: '4.0', month: 'Mar', quote: '95-30/31' },
  { type: 'regular', coupon: '4.0', month: 'Apr', quote: '95-30/31' },
  { type: 'roll', coupon: '4.0', month: 'Feb / Mar', quote: '95-30/31' },
  { type: 'roll', coupon: '4.0', month: 'Mar / Apr', quote: '95-30/31' },
  { type: 'regular', coupon: '4.5', month: 'Feb', quote: '95-30/31' },
  { type: 'regular', coupon: '4.5', month: 'Mar', quote: '95-30/31' },
  { type: 'regular', coupon: '4.5', month: 'Apr', quote: '95-30/31' },
  { type: 'roll', coupon: '4.5', month: 'Feb / Mar', quote: '95-30/31' },
  { type: 'roll', coupon: '4.5', month: 'Mar / Apr', quote: '95-30/31' },
];

MOCKCONTENT_PRODUCT_DATA[MOCKCONTENT_PRODUCTS[2].label] = [
  { type: 'regular', coupon: '2.5', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '2.5', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '2.5', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '2.5', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '2.5', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.0', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.0', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.0', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '3.0', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '3.0', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.5', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.5', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '3.5', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '3.5', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '3.5', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.0', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.0', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.0', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '4.0', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '4.0', month: 'Dec / Jan', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.5', month: 'Nov', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.5', month: 'Dec', quote: '95-30+/01+' },
  { type: 'regular', coupon: '4.5', month: 'Jan', quote: '95-30+/01+' },
  { type: 'roll', coupon: '4.5', month: 'Nov / Dec', quote: '95-30+/01+' },
  { type: 'roll', coupon: '4.5', month: 'Dec / Jan', quote: '95-30+/01+' },
];

export { MOCKCONTENT_PRODUCTS, MOCKCONTENT_PRODUCT_DATA, MINIMUM_TRADE_AMOUNT };
