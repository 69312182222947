import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { Input, styled } from 'tamagui';

const TextInput = styled(Input, {
  backgroundColor: '$mono100',
  borderWidth: '$0',
  borderRadius: getSpaceTokenValue('$3'),
  fontFamily: '$body',
  fontSize: '$6',
  height: '$15',
  paddingVertical: '$4',
  paddingHorizontal: '$2',
  color: '$mono900',
  placeholderTextColor: '$mono400',
  fontWeight: '$2',
});

export { TextInput };
