import { useState, useEffect } from 'react';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { XStack, YStack } from 'tamagui';

import { Icon, LabelM } from '@south-street-app/atoms';
import { useMobileTradeStore } from '@south-street-app/stores';

type CountdownProps = {
  countdownSeconds: number;
  onCompleted?: () => void;
};

const Countdown = ({ countdownSeconds, onCompleted }: CountdownProps) => {
  const countdownMilliseconds = countdownSeconds * 1000;
  const [targetTime, setTargetTime] = useState(
    () => new Date().getTime() + countdownMilliseconds,
  );
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  const isNewQuotedPrice = useMobileTradeStore(
    (state) => state.isNewQuotedPrice,
  );

  useEffect(() => {
    if (isNewQuotedPrice) {
      setCurrentTime(new Date().getTime());

      setTargetTime(() => new Date().getTime() + countdownMilliseconds);
    }
  }, [countdownMilliseconds, isNewQuotedPrice]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const remainingTime = targetTime - currentTime;

  const minutes = Math.floor(remainingTime / 1000 / 60);
  const seconds = Math.floor((remainingTime / 1000) % 60);

  useEffect(() => {
    if (remainingTime <= 0) {
      onCompleted?.();
    }
  }, [onCompleted, remainingTime]);

  return (
    <XStack
      borderRadius={getSpaceTokenValue('$10')}
      backgroundColor={'$mono100'}
      paddingHorizontal={'$4'}
      paddingVertical={'$3'}
      alignItems={'center'}
      justifyContent={'space-between'}
      width={'$25'}
    >
      <Icon iconName={'timerIcon'} size={'$6'} color={'$brand500'} />
      <YStack alignItems={'center'} paddingHorizontal={'$1'}>
        <LabelM fontSize={'$4'} color={'$brand500'}>
          {minutes < 0 ? '00' : minutes < 10 ? '0' + minutes : minutes}
          {':'}
          {seconds < 0 ? '00' : seconds < 10 ? '0' + seconds : seconds}
        </LabelM>
      </YStack>
    </XStack>
  );
};

export { Countdown };
