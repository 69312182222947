import { XStack, YStack } from 'tamagui';

import { BodyL, LabelL } from '@south-street-app/atoms';

const DisplayTradeItem = ({
  label,
  value,
  useSeparator = false,
}: {
  label: string;
  value: string;
  useSeparator?: boolean;
}) => (
  <YStack>
    <XStack justifyContent={'space-between'} paddingVertical={'$4'}>
      <LabelL fontWeight={'$2'}>{label}</LabelL>
      <BodyL>{value}</BodyL>
    </XStack>
    {useSeparator ? (
      <YStack height={'$px'} backgroundColor={'$mono300'} />
    ) : null}
  </YStack>
);

export { DisplayTradeItem };
