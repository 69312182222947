import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { TradingNavigatorParamList } from '@south-street-app/navigation/types';
import type { TradeRequestComponentDto } from '@utility-nyc/react-query-sdk';

import { useCallback, useState } from 'react';

import { useMedia, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { MINIMUM_TRADE_AMOUNT } from '@shared/services';
import { capitalizeFirstLetter, moneyStringToNumber } from '@shared/utils';
import { HeadingM } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { useMobileTradeStore } from '@south-street-app/stores';

import { PreviewTradeButton } from './PreviewTradeButton';
import { SwapButtom } from './SwapButton';
import { TradeItem } from './TradeItem';
import { combinedProductName } from './combinedProductName';

type RolltradeOrderProps = {
  navigation: NativeStackNavigationProp<
    TradingNavigatorParamList,
    'TradeOrder'
  >;
};

const RolltradeOrder = ({ navigation }: RolltradeOrderProps) => {
  const { proposal, setProposal } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      setProposal: state.setProposal,
    })),
  );
  const { desktop } = useMedia();
  const firstProduct = proposal.components[0].product;

  const [topTradeInput, setTopTradeInput] = useState<
    Omit<TradeRequestComponentDto, 'price'>
  >({
    type: 'OFFER',
    product: firstProduct.leg1Product ?? firstProduct,
    amount: 0,
  });

  const [bottomTradeInput, setBottomTradeInput] = useState<
    Omit<TradeRequestComponentDto, 'price'>
  >({ type: 'BID', product: firstProduct, amount: 0 });

  const handleSwap = useCallback(() => {
    setTopTradeInput({
      ...topTradeInput,
      type: bottomTradeInput.type,
    });

    setBottomTradeInput({
      ...bottomTradeInput,
      type: topTradeInput.type,
    });
  }, [topTradeInput, bottomTradeInput]);

  const handlePreviewTrade = useCallback(() => {
    const bidPrice = topTradeInput.product.bidPrice;
    const askPrice = bottomTradeInput.product.askPrice;

    setProposal({
      ...proposal,
      components: [
        {
          ...topTradeInput,
          price: bidPrice,
        },
        {
          ...bottomTradeInput,
          price: askPrice,
        },
      ],
    });

    navigation.navigate('PreviewTradeOrder');
  }, [bottomTradeInput, navigation, proposal, setProposal, topTradeInput]);

  const handleTopTradeInputOnChangeValue = useCallback(
    (amount: string) => {
      setTopTradeInput({
        ...topTradeInput,
        amount: moneyStringToNumber(amount),
      });
    },
    [topTradeInput],
  );

  const handleBottomTradeInputOnChangeValue = useCallback(
    (amount: string) => {
      setBottomTradeInput({
        ...bottomTradeInput,
        amount: moneyStringToNumber(amount),
      });
    },
    [bottomTradeInput],
  );

  return (
    <YStack
      flex={1}
      justifyContent={'space-between'}
      {...(desktop && {
        width: 800,
        marginHorizontal: '$auto',
        justifyContent: 'flex-start',
      })}
    >
      <HeadingM fontWeight={'$3'} paddingTop={'$2'} paddingBottom={'$10'}>
        {en_US.roll}
      </HeadingM>
      <YStack
        flex={desktop ? undefined : 1}
        paddingBottom={desktop ? '$16' : undefined}
        space={'$4'}
      >
        <TradeItem
          label={capitalizeFirstLetter(topTradeInput.type)}
          product={combinedProductName(topTradeInput)}
          onChangeValue={handleTopTradeInputOnChangeValue}
        />
        <YStack paddingVertical={'$1'}>
          <SwapButtom onPress={handleSwap} />
        </YStack>
        <TradeItem
          disabled={topTradeInput.amount < 1}
          label={capitalizeFirstLetter(bottomTradeInput.type)}
          product={combinedProductName(bottomTradeInput)}
          onChangeValue={handleBottomTradeInputOnChangeValue}
        />
      </YStack>
      <PreviewTradeButton
        onPress={handlePreviewTrade}
        isReadyToPreview={
          topTradeInput.amount >= MINIMUM_TRADE_AMOUNT &&
          bottomTradeInput.amount >= MINIMUM_TRADE_AMOUNT
        }
      />
    </YStack>
  );
};

export { RolltradeOrder };
