const formatToMoney = (input: string | number) => {
  if (typeof input === 'number') {
    input = input.toString();
  }

  const cleanInput = input.replace(/[^0-9.-]/g, '');
  const number = parseFloat(cleanInput);

  if (isNaN(number)) {
    return '';
  }

  const formattedMoney = number
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'code',
      minimumFractionDigits: 0,
    })
    .replace('USD', '')
    .trim();

  return formattedMoney;
};

export { formatToMoney };
