import { YStack } from 'tamagui';

import { BodyL, HeadingXL } from '@south-street-app/atoms';

type DisplayItemProps = {
  label: string;
  title: string | undefined;
};

const DisplayItem = ({ label, title }: DisplayItemProps) => (
  <YStack>
    <BodyL color={'$mono100'}>{label}</BodyL>
    {title ? (
      <HeadingXL fontWeight={'$3'} color={'$mono100'} paddingTop={'$1'}>
        {title}
      </HeadingXL>
    ) : undefined}
  </YStack>
);

export { DisplayItem };
