import type { VariantStyle } from './Variants';
import type { GetProps } from 'tamagui';

import type { ReactNode } from 'react';

import { Platform } from 'react-native';

import { styled, Button as Frame, Spinner, YStack } from 'tamagui';

import { LabelL } from '@south-street-app/atoms';

import { BASE_BUTTON_VARIANTS } from './Button';

const webVariantStyles: {
  primary: VariantStyle;
  secondary: VariantStyle;
  disabled: VariantStyle;
} = {
  primary: {
    style: {
      color: '$mono100',
      backgroundColor: '$brand500',
    },
    pressStyle: {
      backgroundColor: '$brand600',
    },
  },
  secondary: {
    style: {
      backgroundColor: '$mono100',
      color: '$brand500',
      fontWeight: '$2',
      borderColor: '$brand500',
      borderWidth: '$px',
    },
    pressStyle: {
      borderColor: '$brand600',
    },
  },
  disabled: {
    style: {
      backgroundColor: '$mono300',
    },
  },
} as const;

type ButtonLargeProps = GetProps<typeof BaseButton> & {
  children: ReactNode;
  variant?: 'primary' | 'secondary';
  loading?: boolean;
  disabled?: boolean;
  accessibilityLabel?: string;
  onPress?: () => void;
};

const BaseButton = styled(Frame, {
  unstyled: true,
  color: '$mono100',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  borderRadius: '$xl',
  height: '$14',
  fontSize: '$3',
  fontWeight: '$4',
  variants: BASE_BUTTON_VARIANTS,
  defaultVariants: {
    variant: 'primary',
  },
});

const ButtonLarge = ({
  children,
  variant = 'primary',
  loading,
  disabled,
  accessibilityLabel,
  onPress,
  ...restProps
}: ButtonLargeProps) =>
  Platform.select({
    native: (
      <BaseButton
        {...restProps}
        disabled={disabled}
        onPress={disabled ? undefined : onPress}
        variant={disabled ? 'disabled' : variant}
      >
        {loading ? <Spinner /> : children}
      </BaseButton>
    ),
    web: (
      <YStack
        cursor={'pointer'}
        backgroundColor={
          !disabled
            ? webVariantStyles[variant].style.backgroundColor
            : webVariantStyles.disabled.style.backgroundColor
        }
        borderWidth={!disabled && variant === 'secondary' ? '$px' : undefined}
        borderColor={
          !disabled && variant === 'secondary'
            ? webVariantStyles[variant].style.borderColor
            : undefined
        }
        disabled={disabled}
        onPress={disabled ? undefined : onPress}
        borderRadius={'$xl'}
        padding={'$4'}
        alignItems={'center'}
        pressStyle={
          !disabled
            ? webVariantStyles[variant].pressStyle
            : webVariantStyles.disabled.style
        }
      >
        <LabelL
          fontSize={'$4'}
          fontWeight={'$2'}
          letterSpacing={'$2'}
          color={webVariantStyles[variant].style.color}
        >
          {loading ? <Spinner /> : children}
        </LabelL>
      </YStack>
    ),
  });

export { ButtonLarge };
