import type { ReactElement } from 'react';

import { YStack } from 'tamagui';

import { BodyL, Icon } from '@south-street-app/atoms';
import { useCheckJailbreakStatus } from '@south-street-app/hooks';
import { ScreenWrapper } from '@south-street-app/molecules';

const CheckJailbrokenProvider = ({
  children,
}: {
  children: ReactElement | Iterable<ReactElement>;
}) => {
  const isJailbroken = useCheckJailbreakStatus();

  if (!isJailbroken) {
    return <>{children}</>;
  }

  return (
    <ScreenWrapper>
      <YStack
        justifyContent={'space-between'}
        alignItems={'center'}
        flex={1}
        space={'$2'}
        paddingHorizontal={'$8'}
        backgroundColor={'$white'}
      >
        <YStack
          alignItems={'center'}
          space={'$4'}
          paddingTop={'$15'}
          paddingHorizontal={'$8'}
        >
          <YStack
            borderRadius={'$xl'}
            padding={'$3.5'}
            backgroundColor={'$mono200'}
          >
            <Icon size={'$6'} iconName={'infoIcon'} color={'$black'} />
          </YStack>
          <BodyL textAlign={'center'}>
            {`We have detected this device is either jail broken or rooted. Please contact South Street's support if you believe this to be an issue.`}
          </BodyL>
        </YStack>
      </YStack>
    </ScreenWrapper>
  );
};

export { CheckJailbrokenProvider };
