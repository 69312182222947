import type { ColorTokens } from 'tamagui';

import { StyleSheet } from 'react-native';

import {
  getColorTokenValue,
  getSpaceTokenValue,
} from '@utility-nyc/react-native-ui-config';
import { YStack, XStack } from 'tamagui';

import { Icon, BodyL, BodyXL, LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { MoneyInput } from '@south-street-app/molecules';

const styles = StyleSheet.create({
  choosePressStyle: {
    backgroundColor: getColorTokenValue('$brand600') as ColorTokens,
  },
});

type TradeItemProps = {
  label: string;
  product: string;
  onChangeValue: (value: string) => void;
  onPressChooseProduct?: () => void;
  onPressChangeProduct?: () => void;
  isProductChangeable?: boolean;
  disabled?: boolean;
};

const TradeItem = ({
  label,
  product,
  onChangeValue,
  onPressChooseProduct,
  onPressChangeProduct,
  isProductChangeable = false,
  disabled = false,
}: TradeItemProps) => (
  <YStack space={'$4'}>
    <XStack
      width={'$100%'}
      alignItems={'flex-end'}
      justifyContent={'space-between'}
    >
      <BodyXL fontWeight={'$3'}>{label}</BodyXL>

      {isProductChangeable && product !== '-' ? (
        <XStack alignItems={'center'} onPress={onPressChangeProduct}>
          <BodyXL color={'$mono900'}>{product}</BodyXL>
          <Icon iconName={'chevronRightIcon'} size={'$6'} color={'$mono400'} />
        </XStack>
      ) : (
        <BodyL color={'$mono900'}>{product}</BodyL>
      )}
    </XStack>

    {product !== '-' ? (
      <MoneyInput onChangeText={onChangeValue} />
    ) : (
      <XStack
        backgroundColor={disabled ? '$mono200' : '$brand500'}
        borderRadius={getSpaceTokenValue('$3')}
        padding={'$5'}
        justifyContent={'space-between'}
        onPress={disabled ? undefined : onPressChooseProduct}
        pressStyle={disabled ? undefined : styles.choosePressStyle}
      >
        <LabelL color={'$mono100'} fontWeight={'$2'}>
          {en_US.chooseProduct}
        </LabelL>
        <Icon size={'$6'} iconName={'chevronRightIcon'} />
      </XStack>
    )}
  </YStack>
);

export { TradeItem };
