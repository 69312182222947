import type { PasswordFormType } from './RenderPassword';
import type { PublicStackNavigationProps } from '@south-street-app/navigation/types';

import { StyleSheet, TouchableOpacity } from 'react-native';

import { useForm, Controller } from 'react-hook-form';
import { YStack, useMedia } from 'tamagui';

import { BodyM, HeadingS } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { ButtonLarge, ScreenWrapperKAV } from '@south-street-app/molecules';
import { useSendOtp } from '@utility-nyc/react-query-sdk';

import { passwordRules, RenderPassword } from './RenderPassword';

const styles = StyleSheet.create({
  forgotPassword: {
    alignSelf: 'center',
  },
});

const LoginPasswordScreen = ({
  route,
  navigation,
}: PublicStackNavigationProps<'LoginPasswordScreen'>) => {
  const { email } = route.params;
  const { desktop } = useMedia();
  const { mutate: sendCredentials, isLoading } = useSendOtp();
  const {
    control,
    setError,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<PasswordFormType>({
    mode: 'onBlur',
    defaultValues: {
      password: '',
    },
  });
  const handleOnSubmit = handleSubmit((data) => {
    try {
      sendCredentials(
        {
          data: {
            identifier: email,
            credential: data.password,
          },
        },
        {
          onSuccess: () => {
            navigation.navigate('LoginPhoneCodeScreen', {
              email,
              password: data.password,
            });
          },
          onError: (error) => {
            if (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              error.response?.status === 401
            ) {
              setError('password', {
                type: 'manual',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                message: error.response?.data?.error?.message,
              });
            } else {
              setError('password', {
                type: 'manual',
                message: en_US.unableToVerifyAccount,
              });
            }
          },
        },
      );
    } catch (error) {
      console.log(error, 'error');
    }
  });

  const onForgot = () => {
    navigation.navigate('ForgotPasswordEmailScreen');
    // Toast.showSuccessToast({
    //   text1: en_US.forgotPasswordSuccess,
    //   position: 'top',
    //   topOffset: 75,
    // });
  };

  return (
    <ScreenWrapperKAV>
      <YStack
        flex={1}
        justifyContent={desktop ? 'center' : 'space-between'}
        paddingTop={desktop ? '$0' : '$15'}
        paddingHorizontal={'$4'}
        {...(desktop && {
          width: '$150',
          margin: '$auto',
          gap: '$7',
        })}
      >
        <YStack space={'$4'}>
          <HeadingS fontWeight={'$2'}>{en_US.enterPassword}</HeadingS>
          <Controller
            control={control}
            name={'password'}
            rules={passwordRules}
            render={({ field, fieldState }) => (
              <RenderPassword
                field={field}
                fieldState={fieldState}
                onSubmit={handleOnSubmit}
              />
            )}
          />
        </YStack>
        <YStack gap={'$4'}>
          <TouchableOpacity onPress={onForgot} style={styles.forgotPassword}>
            <BodyM color={'$mono400'}>{en_US.forgotPassword}</BodyM>
          </TouchableOpacity>
          <ButtonLarge
            disabled={isLoading || !isValid || !isDirty}
            loading={isLoading}
            onPress={handleOnSubmit}
          >
            {en_US.continue}
          </ButtonLarge>
        </YStack>
      </YStack>
    </ScreenWrapperKAV>
  );
};

export { LoginPasswordScreen };
