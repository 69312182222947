import type { PrivateStackNavigationProps } from '@south-street-app/navigation/types';

import { useCallback, useLayoutEffect } from 'react';

import { Linking, Platform, StyleSheet } from 'react-native';

import {
  getColorTokenValue,
  getSpaceTokenValue,
} from '@utility-nyc/react-native-ui-config';
import { Spacer, useMedia, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { Icon, BodyL, BodyXL, HeadingXL } from '@south-street-app/atoms';
import { ScreenWrapper } from '@south-street-app/molecules';
import { useGlobalBottomSheetStore } from '@south-street-app/stores';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: getColorTokenValue('$mono100'),
  },
  pressStyle: {
    opacity: 0.7,
  },
});

const AccountLinkScreen = ({
  route: { params },
  navigation,
}: PrivateStackNavigationProps<'AccountLinkScreen'>) => {
  const { desktop } = useMedia();
  const { headerTitle, title, linkLabel, description, url } = params;
  const { openBottomSheet } = useGlobalBottomSheetStore(
    useShallow((state) => ({
      openBottomSheet: state.openBottomSheet,
    })),
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle,
    });
  }, [headerTitle, navigation]);

  const handleOpenUrl = useCallback(async () => {
    const supported = await Linking.canOpenURL(url);

    if (headerTitle === 'Privacy Policy') {
      return openBottomSheet({ type: 'privacyPolicy' });
    } else if (headerTitle === 'Terms and Conditions') {
      return openBottomSheet({ type: 'termsOfService' });
    }

    if (supported) {
      await Linking.openURL(url);
    }
  }, [headerTitle, openBottomSheet, url]);

  return (
    <ScreenWrapper>
      <YStack
        width={desktop ? '$200' : undefined}
        paddingTop={'$16'}
        paddingHorizontal={'$4'}
        backgroundColor={'$mono100'}
        justifyContent={'space-between'}
        flex={1}
        marginHorizontal={desktop ? '$auto' : undefined}
      >
        <YStack>
          <HeadingXL fontWeight={'$3'} paddingBottom={'$1'}>
            {title}
          </HeadingXL>
          <BodyXL>{description}</BodyXL>
          <Spacer height={'$6'} />
          <XStack
            alignItems={'center'}
            backgroundColor={'$mono200'}
            padding={'$5'}
            borderRadius={getSpaceTokenValue('$3')}
            justifyContent={'space-between'}
            pressStyle={styles.pressStyle}
            {...(Platform.OS === 'web' ? { cursor: 'pointer' } : undefined)}
            onPress={handleOpenUrl}
          >
            <BodyL color={'$brand600'} fontWeight={'$2'}>
              {linkLabel}
            </BodyL>
            <Icon
              iconName={'chevronRightIcon'}
              size={'$6'}
              color={'$brand600'}
            />
          </XStack>
          <YStack />
        </YStack>
      </YStack>
    </ScreenWrapper>
  );
};

export { AccountLinkScreen };
