import type { PublicStackNavigationProps } from '@south-street-app/navigation/types';

import { useCallback } from 'react';

import { StyleSheet } from 'react-native';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Image, useMedia, YStack } from 'tamagui';

import { images } from '@shared/assets';
import { en_US } from '@south-street-app/configs';
import { ButtonLarge } from '@south-street-app/molecules';

const LOGO_HEIGHT = 172;
const LOGO_WIDTH = 197;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: getColorTokenValue('$mono100'),
  },
});

const WelcomeScreen = ({
  navigation,
}: PublicStackNavigationProps<'WelcomeScreen'>) => {
  const onPressLogin = useCallback(() => {
    navigation.navigate('LoginEmailScreen');
  }, [navigation]);
  const { desktop } = useMedia();

  return (
    <SafeAreaView style={styles.container}>
      <YStack
        flex={1}
        justifyContent={desktop ? 'center' : 'space-between'}
        alignItems={'center'}
        {...(desktop && { gap: '$7' })}
      >
        <YStack
          alignItems={'center'}
          justifyContent={'center'}
          flex={desktop ? 0 : 1}
        >
          <Image
            source={images.AlternativeLogo}
            {...(desktop && {
              maxHeight: LOGO_HEIGHT,
              maxWidth: LOGO_WIDTH,
            })}
            width={LOGO_WIDTH}
            height={LOGO_HEIGHT}
          />
        </YStack>
        <YStack
          width={desktop ? '$48' : '$100%'}
          space={'$2'}
          paddingHorizontal={'$4'}
          paddingBottom={'$3'}
        >
          <ButtonLarge onPress={onPressLogin}>{en_US.login}</ButtonLarge>
        </YStack>
      </YStack>
    </SafeAreaView>
  );
};

export { WelcomeScreen };
