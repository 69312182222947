import { Platform } from 'react-native';

import { XStack, YStack } from 'tamagui';

import { BodyS } from '@south-street-app/atoms';

const ProductListTitles = () => (
  <XStack paddingHorizontal={'$8'} paddingVertical={'$2'}>
    <XStack {...(Platform.OS === 'web' ? { width: '$half' } : { flex: 3 })}>
      <YStack
        {...(Platform.OS === 'web' ? { width: '$quarter' } : { flex: 1 })}
      >
        <BodyS fontWeight={'$3'} color={'$mono900'}>
          {'Coupons'}
        </BodyS>
      </YStack>
      <YStack
        {...(Platform.OS === 'web' ? { width: '$3/4' } : { flex: 2 })}
        paddingLeft={'$5'}
      >
        <BodyS fontWeight={'$3'} color={'$mono900'}>
          {'Date'}
        </BodyS>
      </YStack>
    </XStack>
    <YStack {...(Platform.OS === 'web' ? { width: '$half' } : { flex: 2 })}>
      <BodyS fontWeight={'$3'} color={'$mono900'}>
        {'Market Quote'}
      </BodyS>
    </YStack>
  </XStack>
);

export { ProductListTitles };
