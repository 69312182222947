import { XStack, YStack } from 'tamagui';

import { BodyS } from '@south-street-app/atoms';
import { useGlobalBottomSheetStore } from '@south-street-app/stores';

const AgreementsFooter = () => {
  const openBottomSheet = useGlobalBottomSheetStore(
    (state) => state.openBottomSheet,
  );

  return (
    <YStack alignItems={'center'}>
      <XStack>
        <YStack gap={'$1'} alignItems={'center'}>
          <BodyS color={'$mono600'}>
            {'By clicking Continue, you acknowledge you have read our '}
          </BodyS>
          <XStack>
            <BodyS
              textDecorationLine={'underline'}
              onPress={() => openBottomSheet({ type: 'privacyPolicy' })}
            >
              {'Privacy Policy'}
            </BodyS>
            <BodyS color={'$mono600'}>{' and agree to our '}</BodyS>
            <BodyS
              textDecorationLine={'underline'}
              onPress={() => openBottomSheet({ type: 'termsOfService' })}
            >
              {'Terms of Service. '}
            </BodyS>
          </XStack>
        </YStack>
      </XStack>
    </YStack>
  );
};

export { AgreementsFooter };
