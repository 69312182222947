import type { IMessage } from 'react-native-gifted-chat';

import { useCallback, useEffect, useRef, useState } from 'react';

import { ActivityIndicator, Platform, StyleSheet } from 'react-native';

import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { AvoidSoftInput } from 'react-native-avoid-softinput';
import { GiftedChat } from 'react-native-gifted-chat';
import { useMedia, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { Icon } from '@south-street-app/atoms';
import { ScreenWrapper } from '@south-street-app/molecules';
import { useMobileTradeStore } from '@south-street-app/stores';
import { isAndroid } from '@south-street-app/utils';

import { ChatAvatar } from './ChatAvatar';
import { ChatBubble } from './ChatBubble';
import { ChatInput } from './ChatInput';
import { ChatSendButton } from './ChatSendButton';

const styles = StyleSheet.create({
  pressStyle: {
    opacity: 0.7,
  },
});

const FakeHeader = () => {
  const { goBack } = useNavigation();
  const { desktop } = useMedia();
  const handleGoBack = useCallback(() => {
    // Workaround to avoid bottom sheet appearing on Android when using an input
    if (isAndroid) {
      AvoidSoftInput.setAdjustPan();
    }

    goBack();
  }, [goBack]);

  return (
    <YStack
      paddingVertical={'$4'}
      onPress={handleGoBack}
      {...(Platform.OS === 'web' ? { cursor: 'pointer' } : undefined)}
      pressStyle={styles.pressStyle}
      marginLeft={desktop ? 0 : -10}
      width={'$fit-content'}
    >
      <Icon color={'$mono700'} size={'$6'} iconName={'chevronLeftIcon'} />
    </YStack>
  );
};

const ChatScreen = () => {
  const { desktop } = useMedia();
  const {
    sendChatMessage,
    chatConversation,
    chatAuthor,
    setPersistantMessages,
    persistantMessages,
  } = useMobileTradeStore(
    useShallow((state) => ({
      sendChatMessage: state.sendChatMessage,
      chatConversation: state.chatConversation,
      chatAuthor: state.chatAuthor,
      setPersistantMessages: state.setPersistantMessages,
      persistantMessages: state.persistantMessages,
    })),
  );
  const navigation = useNavigation();
  const [chatMessages, setChatMessages] = useState<IMessage[]>([]);
  const ids = useRef<{ [key: string]: boolean }>({});
  const [loadingPrev, setLoadingPrev] = useState(true);

  useFocusEffect(() => {
    // Workaround to fix InputToolbar being overlapped by the keyboard
    if (isAndroid) {
      AvoidSoftInput.setAdjustResize();
    }

    return () => {
      AvoidSoftInput.setAdjustPan();
    };
  });

  useEffect(() => {
    if (loadingPrev) {
      if (chatMessages.length <= 0 && persistantMessages.length > 0) {
        setChatMessages(persistantMessages);
      }

      setLoadingPrev(false);
    }
  }, [chatMessages, persistantMessages, setLoadingPrev, loadingPrev]);

  useEffect(
    () =>
      navigation.addListener('beforeRemove', () => {
        setPersistantMessages(chatMessages);

        setLoadingPrev(true);
      }),
    [chatMessages, setPersistantMessages, navigation],
  );

  useEffect(() => {
    chatConversation?.on('messageAdded', (message) => {
      if (!ids.current[message.sid]) {
        setChatMessages((prevState) => [
          {
            _id: message.sid,
            text: message.body ?? '',
            createdAt: message.dateCreated ?? new Date(),
            user: {
              _id: message.author ?? '',
              name: message.author ?? '',
            },
          },
          ...prevState,
        ]);

        ids.current[message.sid] = true;
      }
    });

    return () => {
      chatConversation?.removeAllListeners();
    };
  }, [chatConversation, chatMessages, setChatMessages]);

  const onSend = useCallback(
    (_messages: IMessage[] = []) => {
      sendChatMessage(_messages[0].text);
    },
    [sendChatMessage],
  );

  const dontRender = () => null;

  const userData = {
    _id: chatAuthor,
    name: chatAuthor,
  };

  return (
    <ScreenWrapper>
      <YStack
        flex={1}
        {...(desktop && {
          backgroundColor: '$brand500',
        })}
      >
        <YStack
          flex={1}
          backgroundColor={'$mono100'}
          paddingHorizontal={'$4'}
          {...(desktop && {
            width: '$200',
            marginHorizontal: '$auto',
            marginVertical: '$4',
            borderRadius: '$true',
            paddingBottom: '$3',
          })}
        >
          {desktop ? <FakeHeader /> : null}
          {!loadingPrev ? (
            <GiftedChat
              messages={
                chatMessages.length > 0 ? chatMessages : persistantMessages
              }
              onSend={onSend}
              user={userData}
              renderUsernameOnMessage={true}
              renderUsername={dontRender}
              renderDay={dontRender}
              renderInputToolbar={ChatInput}
              renderAvatar={ChatAvatar}
              renderBubble={ChatBubble}
              renderSend={ChatSendButton}
            />
          ) : (
            <ActivityIndicator size={'large'} />
          )}
        </YStack>
      </YStack>
    </ScreenWrapper>
  );
};

export { ChatScreen };
