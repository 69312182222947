import { useShallow } from 'zustand/react/shallow';

import { useGlobalBottomSheetStore } from '@south-street-app/stores';

import { BackendErrorBottomSheetContent } from '../Content/BackendErrorBottomSheetContent';
import { LogoutBottomSheetContent } from '../Content/LogoutBottomSheetContent';
import { OperationsBottomSheetContent } from '../Content/OperationsBottomSheetContent';
import { PrivacyPolicyBottomSheetContent } from '../Content/PrivacyPolicyBottomSheetContent';
import { ProductListBottomSheetContent } from '../Content/ProductListBottomSheetContent';
import { RequestTradeBottomSheetContent } from '../Content/RequestTradeBottomSheetContent';
import { TermsOfServiceBottomSheetContent } from '../Content/TermsOfServiceBottomSheetContent';
import { TradeInProgressBottomSheetContent } from '../Content/TradeInProgressBottomSheetContent';

const SwitchGlobalBottomSheetContent = () => {
  const { state: storeState } = useGlobalBottomSheetStore(
    useShallow((globalState) => ({
      state: globalState.state,
    })),
  );

  switch (storeState.type) {
    case 'tradeSelector':
      return <OperationsBottomSheetContent />;
    case 'requestTrade':
      return <RequestTradeBottomSheetContent />;
    case 'logout':
      return <LogoutBottomSheetContent />;
    case 'productList':
      return <ProductListBottomSheetContent />;
    case 'tradeInProgress':
      return <TradeInProgressBottomSheetContent />;
    case 'backendError':
      return <BackendErrorBottomSheetContent />;
    case 'privacyPolicy':
      return <PrivacyPolicyBottomSheetContent />;
    case 'termsOfService':
      return <TermsOfServiceBottomSheetContent />;
    default:
      return null;
  }
};

export { SwitchGlobalBottomSheetContent };
