import type { SvgProps } from 'react-native-svg';
import type { ColorTokens, SizeTokens } from 'tamagui';

import { memo, type FC } from 'react';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';

import { type IconName, icons } from '@shared/assets';
import { getSizeTokenValue } from '@south-street-app/theme';

type GetSizeValueToken = Exclude<SizeTokens, '$100%' | '$auto'>;

type IconProps = SvgProps & {
  iconName: IconName;
  color?: ColorTokens;
  strokeColor?: ColorTokens;
} & (
    | {
        size?: GetSizeValueToken;
        width?: never;
        height?: never;
      }
    | {
        width: GetSizeValueToken;
        height: GetSizeValueToken;
        size?: never;
      }
  );

const Icon = memo(
  ({
    iconName,
    color = '$white',
    size = '$4',
    strokeColor,
    width,
    height,
    ...props
  }: IconProps) => {
    const SvgIcon: FC<SvgProps> = icons[iconName];
    const finalColor = getColorTokenValue(color);
    const IWidth = getSizeTokenValue(width ?? size);
    const IHeight = getSizeTokenValue(height ?? size);

    const strokeProps = strokeColor
      ? { stroke: getColorTokenValue(strokeColor) }
      : {};

    return (
      <SvgIcon
        {...props}
        width={IWidth}
        height={IHeight}
        testID={'svg-icon'}
        fill={finalColor}
        {...strokeProps}
      />
    );
  },
);

export { Icon };
export type { IconProps };
