import type { RequestingTradeNavigatorParamList } from './types';
import type {
  StackCardInterpolationProps,
  StackNavigationOptions,
} from '@react-navigation/stack';

import { useMemo } from 'react';

import { Animated } from 'react-native';

import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import {
  TransitionPresets,
  createStackNavigator,
} from '@react-navigation/stack';

import {
  QuotedPriceScreen,
  RequestingTradeScreen,
  TraderConfirmationScreen,
} from '@south-street-app/screens';

const { Navigator, Screen } =
  createStackNavigator<RequestingTradeNavigatorParamList>();

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

const forSlide = ({
  current,
  next,
  inverted,
  layouts: { screen },
}: StackCardInterpolationProps) => {
  const progress = Animated.add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
          extrapolate: 'clamp',
        })
      : 0,
  );

  return {
    cardStyle: {
      transform: [
        {
          translateX: Animated.multiply(
            progress.interpolate({
              inputRange: [0, 1, 2],
              outputRange: [
                screen.width, // Focused, but offscreen in the beginning
                0, // Fully focused
                screen.width * -1, // Fully unfocused
              ],
              extrapolate: 'clamp',
            }),
            inverted,
          ),
        },
      ],
      opacity: current.progress,
    },
  };
};

const RequestingTradeStackNavigator = () => {
  const screenOptions = useMemo<StackNavigationOptions>(
    () => ({
      safeAreaInsets: { top: 0 },
      ...TransitionPresets.DefaultTransition,
      cardStyleInterpolator: forSlide,
      headerShown: false,
    }),
    [],
  );

  return (
    <NavigationContainer independent={true} theme={navTheme}>
      <Navigator
        initialRouteName={'RequestingTrade'}
        screenOptions={{
          headerShown: false,
          gestureEnabled: false,
          ...screenOptions,
        }}
      >
        <Screen
          name={'RequestingTrade'}
          options={{
            headerShown: false,
          }}
          component={RequestingTradeScreen}
        />
        <Screen
          name={'QuotedPrice'}
          options={{
            headerShown: false,
          }}
          component={QuotedPriceScreen}
        />
        <Screen
          name={'TraderConfirmation'}
          options={{
            headerShown: false,
          }}
          component={TraderConfirmationScreen}
        />
      </Navigator>
    </NavigationContainer>
  );
};

export { RequestingTradeStackNavigator };
