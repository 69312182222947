import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type GlobalBottomSheetStateVariables = {
  isOpen?: boolean;
  type?:
    | 'tradeSelector'
    | 'tradeTimeout'
    | 'tradeInProgress'
    | 'backendError'
    | 'productList'
    | 'requestTrade'
    | 'tradeConfirmation'
    | 'logout'
    | 'privacyPolicy'
    | 'termsOfService'
    | '';
  subType?: 'rejected' | 'timeout' | 'canceled' | 'home' | undefined;
};

type GlobalBottomSheetStateFunctions = {
  openBottomSheet: ({ type, subType }: GlobalBottomSheetStateVariables) => void;
  closeBottomSheet: () => void;
  resetBottomSheet: () => void;
};

type GlobalBottomSheetState = {
  state: GlobalBottomSheetStateVariables;
} & GlobalBottomSheetStateFunctions;

const useGlobalBottomSheetStore =
  createWithEqualityFn<GlobalBottomSheetState>()(
    (set) => ({
      state: {
        isOpen: false,
        type: '',
        subType: undefined,
      },
      openBottomSheet: ({ type, subType }: GlobalBottomSheetStateVariables) =>
        set({ state: { isOpen: true, type, subType } }),
      closeBottomSheet: () =>
        set({
          state: {
            isOpen: false,
          },
        }),
      resetBottomSheet: () =>
        set({
          state: {
            isOpen: false,
            type: '',
            subType: undefined,
          },
        }),
    }),
    shallow,
  );

export { useGlobalBottomSheetStore };
