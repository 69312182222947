import type { RequestingTradeStackNavigationProps } from '@south-street-app/navigation/types';

import { useCallback, useEffect, useMemo } from 'react';

import { YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import {
  apiSpecToFullQuoteNumber,
  formattedTradeDetails,
  stringifyFullQuoteNumber,
} from '@shared/utils';
import { BodyXL, HeadingXL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { ButtonLarge, Spinner } from '@south-street-app/molecules';
import { TradeCard } from '@south-street-app/organisms';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';

const RequestingTradeScreen = ({
  navigation,
}: RequestingTradeStackNavigationProps<'RequestingTrade'>) => {
  const { receivedQuotedPrice, proposal, firmProposal, cancelTrade } =
    useMobileTradeStore(
      useShallow((state) => ({
        receivedQuotedPrice: state.receivedQuotedPrice,
        proposal: state.proposal,
        cancelTrade: state.cancelTrade,
        firmProposal: state.firmProposal,
      })),
    );
  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );

  useEffect(() => {
    if (receivedQuotedPrice) {
      navigation.navigate('QuotedPrice');
    }
  }, [navigation, receivedQuotedPrice]);

  const handleOnCancel = useCallback(() => {
    openBottomSheet({
      type: 'tradeTimeout',
      subType: 'home',
    });

    cancelTrade();
  }, [cancelTrade, openBottomSheet]);

  const isNotRoll =
    (firmProposal && firmProposal.components.length >= 1) ||
    proposal.components.length === 1 ||
    !proposal.components[1].product.bidSpread;

  const memoizedItems = useMemo(
    () =>
      proposal.components.map((component) => ({
        label: formattedTradeDetails(component),
        title: isNotRoll
          ? `${stringifyFullQuoteNumber(
              apiSpecToFullQuoteNumber(
                component.product.bidPrice,
                component.product.askPrice,
              ),
            )}`
          : undefined,
      })),
    [isNotRoll, proposal.components],
  );

  return (
    <YStack flex={1} padding={'$4'} justifyContent={'space-between'}>
      <YStack paddingTop={'$8'}>
        <HeadingXL fontWeight={'$3'}>{en_US.requestingTrade}</HeadingXL>
        <BodyXL paddingTop={'$1'} paddingBottom={'$6'}>
          {en_US.waitingTraderToQuotePrice}
        </BodyXL>
        <TradeCard items={memoizedItems} />
        <Spinner label={en_US.traderIsConfirmingPrice} labelMaxWidth={'$44'} />
      </YStack>
      <ButtonLarge
        variant={'secondary'}
        color={'$brand500'}
        fontWeight={'$2'}
        onPress={handleOnCancel}
      >
        {en_US.cancelTrade}
      </ButtonLarge>
    </YStack>
  );
};

export { RequestingTradeScreen };
