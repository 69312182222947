import type { IMessage, InputToolbarProps } from 'react-native-gifted-chat';

import { StyleSheet } from 'react-native';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { InputToolbar } from 'react-native-gifted-chat';

import { getSizeTokenValue } from '@south-street-app/theme';

const styles = StyleSheet.create({
  inputPrimaryStyle: {
    borderColor: getColorTokenValue('$mono300'),
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: getSizeTokenValue('$3'),
  },
  inputContainerStyle: {
    paddingVertical: getSizeTokenValue('$1'),
    borderTopWidth: 0,
    justifyContent: 'center',
  },
});

const ChatInput = (props: InputToolbarProps<IMessage>) => (
  <InputToolbar
    {...props}
    primaryStyle={styles.inputPrimaryStyle}
    containerStyle={styles.inputContainerStyle}
  />
);

export { ChatInput };
