import { Platform, Pressable, StyleSheet, View } from 'react-native';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import Markdown from 'react-native-markdown-display';
import { useWindowDimensions, YStack, useMedia, XStack } from 'tamagui';

import { Icon } from '@south-street-app/atoms';
import { ScreenWrapperScroll } from '@south-street-app/molecules';
import { useGlobalBottomSheetStore } from '@south-street-app/stores';

const content = `**South Street Securities LLC**

**Privacy Policy**

Last updated: March 2024

South Street Securities LLC, on behalf of its affiliates and subsidiaries (collectively, **“South Street,” “us,” “we,” or “our”**) has prepared this Privacy Policy (**“Privacy Policy”**) to describe to you (**“you”**) our online and offline practices regarding the personal information that South Street collects, uses, and shares on behalf of you and other end users of our mobile applications and other applications and websites (“**Sites**”) and the services, resources, and offerings provided through our Sites (“**Offerings**”).

For the purposes of this Privacy Policy, South Street is a controller (_i.e._, responsible party) for the practices described in this Privacy Policy.

**California Consumer Notice of Collection**

This Notice of Collection is provided to all California consumers subject to this Privacy Policy. You can access specific topics in this Privacy Policy by clicking on the relevant links below:

- [Introduction](#Introduction)
- [Information Collection and Usage](#InfoCollectionUsage)
- [Information Sharing and Disclosure](#InformationSharingDisclosure)
- [How We Protect Your Personal Information](#ProtectPersonalInformation)
- [Cookies and Other Tracking Technologies](#Cookies)
- [Linked Third Party Websites](#LinkedThirdPartySites)
- [Data Retention](#Retention)
- [Rights Related to Your Personal Information](#Rights)
- [Right to prohibit the sale or sharing of Personal Information](#OptOutSharing)
- [Children](#children)
- [Privacy Policy Changes](#Changes)
- [Contact Information](#Contact)

**Information Collection and Usage**

The types of Personal Information that we may collect depend on which your access to our Sites and which Offerings you are utilizing. “Personal Information” refers to information that identifies, relates to, describes, or can be associated with you. Depending on the Sites and Offerings you use, we may collect the following types of Personal Information:

- Information we receive directly from you on applications, forms and in other communications to us, including but not limited to, identification information such as name, street address, e-mail address(es), telephone and facsimile number(s), and other information you may provide to us to access the Offerings.
- Information we receive directly from you when you access our Offerings.
- Information we collect about you or collect from third party sources pertaining to your transactions with us, our affiliates and others.
- Information obtained when verifying your registration when accessing our Offerings, including but not limited to, username, password, and other information that you may provide on our Sites.
- Information we collect automatically from your browser or mobile device, including but not limited to, Internet Protocol address, device identifier, type of mobile device, mobile operating system, browser type, geographic location, pages you visit and the information you request, and the date and time of your access.

South Street uses the above-listed Personal Information only as permitted by law, including for the following purposes:

- To complete transactions and provide the Offerings authorized by you.
- For general business purposes, including but not limited to, to diagnose problems with the Sites, to send you information about your account, to provide you access to your account, to notify you of new products or applications being released, to verify your identity, to complete transactions that you request, to troubleshoot problems, to analyze our products and services, to enforce our Terms and Conditions for use of our Sites and Offerings, to protect our rights and property, and to customize, measure, and improve our Offerings.
- For other business purposes such as data analysis, identifying usage trends, determining the effectiveness of our Offerings and to enhance, customize or improve our features, products and Offerings, such as through the use of artificial intelligence and machine learning.
- To comply with and enforce applicable legal requirements, agreements and policies, or to prevent, detect, identify, investigate, respond and protect against potential or actual claims, liabilities, prohibited behavior and criminal activity.
- To develop and improve our marketing activities, including identifying new customer opportunities and sending marketing communications.
- To perform other activities consistent with this Privacy Policy.

If you are no longer using our Sites, we may continue to store and protect your Personal Information pursuant to this Privacy Policy, and as required by law, including but not limited to the following purposes: risk management, regulatory compliance, and audits.

**Disclosures of Personal Information**

We may disclose, or in the past 12 months have disclosed, the Personal Information identified above in the following limited circumstances for business purposes:

- **Affiliated Companies.** We may disclose Personal Information with companies that are affiliated with us (for example, companies that control, are controlled by, or are under common control with us). We may also disclose Personal Information that is collected between websites that we, or our affiliates, control.
- **Organizations that Provide Services.** We may disclose your Personal Information with companies that perform services for us, such as providers and vendors of data and datasets, credit assessment, product development, artificial intelligence, and data processing. The information shared with these companies typically includes Personal Information to process transactions on your behalf, conduct our operations, verify compliance with terms and conditions, follow your instructions as you authorize, or protect the security of our records. We may also disclose your Personal Information with third parties described below in the Cookies Section.
- **Potential Buyers.** In the event that we go through a business transition, such as a merger, another company acquires us or our affiliates, or we sell a portion of our assets, your Personal Information will, in most instances, be part of the assets transferred. Any successor entity shall be bound by terms and conditions reasonably similar to this Privacy Policy.
- **Other Parties.** We may disclose your Personal Information with other parties to comply with a legal obligation; to protect the legal rights of (or otherwise participate in a legal process pertaining to) our company, our employees, our agents, our clients, and our affiliates; to protect the safety, property, and security of us and/or our visitors, or to protect against fraud; or otherwise with your consent or as allowed by applicable law.

Generally, we do not sell, rent, lease or disclose your Personal Information to third parties except if you consent to such disclosure except as described in this Privacy Policy.

**How we Protect your Personal Information**

We have reasonable and appropriate technical and organizational measures in place to protect against the loss, misuses, and alteration of your Personal Information under our control. We have taken reasonable steps to confirm the Personal Information is retained in secured facilities and that reasonable measures have been implemented for protection from unauthorized access. The measures we use are designed to provide a level of security appropriate to the risk of processing your Personal Information.

However, no security system is impenetrable. We cannot guarantee the security of our website or databases, nor can we guarantee that Personal Information you supply will not be intercepted while being transmitted to us over the Internet. We ask that you do your part by maintaining any usernames and passwords you use to access our Sites strictly confidential.

**Cookies and Other Tracking Technologies**

Every time you come to one of our Sites, our Site’s web server automatically collects information, including, in some instances, Personal Information, through cookies, web beacons, and other similar tracking technologies (collectively “cookies”). Cookies are small text files that are placed on your computer or mobile device when you visit a website. Cookies help the website remember information about your visit, which can make it easier to visit the website again and make the website more useful to you. Some cookies are deleted once you close your browser (session cookies), while other cookies are retained even after you close your browser so that you can be recognized when you return to a website (persistent cookies). More information about cookies and how they work is available at [www.allaboutcookies.org](http://www.allaboutcookies.org).

Cookies on our Sites are generally divided into the following categories:

| **Cookie Type** | **Description** |
| --- | --- |
| **Essential Cookies** | These are cookies that our Sites need in order to function and that enable you to move around and use the Sites and features. Without these essential cookies, the Sites will not perform as smoothly for you as we would like it to, and we may not be able to provide the Sites or certain services or features you request. Examples of where these cookies are used include: to determine when you are signed in, to determine when your account has been inactive, and for other troubleshooting and security purposes. |
| **Analytics Cookies** | Analytics cookies provide us with information regarding how visitors navigate and interact with our Sites. Such cookies allow us to understand, for example, more about how many visitors we have to our Sites, how many times they visit us and how many times a user viewed specific pages within our Sites. Among other Analytics cookies, we use Google Analytics cookies for these purposes. For more information about Google Analytics, please refer to “How Google Uses Information From Sites or Apps that Use Our Services,” which can be found at [www.google.com/policies/privacy/partners/](https://protect-eu.mimecast.com/s/vD14Cxv4zurwoQ7FW8i-L), or any other URL Google may provide from time to time. |

We may use the information collected through cookies and related tracking technologies for the following purposes:

- To make our Sites easier to use and generally improve its performance.
- To gather metrics about how you interact with our Sites.
- For security reasons.
- To provide personalized content. For example, we may store user preferences, your default language, device and browser information, your profile information, which includes the level of your usage of the Sites, which you visit, so we can identify you across devices and personalize the content you see.

Most web browsers automatically accept cookies, but you can usually change your browser settings to prevent this. If you disable cookies, your ability to use some features of the website may be limited. For mobile devices, you may be able to manage certain cookies using your built-in mobile device settings and controls. Any choices concerning cookies are browser and/or device specific. If you clear your cookies from your browser on any of your devices, your choices will need to be reset. You should check how to do this on your device(s) and operating systems.

**Linked Third Party Websites**

We may provide links to other third party websites through the Sites solely as a convenience to you. However, such linking does not mean, and should not be interpreted to mean, that South Street endorses, is affiliated with or makes any representations concerning such third party websites. South Street neither reviews, controls, nor is responsible for these third party sites or any content therein. By using such links, you will leave the Sites. If you decide to access any of the third party websites linked to the Sites, you do so entirely at your own risk. South Street shall not be liable for any consequences arising from use of any third party websites to which the Sites link.

**Data Retention**

How long we retain your Personal Information depends on the context in which, and purposes for which, we collected it. We generally retain Personal Information for as long as necessary for achieving the purposes for which it was collected or processed, unless a different retention period is required by applicable law.

When we have no ongoing business need to process your Personal Information, we will either delete or anonymize it or, if this is not possible (for example, because your Personal Information has been stored in backup archives), then we will securely store your Personal Information and isolate it from any further processing until deletion is possible.

**Rights Related to your Personal Information**

Depending on where you live and the types of Personal Information at issue, you may have certain rights with respect to your Personal Information. Please note that many of these rights are subject to exceptions and limitations.

- **Right of access:** You have the right to know whether your Personal Information is being processed, and, where that is the case, to request access to, including a copy of, the Personal Information undergoing processing.
- **Right to correction:** You have the right to request that we correct or supplement any inaccurate or incomplete Personal Information we process about you.
- **Right to deletion:** You have the right to request that we delete your Personal Information or as applicable anonymize your Personal Information.
- **Right to data portability:** You have the right to request that we provide the Personal Information which you provided to us in a structured, commonly used and machine-readable format; and you have the right to transmit such Personal Information to another entity.
- **Right to prohibit the sale or sharing of Personal Information:** We do not “sell” or “share” Personal Information as those terms are defined under applicable law.
- **Right to limit the processing of your sensitive Personal Information:** We do not currently use or disclose sensitive Personal Information for purposes other than those which cannot be limited under applicable law.

If you choose to assert any of these rights under applicable laws, we will respond within the time period prescribed by applicable law. Please note that many of the above rights are subject to exceptions and limitations. If we are not able to provide the requested information or make the change you requested, you will be provided with the reasons for such decisions. Under local law, you may be entitled to lodge a complaint with your local data protection authority, such as your state Attorney General’s Office.

Your rights and our responses will vary based on your state of residency. Please note that you may be located in a jurisdiction where we are not obligated, or are unable, to fulfill a request. In such a case, your request may not be fulfilled.

We do not discriminate against individuals who exercise any of their rights described in this Privacy Policy. However, we may require use of your Personal Information to provide access to the Sites. Therefore, when you exercise your deletion right, in particular, you may lose access to certain aspects of the Sites that require your Personal Information.

**Verification Process**

Only you, or a person that you authorize to act on your behalf, may make a verifiable consumer request related to your Personal Information. If you designate an authorized agent to submit requests to exercise certain privacy rights on your behalf, we will require verification that you provided the authorized agent permission to make a request on your behalf.

The verifiable consumer request must:

- Provide sufficient information that allows us to reasonably verify you are the person about whom we collected Personal Information or an authorized representative.
- Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.

Our verification process may also include a request for additional information to confirm your identity or your authorized agent’s identity (such as your name, email address and date of birth) or to obtain proof that you have given your authorized agent permission to act on your behalf. If our verification process is successful, we will respond to your request within the time and in the manner required by applicable law. If we cannot validate the identity of you and/or your authorized agent or obtain proof that you have given your authorized agent permission to act on your behalf, we will attempt to contact you to inform you.

We cannot respond to your request or provide you with Personal Information if we cannot verify your identity or authority to make the request and confirm that the Personal Information relates to you. We will only use Personal Information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.

We will deliver our written response by mail or electronically, at your option. For data portability requests, we will select a format to provide your Personal Information that is readily useable and should allow you to transmit the Personal Information from one entity to another entity without hindrance, specifically by electronic mail communication. Further, if you would like to appeal any decision we make about your request, you may contact us as stated in the “Contact Information” section below.

**Children**

Our Site is not directed toward children under the age of thirteen, and we do not knowingly collect any Personal Information from children under the age of thirteen. If a child provided this website with Personal Information, we ask that a parent or guardian send us a written request to the address listed below and we will promptly delete the child's Personal Information from our records.

**International Transfers of Personal Information**

South Street is based in the United States of America. If you are from a country outside of the United States of America with laws governing data collection, use, and disclosure that may differ from U.S. law and you provide Personal Information to us, please note that any Personal Information that you provide to us may be transferred to the United States of America. By providing your Personal Information, where applicable law permits, you hereby specifically and expressly consent to such transfer and processing and the collection, use, and disclosure set forth herein or in the Terms of Use.

**Privacy Policy Changes**

South Street is always improving our Sites. As our Sites evolve, we may update or amend this Privacy Policy. If we modify this Privacy Policy, we will post the revised Privacy Policy online. The revised Privacy Policy will be effective immediately at the time of posting, unless a later effective date is expressly stated therein. We will also revise the “Last updated” date stated below. Should any modification materially change how we use your Personal Information, we will provide notice online prior to the effective date of the change.

It is your responsibility to periodically review this Privacy Policy. Users are bound by any changes to this Privacy Policy by using our Sites after such changes have been first posted. If you do not agree to the new posted Privacy Policy, your only remedy is to discontinue use of the Sites.

**Contact Information**

If You have any questions or concerns about our Privacy Policy or any other privacy or security issue, please contact us at:

South Street Securities LLC  
[mbstrading@ssnyc.com](mailto:mbstrading@ssnyc.com)
`;

const styles = StyleSheet.create({
  markdownContainer: {
    paddingRight: 32,
  },
});

const PrivacyPolicyBottomSheetContent = () => {
  const { height: windowHeight } = useWindowDimensions();
  const closeBottomSheet = useGlobalBottomSheetStore(
    (state) => state.closeBottomSheet,
  );
  const { desktop } = useMedia();

  return (
    <YStack flex={1} height={windowHeight - getSpaceTokenValue('$26')}>
      <ScreenWrapperScroll>
        <YStack padding={'$6'}>
          {desktop && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <XStack
              {...(Platform.OS === 'web'
                ? { position: 'sticky', right: 0, top: 0, zIndex: 1000 }
                : undefined)}
              justifyContent={'flex-end'}
            >
              <Pressable onPress={closeBottomSheet}>
                <Icon iconName={'closeIcon'} color={'$mono700'} size={'$6'} />
              </Pressable>
            </XStack>
          )}
          <View style={desktop ? styles.markdownContainer : undefined}>
            <Markdown>{content}</Markdown>
          </View>
        </YStack>
      </ScreenWrapperScroll>
    </YStack>
  );
};

export { PrivacyPolicyBottomSheetContent };
