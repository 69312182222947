import type { IMessage, SendProps } from 'react-native-gifted-chat';

import { Send } from 'react-native-gifted-chat';
import { YStack } from 'tamagui';

import { Icon } from '@south-street-app/atoms';

const ChatSendButton = (props: SendProps<IMessage>) => (
  <Send {...props}>
    <YStack backgroundColor={'$mono900'} borderRadius={'$xl'} padding={'$1'}>
      <Icon iconName={'chevronUpIcon'} />
    </YStack>
  </Send>
);

export { ChatSendButton };
