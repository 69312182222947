import type { SizeTokens, SpaceTokens } from 'tamagui';

import { useState } from 'react';

import { ActivityIndicator, Platform } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';
import {
  getColorTokenValue,
  getSpaceTokenValue,
} from '@utility-nyc/react-native-ui-config';
import { PanGestureHandler } from 'react-native-gesture-handler';
import Animated, {
  Extrapolate,
  interpolate,
  interpolateColor,
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { XStack } from 'tamagui';

import { LabelL, Icon } from '@south-street-app/atoms';
import { ButtonLarge } from '@south-street-app/molecules';

import { styles } from './styles';

type SlidePercentages = 0.2 | 0.4 | 0.6 | 0.8 | 1;

const AnimatedLabelL = Animated.createAnimatedComponent(LabelL);

type SlideButtonProps = {
  width?: SizeTokens;
  height?: Exclude<SpaceTokens, 'true' | boolean | number>;
  label?: string;
  onCompleted: () => void;
  slidePercentActivation?: SlidePercentages;
  isLoading?: boolean;
};

const SlideButton = ({
  width = '$100%',
  height = '$14',
  label,
  onCompleted,
  isLoading = false,
  slidePercentActivation = 0.8,
}: SlideButtonProps) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const sliderThumbWidth = getSpaceTokenValue('$18');
  const x = useSharedValue(0);

  const colorsArray = [
    getColorTokenValue('$brand500'),
    getColorTokenValue('$mono300'),
  ];

  useFocusEffect(() => {
    x.value = 0;
  });

  const animatedGestureHandler = useAnimatedGestureHandler({
    onActive: (e) => {
      if (!isLoading) {
        x.value = e.translationX;
      }
    },
    onEnd: () => {
      if (!isLoading) {
        if (
          x.value >
          (containerWidth - sliderThumbWidth) * slidePercentActivation
        ) {
          x.value = withTiming(containerWidth - sliderThumbWidth);

          runOnJS(onCompleted)();
        } else {
          x.value = withTiming(0);
        }
      }
    },
  });

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: interpolate(
          x.value,
          [0, containerWidth - sliderThumbWidth],
          [0, containerWidth - sliderThumbWidth],
          Extrapolate.CLAMP,
        ),
      },
    ],
    opacity: isLoading ? 0.3 : 1, // Reduce opacity when disabled
  }));

  const animatedProgress = useAnimatedStyle(() => ({
    width: interpolate(
      x.value,
      [0, containerWidth - sliderThumbWidth],
      [0 + sliderThumbWidth, containerWidth],
      Extrapolate.CLAMP,
    ),
  }));

  const animatedTextColor = useAnimatedStyle(() => ({
    color: interpolateColor(x.value, [0, containerWidth], colorsArray),
    opacity: isLoading ? 0.3 : 1, // Apply reduced opacity for disabled state
  }));

  return Platform.select({
    web: (
      <ButtonLarge
        disabled={isLoading}
        onPress={!isLoading ? onCompleted : undefined}
        width={width}
        height={height}
      >
        {label}
        {isLoading ? (
          <ActivityIndicator color={getColorTokenValue('$brand500')} />
        ) : null}
      </ButtonLarge>
    ),
    native: (
      <XStack
        backgroundColor={isLoading ? '$mono200' : '$mono200'} // Lighter color for disabled state
        justifyContent={'center'}
        alignItems={'center'}
        height={height}
        gap={'$2'}
        borderRadius={'$xl'}
        width={width}
        onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
      >
        <AnimatedLabelL
          fontSize={'$4'}
          zIndex={1}
          letterSpacing={'$2'}
          fontWeight={'$2'}
          style={animatedTextColor}
        >
          {label}
        </AnimatedLabelL>
        {isLoading ? (
          <ActivityIndicator color={getColorTokenValue('$brand500')} />
        ) : null}
        <PanGestureHandler
          onGestureEvent={!isLoading ? animatedGestureHandler : undefined}
        >
          <Animated.View style={[styles.buttonSlider, animatedStyle]}>
            <Icon size={'$6'} iconName={'chevronRightIcon'} />
          </Animated.View>
        </PanGestureHandler>
        <Animated.View
          style={[
            styles.animatedProgress,
            { height: getSpaceTokenValue(height) },
            animatedProgress,
          ]}
        />
      </XStack>
    ),
  });
};

export { SlideButton };
