import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { TradingNavigatorParamList } from '@south-street-app/navigation/types';

import { useCallback, useMemo, useState } from 'react';

import { FlatList } from 'react-native';

import { useMedia, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { MINIMUM_TRADE_AMOUNT } from '@shared/services';
import {
  capitalizeFirstLetter,
  getShortMonthName,
  moneyStringToNumber,
} from '@shared/utils';
import { BodyL, BodyXL } from '@south-street-app/atoms';
import { useMobileTradeStore } from '@south-street-app/stores';

import { FirmTradeItem } from './FirmTradeItem';
import { PreviewTradeButton } from './PreviewTradeButton';

type FirmSingleTradeOrderProps = {
  navigation: NativeStackNavigationProp<
    TradingNavigatorParamList,
    'TradeOrder'
  >;
};

const FirmSingleTradeOrder = ({ navigation }: FirmSingleTradeOrderProps) => {
  const { desktop } = useMedia();
  const {
    proposal,
    setProposal,
    tradeType,
    firmEndUsers,
    deleteEndUserById,
    setShares,
  } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      tradeType: state.tradeType,
      setShares: state.setShares,
      firmEndUsers: state.firmEndUsers,
      deleteEndUserById: state.deleteEndUserById,
      setProposal: state.setProposal,
    })),
  );

  const [amounts, setAmounts] = useState<Record<string, string>>({});

  const deleteUserById = (userId: string) => {
    if (firmEndUsers.length === 1) {
      navigation.goBack();
    }

    deleteEndUserById(userId);
  };

  const setAmountForUser = (userId: string, value: string) => {
    setAmounts((prev) => ({
      ...prev,
      [userId]: value,
    }));
  };

  const handlePreviewTrade = useCallback(() => {
    setProposal({
      ...proposal,
      components: [
        {
          ...proposal.components[0],
          amount: Object.entries(amounts).reduce(
            (sum, [_, value]) => sum + moneyStringToNumber(value),
            0,
          ),
          type: tradeType === 'BID' ? 'BID' : 'OFFER',
        },
      ],
    });

    setShares(
      Object.entries(amounts).map(([key, value]) => ({
        associatedId: key,
        amount: moneyStringToNumber(value),
        type: tradeType === 'BID' ? 'BID' : 'OFFER',
      })),
    );

    navigation.navigate('PreviewTradeOrder');
  }, [navigation, proposal, setProposal, amounts, tradeType, setShares]);

  const formattedProductName = useMemo(
    () =>
      `${proposal.components[0].product.name} ${
        proposal.components[0].product.coupon
      } ${getShortMonthName(proposal.components[0].product.month)}`,
    [proposal.components],
  );

  return (
    <YStack
      flex={1}
      paddingTop={'$5'}
      justifyContent={'space-between'}
      {...(desktop && {
        justifyContent: 'flex-start',
        width: 800,
        gap: '$16',
        marginHorizontal: '$auto',
      })}
    >
      <XStack
        paddingBottom={'$8'}
        width={'$100%'}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
      >
        <BodyXL fontWeight={'$3'}>{capitalizeFirstLetter(tradeType)}</BodyXL>

        <BodyL color={'$mono900'}>{formattedProductName}</BodyL>
      </XStack>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={firmEndUsers}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => {
          const onSetAmountForUser = (amount: string) => {
            setAmountForUser(item.id, amount);
          };

          return (
            <YStack paddingBottom={'$4'}>
              <FirmTradeItem
                onDeleteEndUser={deleteUserById}
                endUser={item}
                product={formattedProductName}
                onChangeValue={onSetAmountForUser}
              />
            </YStack>
          );
        }}
      />

      <PreviewTradeButton
        onPress={handlePreviewTrade}
        isReadyToPreview={firmEndUsers.every(
          (user) =>
            moneyStringToNumber(amounts[user.id] || '0') >=
            MINIMUM_TRADE_AMOUNT,
        )}
      />
    </YStack>
  );
};

export { FirmSingleTradeOrder };
