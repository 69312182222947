import type { HistoryListItemProps } from './HistoryListItem';

import { useCallback } from 'react';

import { RefreshControl, StyleSheet } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';
import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { FlatList } from 'react-native-gesture-handler';
import { useMedia } from 'tamagui';

import { ScreenWrapper } from '@south-street-app/molecules';
import {
  useGetAnalyticFirmAssociates,
  useGetEndUserTradeHistory,
} from '@utility-nyc/react-query-sdk';

import { HistoryListEmptyComponent } from './HistoryListEmptyComponent';
import { HistoryListItem } from './HistoryListItem';
import { HistoryListLoadingComponent } from './HistoryListLoadingComponent';

const styles = StyleSheet.create({
  desktopContainer: {
    width: 800,
    alignSelf: 'center',
  },
  sideBorders: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: getColorTokenValue('$mono300'),
  },
});

const HistoryScreen = () => {
  const { desktop } = useMedia();
  const { data, isLoading, isRefetching, refetch } =
    useGetEndUserTradeHistory();
  const { isLoading: loadingAssociates } = useGetAnalyticFirmAssociates();

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  const renderItem = useCallback(
    ({ item }: HistoryListItemProps) => <HistoryListItem item={item} />,
    [],
  );

  const EmptyComponent = useCallback(() => <HistoryListEmptyComponent />, []);

  const LoadingComponent = useCallback(
    () => <HistoryListLoadingComponent />,
    [],
  );

  const onRefresh = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <ScreenWrapper>
      <FlatList
        data={data?.data.tradeRequests}
        renderItem={renderItem}
        ListEmptyComponent={
          isLoading || loadingAssociates ? LoadingComponent : EmptyComponent
        }
        contentContainerStyle={[
          desktop ? styles.desktopContainer : undefined,
          data?.data.tradeRequests ? styles.sideBorders : undefined,
        ]}
        refreshControl={
          <RefreshControl refreshing={isRefetching} onRefresh={onRefresh} />
        }
      />
    </ScreenWrapper>
  );
};

export { HistoryScreen };
