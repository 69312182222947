import type { BottomSheetViewProps } from './types';
import type { BaseBottomSheetRef } from '@utility-nyc/react-native-bottom-sheet/src/bottom-sheets/types';

import { forwardRef } from 'react';
import type { ForwardedRef } from 'react';

import { BottomSheetWithView } from '@utility-nyc/react-native-bottom-sheet';

import { isRefObject } from '@shared/utils';

import { BottomSheetHeader } from './BottomSheetHeader';

const BottomSheetView = forwardRef(
  (
    { children, headerProps, HeaderComponent, ...props }: BottomSheetViewProps,
    ref: ForwardedRef<BaseBottomSheetRef>,
  ) => (
    <BottomSheetWithView ref={ref} {...props}>
      {HeaderComponent ?? (
        <BottomSheetHeader
          showClose
          onPressClose={() =>
            isRefObject(ref) && ref.current?.handleClosePress()
          }
          {...headerProps}
        />
      )}
      {children}
    </BottomSheetWithView>
  ),
);

export { BottomSheetView };
