import { type PriceDto } from '@utility-nyc/react-query-sdk';

import {
  type QuoteNumber,
  stringifyQuoteNumber,
  stringifyQuoteNumberTick,
  apiSpecToQuoteNumber,
} from './quote-number';

type FullQuoteNumber = {
  bid: QuoteNumber | null;
  offer: QuoteNumber | null;
};

const stringifyFullQuoteNumber = (fullQuoteNumber: FullQuoteNumber) => {
  const { bid, offer } = fullQuoteNumber;
  const bidString = bid ? stringifyQuoteNumber(bid) : '-';
  const offerString = offer ? stringifyQuoteNumberTick(offer) : '-';

  return `${bidString}/${offerString}`;
};

const apiSpecToFullQuoteNumber = (
  bid: PriceDto | undefined,
  offer: PriceDto | undefined,
): FullQuoteNumber => ({
  bid: bid ? apiSpecToQuoteNumber(bid) : null,
  offer: offer ? apiSpecToQuoteNumber(offer) : null,
});

export {
  type FullQuoteNumber,
  stringifyFullQuoteNumber,
  apiSpecToFullQuoteNumber,
};
