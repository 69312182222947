import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { TradingNavigatorParamList } from '@south-street-app/navigation/types';
import type { TradeRequestComponentDto } from '@utility-nyc/react-query-sdk';

import React, { useCallback, useState } from 'react';

import { FlatList, StyleSheet, TouchableOpacity } from 'react-native';

import { XStack, YStack, useMedia } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { MINIMUM_TRADE_AMOUNT } from '@shared/services';
import { capitalizeFirstLetter, moneyStringToNumber } from '@shared/utils';
import { HeadingM, Icon, LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { InitialsCircle } from '@south-street-app/molecules';
import { useMobileTradeStore } from '@south-street-app/stores';

import { PreviewTradeButton } from './PreviewTradeButton';
import { SwapButtom } from './SwapButton';
import { TradeItem } from './TradeItem';
import { combinedProductName } from './combinedProductName';

const styles = StyleSheet.create({
  flatlist: {
    gap: 16,
  },
});

type FirmRollTradeOrderProps = {
  navigation: NativeStackNavigationProp<
    TradingNavigatorParamList,
    'TradeOrder'
  >;
};

type SwapTradeItemProps = {
  topTradeInput: Omit<TradeRequestComponentDto, 'price'>;
  handleTopTradeInputOnChangeValue: (value: string, userId: string) => void;
  bottomTradeInput: Omit<TradeRequestComponentDto, 'price'>;
  handleBottomTradeInputOnChangeValue: (value: string, userId: string) => void;
  handleSwap: () => void;
  userName: string;
  userId: string;
  deleteUser: (userId: string) => void;
};

const SwapTradeItem = ({
  bottomTradeInput,
  handleBottomTradeInputOnChangeValue,
  topTradeInput,
  handleTopTradeInputOnChangeValue,
  handleSwap,
  userName,
  userId,
  deleteUser,
}: SwapTradeItemProps) => {
  const deleteUserById = () => {
    deleteUser(userId);
  };

  const handleTopInputChange = (value: string) => {
    handleTopTradeInputOnChangeValue(value, userId);
  };

  const handleBottomInputChange = (value: string) => {
    handleBottomTradeInputOnChangeValue(value, userId);
  };

  return (
    <YStack
      padding={'$4'}
      borderWidth={'$px'}
      borderColor={'$mono200'}
      borderRadius={'$l'}
      space={'$4'}
    >
      <XStack
        alignItems={'center'}
        borderBottomWidth={'$px'}
        justifyContent={'space-between'}
        borderBottomColor={'$mono200'}
        paddingBottom={'$2'}
      >
        <XStack gap={'$2'} alignItems={'center'}>
          <InitialsCircle usePadding={false} name={userName} />
          <LabelL fontWeight={'$4'} color={'$black'}>
            {userName}
          </LabelL>
        </XStack>
        <TouchableOpacity onPress={deleteUserById} activeOpacity={0.6}>
          <Icon iconName={'deleteIcon'} color={'$mono400'} size={'$5'} />
        </TouchableOpacity>
      </XStack>
      <TradeItem
        label={capitalizeFirstLetter(topTradeInput.type)}
        product={combinedProductName(topTradeInput)}
        onChangeValue={handleTopInputChange}
      />
      <SwapButtom onPress={handleSwap} />
      <TradeItem
        disabled={topTradeInput.amount < 1}
        label={capitalizeFirstLetter(bottomTradeInput.type)}
        product={combinedProductName(bottomTradeInput)}
        onChangeValue={handleBottomInputChange}
      />
    </YStack>
  );
};

const FirmRollTradeOrder = ({ navigation }: FirmRollTradeOrderProps) => {
  const { desktop } = useMedia();
  const { proposal, setProposal, firmEndUsers, deleteEndUserById, setShares } =
    useMobileTradeStore(
      useShallow((state) => ({
        proposal: state.proposal,
        setProposal: state.setProposal,
        firmEndUsers: state.firmEndUsers,
        deleteEndUserById: state.deleteEndUserById,
        setShares: state.setShares,
      })),
    );

  const [topAmounts, setTopAmounts] = useState<Record<string, string>>(
    firmEndUsers.reduce<Record<string, string>>((acc, item) => {
      acc[item.id] = '0';

      return acc;
    }, {}),
  );

  const [bottomAmounts, setBottomAmounts] = useState<Record<string, string>>(
    firmEndUsers.reduce<Record<string, string>>((acc, item) => {
      acc[item.id] = '0';

      return acc;
    }, {}),
  );

  const firstProduct = proposal.components[0].product;

  const [topTradeInput, setTopTradeInput] = useState<
    Omit<TradeRequestComponentDto, 'price'>
  >({
    type: 'OFFER',
    product: firstProduct.leg1Product ?? firstProduct,
    amount: 0,
  });

  const [bottomTradeInput, setBottomTradeInput] = useState<
    Omit<TradeRequestComponentDto, 'price'>
  >({ type: 'BID', product: firstProduct, amount: 0 });

  const handleSwap = useCallback(() => {
    setTopTradeInput({
      ...topTradeInput,
      type: bottomTradeInput.type,
    });

    setBottomTradeInput({
      ...bottomTradeInput,
      type: topTradeInput.type,
    });
  }, [topTradeInput, bottomTradeInput]);

  const handlePreviewTrade = useCallback(() => {
    const topTradeInputProposal = {
      ...topTradeInput,
      amount: Object.values(topAmounts).reduce(
        (acc, item) => acc + moneyStringToNumber(item),
        0,
      ),
    };
    const bottomTradeInputProposal = {
      ...bottomTradeInput,
      amount: Object.values(bottomAmounts).reduce(
        (acc, item) => acc + moneyStringToNumber(item),
        0,
      ),
    };
    const bidPrice = topTradeInputProposal.product.bidPrice
      ? {
          par: moneyStringToNumber(
            topTradeInputProposal.product.bidPrice.par.toString(),
          ),
          fraction: topTradeInputProposal.product.bidPrice.fraction,
        }
      : undefined;
    const askPrice = bottomTradeInputProposal.product.askPrice
      ? {
          par: moneyStringToNumber(
            bottomTradeInputProposal.product.askPrice.par.toString(),
          ),
          fraction: bottomTradeInputProposal.product.askPrice.fraction,
        }
      : undefined;
    const shares = firmEndUsers.map((firmEndUser) => [
      {
        amount: moneyStringToNumber(topAmounts[firmEndUser.id]),
        associatedId: firmEndUser.id,
        type: topTradeInput.type,
      },
      {
        amount: moneyStringToNumber(bottomAmounts[firmEndUser.id]),
        associatedId: firmEndUser.id,
        type: bottomTradeInput.type,
      },
    ]);

    setShares(shares.flatMap((share) => share));

    setProposal({
      ...proposal,
      components: [
        {
          ...topTradeInputProposal,
          price: bidPrice,
        },
        {
          ...bottomTradeInputProposal,
          price: askPrice,
        },
      ],
    });

    navigation.navigate('PreviewTradeOrder');
  }, [
    bottomTradeInput,
    navigation,
    proposal,
    setProposal,
    topTradeInput,
    topAmounts,
    bottomAmounts,
    firmEndUsers,
    setShares,
  ]);

  const setTopAmountForUser = (value: string, userId: string) => {
    setTopAmounts((prev) => ({
      ...prev,
      [userId]: value,
    }));
  };

  const setBottomAmountForUser = (value: string, userId: string) => {
    setBottomAmounts((prev) => ({
      ...prev,
      [userId]: value,
    }));
  };

  const onDeleteUserById = (userId: string) => {
    if (firmEndUsers.length === 1) {
      navigation.goBack();
    }

    deleteEndUserById(userId);

    const newTopAmounts = { ...topAmounts };
    const newBottomAmounts = { ...bottomAmounts };

    delete newTopAmounts[userId];

    delete newBottomAmounts[userId];

    setTopAmounts(newTopAmounts);

    setBottomAmounts(newBottomAmounts);
  };

  return (
    <YStack
      flex={1}
      justifyContent={'space-between'}
      {...(desktop && {
        justifyContent: 'flex-start',
        width: 800,
        gap: '$16',
        marginHorizontal: '$auto',
      })}
    >
      <YStack flex={1} paddingBottom={'$6'}>
        <HeadingM fontWeight={'$3'} paddingTop={'$2'} paddingBottom={'$10'}>
          {en_US.roll}
        </HeadingM>

        <FlatList
          data={firmEndUsers}
          keyExtractor={(item) => item.id}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={styles.flatlist}
          renderItem={({ item }) => (
            <SwapTradeItem
              deleteUser={onDeleteUserById}
              userName={item.name}
              userId={item.id}
              bottomTradeInput={bottomTradeInput}
              handleBottomTradeInputOnChangeValue={setBottomAmountForUser}
              topTradeInput={topTradeInput}
              handleTopTradeInputOnChangeValue={setTopAmountForUser}
              handleSwap={handleSwap}
            />
          )}
        />
      </YStack>
      <PreviewTradeButton
        onPress={handlePreviewTrade}
        isReadyToPreview={
          Object.values(bottomAmounts).every(
            (value) => moneyStringToNumber(value) >= MINIMUM_TRADE_AMOUNT,
          ) &&
          Object.values(topAmounts).every(
            (value) => moneyStringToNumber(value) >= MINIMUM_TRADE_AMOUNT,
          )
        }
      />
    </YStack>
  );
};

export { FirmRollTradeOrder };
