import { YStack } from 'tamagui';

import { BodyS } from '@south-street-app/atoms';

const PricingDisclaimerBanner = () => (
  <YStack
    backgroundColor={'$mono100'}
    alignItems={'center'}
    paddingVertical={'$3'}
    paddingHorizontal={'$3'}
  >
    <BodyS color={'$mono400'}>
      {
        'Pricing data is updated every 10 seconds. It may not be fully accurate.'
      }
    </BodyS>
  </YStack>
);

export { PricingDisclaimerBanner };
