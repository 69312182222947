import React, { useCallback, useMemo } from 'react';

import { FlatList } from 'react-native';

import { XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { capitalizeFirstLetter, formatToMoney } from '@shared/utils';
import { BodyL, HeadingL, LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { SlideButton } from '@south-street-app/molecules';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';
import { useGetCurrentEndUser } from '@utility-nyc/react-query-sdk';

const Divider = () => (
  <YStack width={'$100%'} height={'$px'} backgroundColor={'$mono300'} />
);

const FirmSinglePreviewOrder = () => {
  const {
    createTradeRequest,
    setReceivedQuotedPrice,
    proposal,
    tradeType,
    firmEndUsers,
    shares,
    isTwilioConnected,
  } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      shares: state.shares,
      isTwilioConnected: state.isTwilioConnected,
      createTradeRequest: state.createTradeRequest,
      setReceivedQuotedPrice: state.setReceivedQuotedPrice,
      tradeType: state.tradeType,
      firmEndUsers: state.firmEndUsers,
    })),
  );
  const { data } = useGetCurrentEndUser();
  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );

  const formattedType = useMemo(
    () => capitalizeFirstLetter(proposal.components[0].type),
    [proposal],
  );
  const totalAmount = proposal.components.reduce(
    (sum, request) => sum + request.amount,
    0,
  );

  const firstComponent = proposal.components[0];

  const handleOnCompleted = useCallback(() => {
    try {
      setReceivedQuotedPrice(false);

      if (data?.data.id) {
        const tradeRequest = {
          components: [
            {
              productId: firstComponent.product.id,
              amount: firstComponent.amount,
              type: firstComponent.type,
            },
          ],
          type: tradeType,
          shares,
          endUserId: data.data.id,
        };

        createTradeRequest(tradeRequest);

        openBottomSheet({
          type: 'requestTrade',
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    createTradeRequest,
    firstComponent,
    openBottomSheet,
    setReceivedQuotedPrice,
    tradeType,
    data,
    shares,
  ]);

  return (
    <YStack flex={1} justifyContent={'space-between'}>
      <YStack>
        <HeadingL fontWeight={'$3'}>{formattedType}</HeadingL>
        <XStack justifyContent={'space-between'} paddingVertical={'$4'}>
          <LabelL fontWeight={'$2'}>{en_US.totalAmount}</LabelL>
          {totalAmount ? (
            <BodyL color={'$black'}>
              {'$'}
              {formatToMoney(totalAmount)}
            </BodyL>
          ) : null}
        </XStack>
        <Divider />
        <FlatList
          data={shares}
          keyExtractor={(item) => item.associatedId}
          renderItem={({ item }) => (
            <XStack justifyContent={'space-between'} paddingVertical={'$4'}>
              <LabelL fontWeight={'$2'}>
                {firmEndUsers.find((u) => u.id === item.associatedId)?.name}
              </LabelL>
              <BodyL color={'$black'}>
                {'$'}
                {formatToMoney(item.amount)}
              </BodyL>
            </XStack>
          )}
        />
        <Divider />
        <XStack justifyContent={'space-between'} paddingVertical={'$4'}>
          <LabelL fontWeight={'$2'}>{en_US.product}</LabelL>
          <BodyL color={'$black'}>{proposal.components[0].product.name}</BodyL>
        </XStack>
      </YStack>
      <SlideButton
        isLoading={!isTwilioConnected}
        label={
          isTwilioConnected
            ? en_US.submitTradeRequest
            : en_US.connectingToTradeNetwork
        }
        onCompleted={handleOnCompleted}
      />
    </YStack>
  );
};

export { FirmSinglePreviewOrder };
