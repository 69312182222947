import { useEffect } from 'react';

import { Platform } from 'react-native';

import { useBottomSheetWithControls } from '@utility-nyc/react-native-bottom-sheet';
import { useShallow } from 'zustand/react/shallow';

import { useGlobalBottomSheetStore } from '@south-street-app/stores';

import { SwitchGlobalBottomSheetContent } from './SwitchGlobalBottomSheetContent';
import { useGlobalBottomSheetControls } from './hooks';
import { BottomSheetView } from '../BottomSheetView';

const GlobalBottomSheet = () => {
  const { sheetRef, handleOpenBottomSheet, handleCloseBottomSheet } =
    useBottomSheetWithControls();

  const { state: storeState, resetBottomSheet } = useGlobalBottomSheetStore(
    useShallow((globalState) => ({
      state: globalState.state,
      resetBottomSheet: globalState.resetBottomSheet,
    })),
  );

  const { canDismissBottomSheet, showHandleComponent } =
    useGlobalBottomSheetControls();

  useEffect(() => {
    if (storeState.isOpen) {
      handleOpenBottomSheet();
    } else {
      handleCloseBottomSheet();
    }
  }, [handleCloseBottomSheet, handleOpenBottomSheet, storeState.isOpen]);

  useEffect(() => {
    if (!storeState.type && Platform.OS === 'web') {
      handleCloseBottomSheet();
    }
  }, [storeState.type, handleCloseBottomSheet]);

  return (
    <BottomSheetView
      ref={sheetRef}
      onClose={resetBottomSheet}
      enableHandlePanningGesture={Platform.OS === 'web' ? false : true}
      enableContentPanningGesture={Platform.OS === 'web' ? false : true}
      enablePanDownToClose={canDismissBottomSheet}
      disableBackgroundPress={!canDismissBottomSheet}
      handleComponent={showHandleComponent}
      keyboardBehavior={'interactive'}
      android_keyboardInputMode={'adjustPan'}
      animationConfigs={{
        duration: 150,
      }}
    >
      <SwitchGlobalBottomSheetContent />
    </BottomSheetView>
  );
};

export { GlobalBottomSheet };
