import type { TradeRequestComponentDto } from '@utility-nyc/react-query-sdk';

import { getShortMonthName } from '@shared/utils';

const combinedProductName = (input: Omit<TradeRequestComponentDto, 'price'>) =>
  input.product.name === '-'
    ? '-'
    : `${input.product.name} ${input.product.coupon} ${getShortMonthName(
        input.product.month,
      )}`;

export { combinedProductName };
