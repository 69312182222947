import type { TradingStackNavigationProps } from '@south-street-app/navigation/types';

import { useCallback } from 'react';

import { Platform, SafeAreaView, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import {
  getColorTokenValue,
  getSpaceTokenValue,
} from '@utility-nyc/react-native-ui-config';
import { Spacer, useMedia, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { Icon, BodyL, BodyXL, HeadingXL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { ButtonLarge } from '@south-street-app/molecules';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';
import {
  useGetCurrentEndUser,
  type TradeComponentInputDto,
} from '@utility-nyc/react-query-sdk';

const TIMEOUT_TYPE = {
  timeout: 'Trade timed out',
  rejected: 'Rejected trade',
  canceled: 'Trade cancelled',
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: getColorTokenValue('$mono100'),
  },
  pressStyle: {
    opacity: 0.7,
  },
});

const TradeTimeoutScreen = ({
  route: { params },
}: TradingStackNavigationProps<'TradeTimeout'>) => {
  const { timeoutType } = params;
  const navigation = useNavigation();
  const { desktop } = useMedia();
  const openBottomSheet = useGlobalBottomSheetStore(
    (state) => state.openBottomSheet,
  );

  const {
    proposal,
    createTradeRequest,
    setReceivedQuotedPrice,
    clearFirmEndUsers,
    tradeType,
    shares,
    clearPersistantMessages,
  } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      createTradeRequest: state.createTradeRequest,
      setReceivedQuotedPrice: state.setReceivedQuotedPrice,
      tradeType: state.tradeType,
      shares: state.shares,
      clearFirmEndUsers: state.clearFirmEndUsers,
      clearPersistantMessages: state.clearPersistantMessages,
    })),
  );
  const { data } = useGetCurrentEndUser();
  const reSubmitTrade = useCallback(() => {
    setReceivedQuotedPrice(false);

    const components: TradeComponentInputDto[] = proposal.components.map(
      (component) => ({
        productId: component.product.id,
        amount: component.amount,
        type: component.type,
      }),
    );

    if (data) {
      createTradeRequest({
        components,
        type: tradeType,
        shares,
        endUserId: data.data.id,
      });

      openBottomSheet({
        type: 'requestTrade',
      });
    }
  }, [
    createTradeRequest,
    proposal.components,
    openBottomSheet,
    shares,
    data,
    setReceivedQuotedPrice,
    tradeType,
  ]);

  const handleGoHome = useCallback(() => {
    clearFirmEndUsers();

    clearPersistantMessages();

    navigation.navigate('HomeTab', { screen: 'HomeScreen' });
  }, [navigation, clearPersistantMessages, clearFirmEndUsers]);

  const handleChatWithTrader = useCallback(() => {
    navigation.navigate('ChatScreen');
  }, [navigation]);

  return (
    <SafeAreaView style={styles.container}>
      <YStack
        paddingTop={'$16'}
        paddingHorizontal={'$4'}
        backgroundColor={'$mono100'}
        justifyContent={'space-between'}
        flex={1}
        {...(desktop && {
          width: 800,
          marginHorizontal: '$auto',
          justifyContent: 'flex-start',
          gap: '$16',
        })}
      >
        <YStack>
          <HeadingXL fontWeight={'$3'} paddingBottom={'$1'}>
            {TIMEOUT_TYPE[timeoutType]}
          </HeadingXL>
          <BodyXL>{en_US.tradeWasNotCompleted}</BodyXL>
          <Spacer height={'$6'} />
          <XStack
            alignItems={'center'}
            backgroundColor={'$mono200'}
            padding={'$5'}
            {...(Platform.OS === 'web' ? { cursor: 'pointer' } : undefined)}
            borderRadius={getSpaceTokenValue('$3')}
            justifyContent={'space-between'}
            pressStyle={styles.pressStyle}
            onPress={handleChatWithTrader}
          >
            <BodyL color={'$brand600'} fontWeight={'$2'}>
              {en_US.chatWithTrader}
            </BodyL>
            <Icon
              iconName={'chevronRightIcon'}
              size={'$6'}
              color={'$brand600'}
            />
          </XStack>
          <YStack />
        </YStack>
        <YStack space={'$2'} paddingBottom={'$3'}>
          <ButtonLarge
            variant={'secondary'}
            color={'$brand500'}
            fontWeight={'$2'}
            onPress={reSubmitTrade}
          >
            {en_US.reSubmitTrade}
          </ButtonLarge>
          <ButtonLarge variant={'primary'} onPress={handleGoHome}>
            {en_US.goHome}
          </ButtonLarge>
        </YStack>
      </YStack>
    </SafeAreaView>
  );
};

export { TradeTimeoutScreen };
