import type {
  ProductDto,
  TradeRequestProposalDto,
} from '@utility-nyc/react-query-sdk';

const getProductsByTypeOfTrade = (
  product: ProductDto,
  selectedProduct: string,
  proposal: TradeRequestProposalDto,
  isSwap: boolean,
) => {
  const isNotRoll = !product.leg1Product;

  return isSwap
    ? isNotRoll &&
        product.name.includes(selectedProduct) &&
        product.id !== proposal.components[0].product.id
    : product.name.includes(selectedProduct);
};

export { getProductsByTypeOfTrade };
