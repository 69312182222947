import type { Text, GetProps } from '@tamagui/core';

import { styled } from '@tamagui/core';
import {
  HeadingL as THeadingL,
  BodyL as TBodyL,
  ActionL as TActionL,
  HeadingM as THeadingM,
  BodyM as TBodyM,
  ActionM as TActionM,
  HeadingS as THeadingS,
  BodyS as TBodyS,
  ActionS as TActionS,
} from '@utility-nyc/react-native-ui-config';

const commonDefault: GetProps<typeof Text> = {
  fontFamily: '$body',
  color: '$black',
  lineHeight: '$3',
  maxFontSizeMultiplier: 1.8,
};

const basePressStyle = { opacity: 0.7 };

const BodyS = styled(TBodyS, {
  ...commonDefault,
  fontSize: '$1',
  lineHeight: '$2',
  fontWeight: '$1',
  letterSpacing: '$3',
});

const BodyM = styled(TBodyM, {
  ...commonDefault,
  fontSize: '$2',
  lineHeight: '$2',
  fontWeight: '$1',
  letterSpacing: '$3',
});

const BodyL = styled(TBodyL, {
  ...commonDefault,
  fontSize: '$4',
  lineHeight: '$2',
  fontWeight: '$1',
  letterSpacing: '$3',
});
const BodyXL = styled(TBodyL, {
  ...commonDefault,
  fontSize: '$5',
  lineHeight: '$5',
  fontWeight: '$1',
  letterSpacing: '$3',
});

const LabelS = styled(TActionS, {
  ...commonDefault,
  fontSize: '$1',
  lineHeight: '$2',
  fontWeight: '$1',
  letterSpacing: '$3',
});

const LabelM = styled(TActionM, {
  ...commonDefault,
  fontSize: '$2',
  lineHeight: '$2',
  fontWeight: '$1',
  letterSpacing: '$3',
});

const LabelL = styled(TActionL, {
  ...commonDefault,
  fontSize: '$4',
  lineHeight: '$3',
  fontWeight: '$1',
  letterSpacing: '$3',
});
const LabelXL = styled(TActionL, {
  ...commonDefault,
  fontSize: '$5',
  lineHeight: '$4',
  fontWeight: '$1',
  letterSpacing: '$3',
});

const HeadingS = styled(THeadingS, {
  ...commonDefault,
  fontSize: '$6',
  lineHeight: '$3',
  fontWeight: '$2',
  letterSpacing: '$3',
});

const HeadingM = styled(THeadingM, {
  ...commonDefault,
  fontSize: '$7',
  lineHeight: '$4',
  fontWeight: '$2',
  letterSpacing: '$3',
});

const HeadingL = styled(THeadingL, {
  ...commonDefault,
  fontSize: '$8',
  lineHeight: '$7',
  fontWeight: '$2',
  letterSpacing: '$3',
});

const HeadingXL = styled(THeadingL, {
  ...commonDefault,
  fontSize: '$9',
  lineHeight: '$9',
  fontWeight: '$1',
  letterSpacing: '$3',
});

const DisplayS = styled(THeadingS, {
  ...commonDefault,
  fontSize: '$10',
  lineHeight: '$10',
  fontWeight: '$3',
  letterSpacing: '$3',
});

const DisplayM = styled(THeadingM, {
  ...commonDefault,
  fontSize: '$11',
  lineHeight: '$11',
  fontWeight: '$3',
  letterSpacing: '$3',
});

const DisplayL = styled(THeadingL, {
  ...commonDefault,
  fontSize: '$12',
  lineHeight: '$12',
  fontWeight: '$3',
  letterSpacing: '$3',
});

const DisplayXL = styled(THeadingL, {
  ...commonDefault,
  fontSize: '$true',
  lineHeight: '$true',
  fontWeight: '$1',
  letterSpacing: '$3',
});

export {
  BodyS,
  BodyM,
  BodyL,
  BodyXL,
  LabelS,
  LabelM,
  LabelL,
  LabelXL,
  HeadingS,
  HeadingM,
  HeadingL,
  HeadingXL,
  DisplayS,
  DisplayM,
  DisplayL,
  DisplayXL,
  basePressStyle,
};
