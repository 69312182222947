const radius = {
  xs: 2,
  s: 4,
  m: 8,
  l: 24,
  xl: 36,
  '2xl': 64,
  full: 9999,
  true: 16,
  none: 0,
};

export { radius };
