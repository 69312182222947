import { Platform } from 'react-native';

const isAndroid = Platform.OS === 'android';
const isIOS = Platform.OS === 'ios';

const PlatformUtils = {
  isAndroid,
  isIOS,
};

export { PlatformUtils };
