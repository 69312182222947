import type { ListAssociatedResponseDto } from '@utility-nyc/react-query-sdk';

const sectionUsers = (mockedFirmUsers: ListAssociatedResponseDto[]) => {
  const groupedUsers = mockedFirmUsers.reduce(
    (acc: { [key: string]: ListAssociatedResponseDto[] }, user) => {
      const name = user.firstName + ' ' + user.lastName;
      const firstLetter = name.charAt(0).toUpperCase();

      // Explicit check using `in` to ensure existence of the key
      if (!(firstLetter in acc)) {
        acc[firstLetter] = [];
      }

      acc[firstLetter].push(user);

      return acc;
    },
    {},
  );

  const sectionedData = Object.keys(groupedUsers)
    .sort((a, b) => a.localeCompare(b))
    .map((letter) => ({
      title: letter,
      data: groupedUsers[letter],
    }));

  return sectionedData;
};

export { sectionUsers };
