import React from 'react';

import { FlatList, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { XStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { Icon, LabelL } from '@south-street-app/atoms';
import { InitialsCircle } from '@south-street-app/molecules';
import { useMobileTradeStore } from '@south-street-app/stores';

const styles = StyleSheet.create({
  flatlist: { display: 'flex', gap: -12 },
});

const TradeOrderHeader = () => {
  const navigation = useNavigation();
  const { firmEndUsers } = useMobileTradeStore(
    useShallow((state) => ({
      firmEndUsers: state.firmEndUsers,
    })),
  );

  const isFirmTrade = firmEndUsers.length >= 1;

  const data =
    firmEndUsers.length > 3
      ? [{ id: '-', name: '...' }].concat(firmEndUsers.slice(0, 3))
      : firmEndUsers;

  const onGoBack = () => {
    navigation.goBack();
  };

  return isFirmTrade ? (
    <XStack onPress={onGoBack} alignItems={'center'} gap={'$1'}>
      <FlatList
        data={data}
        keyExtractor={(item) => item.id}
        scrollEnabled={false}
        horizontal
        contentContainerStyle={styles.flatlist}
        renderItem={({ item }) => (
          <InitialsCircle
            hasBorders
            usePadding={false}
            size={'small'}
            name={item.name}
          />
        )}
      />
      <LabelL fontWeight={'600'}>
        {firmEndUsers.length}
        {' Clients'}
      </LabelL>
      <Icon iconName={'chevronDownIcon'} color={'$mono700'} />
    </XStack>
  ) : (
    <LabelL fontWeight={'600'}>{'Trade Order'}</LabelL>
  );
};

export { TradeOrderHeader };
