import { StyleSheet } from 'react-native';

import {
  getColorTokenValue,
  getSpaceTokenValue,
} from '@utility-nyc/react-native-ui-config';

const styles = StyleSheet.create({
  buttonSlider: {
    position: 'absolute',
    left: 0,
    width: getSpaceTokenValue('$18'),
    height: getSpaceTokenValue('$14'),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: getColorTokenValue('$brand500'),
    borderRadius: getSpaceTokenValue('$10'),
    zIndex: 4,
  },
  animatedProgress: {
    backgroundColor: getColorTokenValue('$brand500'),
    borderRadius: getSpaceTokenValue('$10'),

    position: 'absolute',
    left: 0,
  },
});

export { styles };
