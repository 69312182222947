const getShortMonthName = (monthNumber: number) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Check if the month number is valid
  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  }

  return 'Invalid Month';
};

export { getShortMonthName };
