import type { SelectorItemData } from '@south-street-app/molecules';
import type {
  ListProductResponseDto,
  ProductDto,
} from '@utility-nyc/react-query-sdk';

import { useCallback } from 'react';

import { Pressable } from 'react-native';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { useWindowDimensions, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { HeadingS, Icon } from '@south-street-app/atoms';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';
import { useGetInitialEndUserState } from '@utility-nyc/react-query-sdk';

import { ProductList } from '../../ProductList';

const ProductListBottomSheetContent = () => {
  const { height: windowHeight } = useWindowDimensions();

  const { data } = useGetInitialEndUserState();
  const { proposal, setProposal } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      setProposal: state.setProposal,
    })),
  );

  const closeBottomSheet = useGlobalBottomSheetStore(
    (state) => state.closeBottomSheet,
  );

  const products: SelectorItemData[] =
    data?.data.products.map((product: ListProductResponseDto) => ({
      label: product.name,
    })) ?? [];

  const handleOnChangeProduct = useCallback(
    (product: string, item: ProductDto) => {
      setProposal({
        ...proposal,
        components: [
          proposal.components[0],
          {
            ...proposal.components[1],
            product: item,
          },
        ],
      });

      closeBottomSheet();
    },
    [closeBottomSheet, proposal, setProposal],
  );

  return (
    <YStack flex={1} height={windowHeight - getSpaceTokenValue('$26')}>
      <XStack padding={'$4'} alignItems={'flex-end'} space={'$1'}>
        <Pressable onPress={closeBottomSheet}>
          <Icon iconName={'chevronLeftIcon'} color={'$mono700'} size={'$6'} />
        </Pressable>
        <HeadingS>{'Choose product'}</HeadingS>
      </XStack>
      <ProductList
        products={products}
        onChangeProduct={handleOnChangeProduct}
        isSwap={true}
      />
    </YStack>
  );
};

export { ProductListBottomSheetContent };
