import type { TradeRequestComponentDto } from '@utility-nyc/react-query-sdk';

import { useMemo } from 'react';

import { YStack } from 'tamagui';

import {
  apiSpecToQuoteNumber,
  capitalizeFirstLetter,
  formatToMoney,
  getShortMonthName,
  stringifyQuoteNumber,
} from '@shared/utils';
import { LabelXL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';

import { DisplayTradeItem } from '../PreviewTradeOrderScreen/DisplayTradeItem';

type DisplaySwapTradeInfoProps = {
  order: TradeRequestComponentDto;
};

const DisplaySwapTradeInfo = ({ order }: DisplaySwapTradeInfoProps) => {
  const { product, amount, type, price } = order;
  const { name, coupon, month } = product;

  const formattedType = useMemo(() => capitalizeFirstLetter(type), [type]);
  const formattedAmount = useMemo(() => `$${formatToMoney(amount)}`, [amount]);
  const formattedProductName = useMemo(
    () => `${name} ${coupon} ${getShortMonthName(month)}`,
    [coupon, month, name],
  );
  const formattedPrice = useMemo(
    () => stringifyQuoteNumber(apiSpecToQuoteNumber(price)),
    [price],
  );
  const formattedSettledDate = useMemo(
    () => new Date().toLocaleDateString('en-US'),
    [],
  );

  return (
    <YStack>
      <LabelXL color={'$mono400'} paddingTop={'$6'} fontWeight={'$3'}>
        {formattedType}
      </LabelXL>

      <YStack>
        <DisplayTradeItem label={en_US.amount} value={formattedAmount} />
        <DisplayTradeItem label={en_US.product} value={formattedProductName} />
        <DisplayTradeItem label={en_US.finalPrice} value={formattedPrice} />
        <DisplayTradeItem
          label={en_US.settledDate}
          value={formattedSettledDate}
        />
      </YStack>
    </YStack>
  );
};

export { DisplaySwapTradeInfo };
