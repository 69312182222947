import type { AxiosRequestConfig } from 'axios';

import Axios from 'axios';

const apiAxiosInstance = Axios.create({
  paramsSerializer: { indexes: null },
  baseURL: process.env['NEXT_PUBLIC_BASE_URL'],
});

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('@utility-nyc/react-auth-setup').setupAxiosInterceptors({
  axiosInstance: apiAxiosInstance,
  shouldAutoLogoutOnFailedSessionRefresh: true,
});

// add a second `options` argument here if you want to pass extra options to each generated query
const customAxios = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> =>
  apiAxiosInstance
    .request({
      ...config,
      ...options,
    })
    .then(({ data }) => data)
    .catch((e) => {
      throw e;
    });

export { customAxios };
