import type { RequestingTradeStackNavigationProps } from '@south-street-app/navigation/types';

import { useEffect } from 'react';

import { YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { HeadingXL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { Spinner } from '@south-street-app/molecules';
import { TradeCard } from '@south-street-app/organisms';
import { useMobileTradeStore } from '@south-street-app/stores';

import { tradeCardItems } from '../QuotedPriceScreen/tradeCardItems';

const TraderConfirmationScreen = ({
  navigation,
}: RequestingTradeStackNavigationProps<'TraderConfirmation'>) => {
  const { counterProposal, receivedQuotedPrice } = useMobileTradeStore(
    useShallow((state) => ({
      counterProposal: state.counterProposal,
      receivedQuotedPrice: state.receivedQuotedPrice,
    })),
  );

  useEffect(() => {
    if (receivedQuotedPrice) {
      navigation.navigate('QuotedPrice', {
        title: en_US.traderHasReadjustedTheQuote,
        description: en_US.reviewNewQuotedPrice,
      });
    }
  }, [navigation, receivedQuotedPrice]);

  return (
    <YStack flex={1} padding={'$4'} justifyContent={'space-between'}>
      <YStack paddingTop={'$8'}>
        <HeadingXL fontWeight={'$3'} paddingBottom={'$6'}>
          {en_US.awaitingTraderConfirmation}
        </HeadingXL>
        <TradeCard items={tradeCardItems(counterProposal)} />
        <Spinner
          label={en_US.awaitingTraderConfirmation}
          labelMaxWidth={'$44'}
        />
      </YStack>
    </YStack>
  );
};

export { TraderConfirmationScreen };
