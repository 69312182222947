import { useMedia, YStack } from 'tamagui';

import { Icon, BodyL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';

const HistoryListEmptyComponent = () => {
  const { desktop } = useMedia();

  return (
    <YStack
      flex={1}
      justifyContent={'center'}
      alignItems={'center'}
      paddingTop={'$15'}
      paddingHorizontal={'$12'}
      paddingBottom={desktop ? '$15' : undefined}
      space={'$4'}
      borderBottomColor={desktop ? '$mono300' : undefined}
      borderBottomWidth={desktop ? 1 : undefined}
    >
      <YStack
        borderRadius={'$xl'}
        padding={'$3.5'}
        backgroundColor={'$mono200'}
      >
        <Icon iconName={'infoIcon'} color={'$black'} />
      </YStack>
      <BodyL textAlign={'center'}>{en_US.historyList.emptyList}</BodyL>
    </YStack>
  );
};

export { HistoryListEmptyComponent };
