import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { TradingNavigatorParamList } from '@south-street-app/navigation/types';

import { useShallow } from 'zustand/react/shallow';

import { useMobileTradeStore } from '@south-street-app/stores';

import { FirmRollTradeOrder } from './FirmRollTradeOrder';
import { FirmSingleTradeOrder } from './FirmSingleTradeOrder';
import { FirmSwapTradeOrder } from './FirmSwapTradeOrder';
import { RolltradeOrder } from './RollTradeOrder';
import { SingleTradeOrder } from './SingleTradeOrder';
import { SwapTradeOrder } from './SwapTradeOrder';

type SwitchTradeRenderProps = {
  navigation: NativeStackNavigationProp<
    TradingNavigatorParamList,
    'TradeOrder'
  >;
};

const SwitchTradeRender = ({ navigation }: SwitchTradeRenderProps) => {
  const { tradeType, firmEndUsers } = useMobileTradeStore(
    useShallow((state) => ({
      tradeType: state.tradeType,
      firmEndUsers: state.firmEndUsers,
    })),
  );

  switch (tradeType) {
    case 'SWAP':
      if (firmEndUsers.length > 0) {
        return <FirmSwapTradeOrder navigation={navigation} />;
      }

      return <SwapTradeOrder navigation={navigation} />;
    case 'ROLL':
      if (firmEndUsers.length > 0) {
        return <FirmRollTradeOrder navigation={navigation} />;
      }

      return <RolltradeOrder navigation={navigation} />;
    default:
      if (firmEndUsers.length > 0) {
        return <FirmSingleTradeOrder navigation={navigation} />;
      }

      return <SingleTradeOrder navigation={navigation} />;
  }
};

export { SwitchTradeRender };
