import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { useMedia, useWindowDimensions, YStack } from 'tamagui';

import { RequestingTradeStackNavigator } from '@south-street-app/navigation';

const RequestTradeBottomSheetContent = () => {
  const { height: windowHeight } = useWindowDimensions();
  const { desktop } = useMedia();

  return (
    <YStack
      flex={1}
      padding={desktop ? '$6' : undefined}
      height={windowHeight - getSpaceTokenValue('$26')}
    >
      <RequestingTradeStackNavigator />
    </YStack>
  );
};

export { RequestTradeBottomSheetContent };
