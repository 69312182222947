import type {
  ListProductResponseDto,
  ProductDto,
} from '@utility-nyc/react-query-sdk';

import { useNavigation } from '@react-navigation/native';
import { YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import {
  PricingDisclaimerBanner,
  type SelectorItemData,
} from '@south-street-app/molecules';
import { ProductList } from '@south-street-app/organisms';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';
import {
  useGetAnalyticFirmAssociates,
  useGetCurrentEndUser,
  useGetInitialEndUserState,
} from '@utility-nyc/react-query-sdk';

const TradingScreen = () => {
  useGetAnalyticFirmAssociates();

  const navigation = useNavigation();
  const openBottomSheet = useGlobalBottomSheetStore(
    (state) => state.openBottomSheet,
  );
  const { setTradeType, setProposal, proposal } = useMobileTradeStore(
    useShallow((state) => ({
      setTradeType: state.setTradeType,
      setProposal: state.setProposal,
      proposal: state.proposal,
    })),
  );
  const { data: userDetails } = useGetCurrentEndUser();

  const { data } = useGetInitialEndUserState();
  const products: SelectorItemData[] =
    data?.data.products.map((product: ListProductResponseDto) => ({
      label: product.name,
    })) ?? [];

  const handleOnChangeProduct = (product: string, item: ProductDto) => {
    setProposal({
      ...proposal,
      components: [
        {
          amount: 0,
          price: {
            par: 0,
            fraction: 0,
          },
          product: item,
          type: 'BID',
        },
      ],
    });

    if (item.leg1Product) {
      setTradeType('ROLL');

      return navigation.navigate('TradeScreens', {
        screen:
          userDetails?.data.role === 'ANALYTIC_FIRM'
            ? 'FirmSelectClientsScreen'
            : 'TradeOrder',
      });
    }

    openBottomSheet({
      type: 'tradeSelector',
    });
  };

  return (
    <YStack flex={1} backgroundColor={'$mono100'}>
      <ProductList
        products={products}
        onChangeProduct={handleOnChangeProduct}
      />
      <PricingDisclaimerBanner />
    </YStack>
  );
};

export { TradingScreen };
