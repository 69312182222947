import type { PoolColorRecordResponseDto } from '@utility-nyc/react-query-sdk';

import { useCallback } from 'react';

import { FlatList, Platform, StyleSheet } from 'react-native';

import { YStack, XStack, useMedia, Spacer } from 'tamagui';

import { formattedDate } from '@shared/utils';
import { BodyM, BodyS, LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { useCouponColor } from '@south-street-app/hooks';
import { PoolColorListItem } from '@south-street-app/organisms';
import { useListPoolColors } from '@utility-nyc/react-query-sdk';

const styles = StyleSheet.create({
  columnWebWidth: { width: '57%' },
  columnNativeWidth: { flex: 4 },
  '25%': { width: '25%' },
  '75%': { width: '75%' },
  '43%': { width: '43%', alignItems: 'flex-end' },
  'flex-2': { flex: 2 },
  'flex-3': { flex: 3 },
  'flex-1': { flex: 1 },
});

const ListTitles = () => {
  const { desktop } = useMedia();
  const ListTitleText = desktop ? LabelL : BodyS;

  return (
    <>
      <XStack
        paddingHorizontal={'$4'}
        paddingVertical={'$2'}
        backgroundColor={'$mono100'}
        width={desktop ? undefined : '$100%'}
      >
        <XStack
          style={desktop ? styles.columnWebWidth : styles.columnNativeWidth}
        >
          <YStack style={desktop ? styles['75%'] : styles['flex-2']}>
            <ListTitleText fontWeight={'$3'} color={'$mono900'}>
              {en_US.type}
            </ListTitleText>
          </YStack>
          <YStack style={desktop ? styles['25%'] : styles['flex-1']}>
            <ListTitleText fontWeight={'$3'} color={'$mono900'}>
              {en_US.coupon}
            </ListTitleText>
          </YStack>
        </XStack>
        <YStack
          style={desktop ? styles['43%'] : styles['flex-3']}
          paddingLeft={'$10'}
        >
          <ListTitleText fontWeight={'$3'} color={'$mono900'}>
            {en_US.color}
          </ListTitleText>
        </YStack>
      </XStack>
      <YStack height={'$px'} width={'$100%'} backgroundColor={'$mono200'} />
    </>
  );
};

const PoolColorScreen = () => {
  const { data } = useListPoolColors();
  const { getColorForCoupon } = useCouponColor();
  const { desktop } = useMedia();
  const ItemSeparator = useCallback(
    () =>
      Platform.select({
        native: (
          <Spacer height={'$px'} width={'$100%'} backgroundColor={'$mono200'} />
        ),
        web: (
          <YStack height={'$px'} width={'$100%'} backgroundColor={'$mono200'} />
        ),
      }),
    [],
  );
  const renderItem = useCallback(
    ({ item, index }: { item: PoolColorRecordResponseDto; index: number }) => {
      const { backgroundColor, textColor } = getColorForCoupon(
        'poolColor',
        item.coupon,
      );

      return (
        <PoolColorListItem
          item={item}
          index={index}
          backgroundColor={backgroundColor}
          fontColor={textColor}
        />
      );
    },
    [getColorForCoupon],
  );

  return (
    <YStack flex={1} backgroundColor={'$mono100'} alignItems={'center'}>
      <YStack paddingVertical={'$2'} alignItems={'center'}>
        <BodyM color={'$mono900'}>
          {formattedDate(data?.data.updatedAt || '')}
        </BodyM>
      </YStack>
      <YStack
        flex={1}
        {...(desktop
          ? { borderWidth: '$px', borderColor: '$mono200' }
          : undefined)}
        width={desktop ? '$200' : undefined}
      >
        <ListTitles />
        <YStack flex={1}>
          <FlatList
            showsVerticalScrollIndicator={desktop ? false : undefined}
            data={data?.data.items}
            ItemSeparatorComponent={ItemSeparator}
            renderItem={renderItem}
          />
        </YStack>
      </YStack>
    </YStack>
  );
};

export { PoolColorScreen };
