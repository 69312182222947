import { add, equal, floor, round } from 'mathjs';

const QUOTE_NUMBER_REGEX = /^-?\d+-([0-2]?\d|3[01])(\.\d{1,3}|\+)?$/;

const MAX_TICK_VALUE = 32;
const MIN_PRECISION = 2;
const MAX_PRECISION = 3;
const HALF_TICK_SIGN_VALUE = 0.5;
const DEFAULT_PRECISION = 2;

type QuoteNumber = {
  point: number;
  tick: number;
  precision?: 2 | 3;
};

const isNegativeNumber = (num: number) => num < 0 || Object.is(num, -0);

const isValidQuoteNumber = (quoteNumber: string): boolean =>
  QUOTE_NUMBER_REGEX.test(quoteNumber);

const addQuoteNumbers = (...quoteNumbers: (QuoteNumber | null)[]) => {
  if (quoteNumbers.length < 2) {
    return quoteNumbers[0];
  }

  return quoteNumbers.reduce((quoteNumber, addingQuoteNumber) => {
    if (!quoteNumber || !addingQuoteNumber) {
      return null;
    }

    const { point, tick, precision = DEFAULT_PRECISION } = quoteNumber;
    const {
      point: addingPoint,
      tick: addingTick,
      precision: addingPrecision = DEFAULT_PRECISION,
    } = addingQuoteNumber;
    const isNegative = isNegativeNumber(addingPoint);

    let resultingPoint = add(point, addingPoint);
    let resultingTick = add(tick, addingTick * (isNegative ? -1 : 1));
    const resultingPrecision = Math.max(
      precision,
      addingPrecision,
    ) as QuoteNumber['precision'];

    if (resultingTick < 0) {
      resultingPoint--;

      resultingTick = MAX_TICK_VALUE - Math.abs(resultingTick);
    } else if (resultingTick >= MAX_TICK_VALUE) {
      resultingPoint++;

      resultingTick = resultingTick % MAX_TICK_VALUE;
    }

    return {
      point: resultingPoint,
      tick: round(resultingTick, resultingPrecision),
      precision: resultingPrecision,
    };
  });
};

const parseQuoteNumber = (quoteNumber: string): QuoteNumber => {
  if (!isValidQuoteNumber(quoteNumber)) {
    throw new Error('Provided `quoteNumber` is not in a valid format.');
  }

  const isNegative = quoteNumber.startsWith('-');
  const hasHalfTickSign = quoteNumber.endsWith('+');
  const quoteNumberParts = quoteNumber.substring(isNegative ? 1 : 0).split('-');

  const [point, tick] = quoteNumberParts;
  const pointNumber = Number(point);
  const tickNumber = Number(tick.replace(/[^0-9.]/g, ''));
  const precision = parseTickPrecision(tickNumber);

  return {
    point: pointNumber * (isNegative ? -1 : 1),
    tick: round(
      tickNumber + (hasHalfTickSign ? HALF_TICK_SIGN_VALUE : 0),
      precision,
    ),
    precision,
  };
};

const parseTickPrecision = (
  tick: number | string,
): QuoteNumber['precision'] => {
  const decimalsMatch = tick.toString().match(/\.(\d+)/);
  const foundPrecision = decimalsMatch?.[1]?.length ?? -1;

  if (foundPrecision < MIN_PRECISION || foundPrecision > MAX_PRECISION) {
    return DEFAULT_PRECISION;
  }

  return foundPrecision as 2 | 3;
};

const stringifyQuoteNumber = (quoteNumber: QuoteNumber | null) => {
  if (!quoteNumber) {
    return '-';
  }

  return (
    stringifyQuoteNumberPoint(quoteNumber) +
    '-' +
    stringifyQuoteNumberTick(quoteNumber)
  );
};

const stringifyQuoteNumberPoint = (quoteNumber: QuoteNumber): string => {
  const { point } = quoteNumber;

  const absolutePoint = Math.abs(point);
  const sign = isNegativeNumber(point) ? '-' : '';

  return sign + absolutePoint.toString().padStart(2, '0');
};

const stringifyQuoteNumberTick = (quoteNumber: QuoteNumber): string => {
  const { tick, precision = DEFAULT_PRECISION } = quoteNumber;

  const tickInteger = floor(tick);
  const tickFraction = round(tick % 1, precision);
  const hasHalfTick = equal(tickFraction, 0.5);

  const sanitizedTick =
    hasHalfTick || !tickFraction
      ? tickInteger
      : round(tick, precision).toFixed(precision);

  return [
    // Adds padding to ensure tick is at least 2 digits long.
    tick < 10 ? '0' : '',

    sanitizedTick,

    // Adds the half tick sign if applicable.
    hasHalfTick ? '+' : '',
  ].join('');
};

const isEqualQuoteNumber = (
  firstQuoteNumber: QuoteNumber,
  secondQuoteNumber: QuoteNumber,
) =>
  firstQuoteNumber.point === secondQuoteNumber.point &&
  firstQuoteNumber.tick === secondQuoteNumber.tick;

const toNegativeQuoteNumber = (quoteNumber: QuoteNumber): QuoteNumber => ({
  ...quoteNumber,
  point: -quoteNumber.point,
});

type PriceDto = {
  par: number;
  fraction: number;
};

const quoteNumberToApiSpec = (quoteNumber: QuoteNumber): PriceDto => ({
  par: quoteNumber.point,
  fraction: quoteNumber.tick,
});

const apiSpecToQuoteNumber = (
  obj: PriceDto | undefined | null,
): QuoteNumber | null => {
  if (!obj) {
    return null;
  }

  return {
    point: obj.par,
    tick: obj.fraction,
    precision: parseTickPrecision(obj.fraction),
  };
};

/**
 * This function formats a spread value (composed by par and fraction) into spread notation.
 *
 * The spread notation is composed by three parts:
 * 1 - Par - an integer ranging from -1 to 1. 1 par === 32 fractions.
 * 2 - Fraction - an integer ranging from 0 to 31. 1 fraction === 8 basis points.
 * 3 - Basis points (optional) - an integer ranging from 0 to 7. 1 basis point === 0.125. If basis points is 4 we replace it with the "+" signal
 *
 * Examples:
 * 1)
 *  Spread value:
 *    - Par: 0
 *    - Fraction: 3.125
 *  Broken into par/fraction/basis points:
 *    - Par: 0
 *    - Fraction: 3
 *    - Basis points: 1
 *  Spread notation: 031
 *
 * 2)
 *  Spread value:
 *    - Par: 0
 *    - Fraction: 8.375
 *  Broken into par/fraction/basis points:
 *    - Par: 0
 *    - Fraction: 8
 *    - Basis points: 3
 *  Spread notation: 083
 *
 * 3)
 *  Spread value:
 *    - Par: 0
 *    - Fraction: 2.5
 *  Broken into par/fraction/basis points:
 *    - Par: 0
 *    - Fraction: 2
 *    - Basis points: 4
 *  Spread notation: 02+
 *
 * 4)
 *  Spread value:
 *    - Par: 0
 *    - Fraction: -0.25
 *  Broken into par/fraction/basis points:
 *    - Par: 0
 *    - Fraction: 0
 *    - Basis points: 2
 *  Spread notation: -002
 *
 * 5)
 *  Spread value:
 *    - Par: 0
 *    - Fraction: 0
 *  Broken into par/fraction/basis points:
 *    - Par: 0
 *    - Fraction: 0
 *    - Basis points: 0
 *  Spread notation: 00
 *
 * 6)
 *  Spread value: null
 *  Spread notation: -
 */
const stringifyQuoteNumberSpread = (spread: QuoteNumber | null) => {
  if (!spread) {
    return '-';
  }

  const { point, tick } = spread;
  const totalTicks = point * 32 + tick;
  const symbol = totalTicks < 0 ? '-' : '';

  const absFractionWithoutBasis = Math.trunc(Math.abs(totalTicks));
  const formattedTicks =
    absFractionWithoutBasis < 10
      ? `0${absFractionWithoutBasis}`
      : absFractionWithoutBasis;

  const tickDecimals = Math.abs(totalTicks) % 1;

  if (tickDecimals) {
    // One basis point = 0.0125
    const basisPoints = tickDecimals === 0.5 ? '+' : tickDecimals / 0.125;

    return `${symbol}${formattedTicks}${basisPoints}`;
  }

  return `${symbol}${formattedTicks}`;
};

// Mock for ProductDto[]
const rolls = [
  {
    bidSpread: {
      par: 0,
      fraction: -0.25,
    },
    askSpread: {
      par: 0,
      fraction: -0.75,
    },
  },
  {
    bidSpread: {
      par: 0,
      fraction: -1.5,
    },
    askSpread: {
      par: 0,
      fraction: -1.75,
    },
  },
  {
    bidSpread: {
      par: 0,
      fraction: 3.25,
    },
    askSpread: {
      par: 0,
      fraction: 3.125,
    },
  },
  {
    bidSpread: {
      par: 0,
      fraction: -0.5,
    },
    askSpread: {
      par: 0,
      fraction: 1.5,
    },
  },
  {
    bidSpread: null,
    askSpread: null,
  },
];

const spreadsAsQuoteNumbers: [QuoteNumber | null, QuoteNumber | null][] =
  rolls.map(({ bidSpread, askSpread }) => [
    bidSpread ? { point: bidSpread.par, tick: bidSpread.fraction } : null,
    askSpread ? { point: askSpread.par, tick: askSpread.fraction } : null,
  ]);

spreadsAsQuoteNumbers.forEach(([bid, ask], idx) => {
  console.log(
    'Bid Spread',
    rolls[idx].bidSpread,
    '\nAsk Spread',
    rolls[idx].askSpread,
    '\nStringified',
    `${stringifyQuoteNumberSpread(bid)}/${stringifyQuoteNumberSpread(ask)}\n\n`,
  );
});
/**
 * Results

Bid Spread { par: 0, fraction: -0.25 }
Ask Spread { par: 0, fraction: -0.75 }
Stringified -002/-006


Bid Spread { par: 0, fraction: -1.5 }
Ask Spread { par: 0, fraction: -1.75 }
Stringified -01+/-016


Bid Spread { par: 0, fraction: 3.25 }
Ask Spread { par: 0, fraction: 3.125 }
Stringified 032/031


Bid Spread { par: 0, fraction: -0.5 }
Ask Spread { par: 0, fraction: 1.5 }
Stringified -00+/01+


Bid Spread null
Ask Spread null
Stringified -/-
 */

export {
  type QuoteNumber,
  addQuoteNumbers,
  isValidQuoteNumber,
  isEqualQuoteNumber,
  parseQuoteNumber,
  stringifyQuoteNumber,
  stringifyQuoteNumberTick,
  stringifyQuoteNumberSpread,
  quoteNumberToApiSpec,
  apiSpecToQuoteNumber,
  toNegativeQuoteNumber,
};
