import type { SizeTokens } from 'tamagui';

import { getTokens } from 'tamagui';
type DimensionValue = number | 'auto' | `${number}%`;

/**
 * @param sizeToken tamagui size token
 * @returns value for the sizeToken
 */
const getSizeTokenValue = (sizeToken: SizeTokens): DimensionValue => {
  const sizeTokens = getTokens({ prefixed: true }).size;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const maybeSpaceTokenObj = sizeTokens[sizeToken];

  if (
    typeof maybeSpaceTokenObj !== 'number' &&
    typeof maybeSpaceTokenObj !== 'string'
  ) {
    if (
      typeof maybeSpaceTokenObj.val !== 'number' &&
      typeof maybeSpaceTokenObj.val !== 'string'
    ) {
      throw new Error(`Wrong size type for ${sizeToken}`);
    } else {
      return checkValueType(maybeSpaceTokenObj.val);
    }
  }

  return checkValueType(maybeSpaceTokenObj);
};

const checkValueType = (valueChecking: number | string): DimensionValue => {
  if (typeof valueChecking === 'string') {
    //This makes sure that the value is a number followed by a % sign
    if (/^\d+%$/.test(valueChecking)) {
      return valueChecking as DimensionValue;
    }

    throw new Error(`Wrong size type for ${valueChecking}`);
  }

  if (typeof valueChecking === 'number') {
    return valueChecking;
  }

  throw new Error(`Wrong size type for ${valueChecking}`);
};

export { getSizeTokenValue };
