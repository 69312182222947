import type { NetInfoState } from '@react-native-community/netinfo';

import { useCallback, useEffect, useRef, useState } from 'react';

import {
  fetch as netInfoFetch,
  addEventListener as netInfoAddEventListener,
} from '@react-native-community/netinfo';

const SET_ERROR_TIMEOUT = 10000;
const FIVE_HUNDRED = 500;

const useSetupNetworkConnectionChange = () => {
  const [hasNetworkConnection, setHasNetworkConnection] = useState(true);
  const errorTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearSetErrorTimeout = useCallback(() => {
    if (errorTimeout.current) {
      clearTimeout(errorTimeout.current);

      errorTimeout.current = null;
    }
  }, []);

  const queueSetError = useCallback(() => {
    if (!errorTimeout.current) {
      errorTimeout.current = setTimeout(
        () => setHasNetworkConnection(false),
        SET_ERROR_TIMEOUT,
      );
    }
  }, []);

  const handleConnectionChange = useCallback(
    (connectionInfo: NetInfoState) => {
      const { isInternetReachable } = connectionInfo;

      if (isInternetReachable === false) {
        queueSetError();
      } else if (isInternetReachable === true) {
        setHasNetworkConnection(true);

        clearSetErrorTimeout();
      }
    },
    [clearSetErrorTimeout, queueSetError],
  );

  const handleInitialNetInfoState = useCallback(async () => {
    const initialNetInfoState = await netInfoFetch();

    // we want to immediately set the network state upon app first load / whenever this effect is run
    // For some reason, on iOS, `setNetworkError` needs to be queued up
    // I see no harm/downside to implementing this workaround
    if (initialNetInfoState.isInternetReachable === false) {
      setTimeout(() => setHasNetworkConnection(false), FIVE_HUNDRED);
    }
  }, []);

  useEffect(() => {
    handleInitialNetInfoState();

    const unsubscribe = netInfoAddEventListener(handleConnectionChange);

    return () => {
      clearSetErrorTimeout();

      unsubscribe();
    };
  }, [clearSetErrorTimeout, handleConnectionChange, handleInitialNetInfoState]);

  return hasNetworkConnection;
};

export { useSetupNetworkConnectionChange };
