import type { ProductDto } from '@utility-nyc/react-query-sdk';
import type { ColorTokens } from 'tamagui';

import { Platform, StyleSheet } from 'react-native';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { XStack, YStack } from 'tamagui';

import {
  apiSpecToFullQuoteNumber,
  apiSpecToQuoteNumber,
  getShortMonthName,
  stringifyFullQuoteNumber,
  stringifyQuoteNumberSpread,
} from '@shared/utils';
import { LabelL, LabelS } from '@south-street-app/atoms';

type ProductListItemProps = {
  item: ProductDto;
  index: number;
  onPress: () => void;
  backgroundColor: ColorTokens;
  textColor: ColorTokens;
};

const styles = StyleSheet.create({
  pressStyle: {
    opacity: 0.7,
  },
});

const ProductListItem = ({
  item,
  onPress,
  backgroundColor,
  textColor,
}: ProductListItemProps) => {
  const isRoll = item.leg1Product;

  return (
    <XStack
      padding={'$4'}
      backgroundColor={'$mono100'}
      borderRadius={getSpaceTokenValue('$4')}
      paddingHorizontal={'$4'}
      paddingVertical={'$3.5'}
      alignItems={'center'}
      pressStyle={styles.pressStyle}
      onPress={onPress}
      {...(Platform.OS === 'web' ? { cursor: 'pointer' } : undefined)}
    >
      <XStack
        {...(Platform.OS === 'web' ? { width: '$half' } : { flex: 3 })}
        alignItems={'center'}
      >
        <YStack
          {...(Platform.OS === 'web' ? { width: '$quarter' } : { flex: 1 })}
          width={'$quarter'}
          height={'$8'}
          borderRadius={getSpaceTokenValue('$10')}
          justifyContent={'center'}
          alignItems={'center'}
          backgroundColor={backgroundColor}
        >
          <LabelS fontSize={'$3'} fontWeight={'$2'} color={textColor}>
            {item.coupon}
          </LabelS>
        </YStack>
        <YStack
          {...(Platform.OS === 'web' ? { width: '$3/4' } : { flex: 2 })}
          paddingLeft={'$5'}
          width={'$3/4'}
        >
          <LabelL fontWeight={'$2'}>
            {item.leg1Product
              ? `${getShortMonthName(
                  item.leg1Product.month,
                )} / ${getShortMonthName(item.month)}`
              : getShortMonthName(item.month)}
          </LabelL>
        </YStack>
      </XStack>
      <YStack {...(Platform.OS === 'web' ? { width: '$half' } : { flex: 2 })}>
        <LabelL fontWeight={'$2'} color={'$mono900'}>
          {isRoll
            ? `${stringifyQuoteNumberSpread(
                apiSpecToQuoteNumber(item.bidSpread),
              )}/${stringifyQuoteNumberSpread(
                apiSpecToQuoteNumber(item.askSpread),
              )}`
            : `${stringifyFullQuoteNumber(
                apiSpecToFullQuoteNumber(item.bidPrice, item.askPrice),
              )}`}
        </LabelL>
      </YStack>
    </XStack>
  );
};

export { ProductListItem };
