import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { TradingNavigatorParamList } from '@south-street-app/navigation/types';

import { useCallback, useMemo, useState } from 'react';

import { useMedia, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { MINIMUM_TRADE_AMOUNT } from '@shared/services';
import {
  capitalizeFirstLetter,
  getShortMonthName,
  moneyStringToNumber,
} from '@shared/utils';
import { useMobileTradeStore } from '@south-street-app/stores';

import { PreviewTradeButton } from './PreviewTradeButton';
import { TradeItem } from './TradeItem';

type SingleTradeOrderProps = {
  navigation: NativeStackNavigationProp<
    TradingNavigatorParamList,
    'TradeOrder'
  >;
};

const SingleTradeOrder = ({ navigation }: SingleTradeOrderProps) => {
  const { desktop } = useMedia();
  const { proposal, setProposal, tradeType } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      setProposal: state.setProposal,
      tradeType: state.tradeType,
    })),
  );

  const [amount, setAmount] = useState('');

  const handlePreviewTrade = useCallback(() => {
    setProposal({
      ...proposal,
      components: [
        {
          ...proposal.components[0],
          amount: moneyStringToNumber(amount),
          type: tradeType === 'BID' ? 'BID' : 'OFFER',
        },
      ],
    });

    navigation.navigate('PreviewTradeOrder');
  }, [setProposal, proposal, amount, tradeType, navigation]);

  const formattedProductName = useMemo(
    () =>
      `${proposal.components[0].product.name} ${
        proposal.components[0].product.coupon
      } ${getShortMonthName(proposal.components[0].product.month)}`,
    [proposal.components],
  );

  return (
    <YStack
      flex={1}
      paddingTop={'$5'}
      justifyContent={'space-between'}
      {...(desktop && {
        justifyContent: 'flex-start',
        gap: '$16',
        width: 800,
        marginHorizontal: '$auto',
      })}
    >
      <TradeItem
        label={capitalizeFirstLetter(tradeType)}
        product={formattedProductName}
        onChangeValue={setAmount}
      />

      <PreviewTradeButton
        onPress={handlePreviewTrade}
        isReadyToPreview={moneyStringToNumber(amount) >= MINIMUM_TRADE_AMOUNT}
      />
    </YStack>
  );
};

export { SingleTradeOrder };
