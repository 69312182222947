import type { LinkingOptions } from '@react-navigation/native';
import type { RootStackNavigatorParamList } from '@south-street-app/navigation/types';

import React from 'react';

import { StyleSheet, Platform } from 'react-native';

import appConfig from '@default-tamagui-config';
import { NavigationContainer } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import { images } from '@shared/assets';
import { setupSentry } from '@south-street-app/configs';
import { toastConfig } from '@south-street-app/molecules';
import { RootStackNavigator } from '@south-street-app/navigation';
import { GlobalBottomSheet } from '@south-street-app/organisms';
import {
  NoConnectionProvider,
  QueryProvider,
  CheckJailbrokenProvider,
} from '@south-street-app/providers';
import { useCustomFonts, useFontsWebLoader } from '@south-street-app/theme';
import { TwilioWrapper } from '@south-street-app/utils';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Toast from 'react-native-toast-message';
import { TamaguiProvider } from 'tamagui';

const SplashView = Platform.select({
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  native: () => require('@utility-nyc/react-native-splash-view').SplashView,
  web: () => null,
});

const SplashViewComponent = SplashView ? SplashView() : null;

const styles = StyleSheet.create({
  gestureHandler: {
    flex: 1,
  },
});

const linking: LinkingOptions<RootStackNavigatorParamList> = {
  prefixes: ['sss://', 'southstreetsecurities://'],
  config: {
    screens: {
      PublicStackNavigator: {
        screens: {
          SetPasswordScreen: 'set-password/:signature/:code',
        },
      },
    },
  },
};

setupSentry();

const App = () => {
  const [fontsLoaded] = useCustomFonts();

  useFontsWebLoader();

  return (
    <Sentry.ErrorBoundary>
      {fontsLoaded ? (
        <TamaguiProvider defaultTheme={'light'} config={appConfig}>
          <QueryProvider>
            <GestureHandlerRootView style={styles.gestureHandler}>
              <NavigationContainer linking={linking}>
                <SafeAreaProvider>
                  <TwilioWrapper>
                    <CheckJailbrokenProvider>
                      <NoConnectionProvider>
                        <RootStackNavigator />
                        <GlobalBottomSheet />
                        <Toast config={toastConfig} />
                      </NoConnectionProvider>
                    </CheckJailbrokenProvider>
                  </TwilioWrapper>
                </SafeAreaProvider>
              </NavigationContainer>
            </GestureHandlerRootView>
          </QueryProvider>
        </TamaguiProvider>
      ) : null}
      {Platform.OS !== 'web' && (
        <SplashViewComponent
          launchImageUrl={images.Splash}
          fadeOutDuration={800}
          animationTimeMs={2000}
          launchScreenHideMs={500}
        />
      )}
    </Sentry.ErrorBoundary>
  );
};

export default App;
