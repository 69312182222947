import type { PublicStackNavigatorParamList } from './types';
import type { NativeStackHeaderProps } from '@react-navigation/native-stack';

import { StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMedia, XStack, YStack } from 'tamagui';

import { Icon } from '@south-street-app/atoms';
import {
  LoginEmailScreen,
  LoginPasswordScreen,
  LoginPhoneCodeScreen,
  SetPasswordScreen,
  WelcomeScreen,
  ForgotPasswordEmailScreen,
} from '@south-street-app/screens';

const styles = StyleSheet.create({
  pressStyle: {
    opacity: 0.7,
  },
});

const { Navigator, Screen } =
  createNativeStackNavigator<PublicStackNavigatorParamList>();

const HeaderLeft = () => {
  const { goBack } = useNavigation();

  return (
    <YStack
      paddingVertical={'$3'}
      onPress={goBack}
      pressStyle={styles.pressStyle}
      marginLeft={-10}
      position={'absolute'}
      top={getSpaceTokenValue('$13')}
      left={getSpaceTokenValue('$6')}
    >
      <Icon color={'$mono700'} size={'$6'} iconName={'chevronLeftIcon'} />
    </YStack>
  );
};

const Header = (props: NativeStackHeaderProps & { desktop: boolean }) => {
  const safeAreaInsets = useSafeAreaInsets();

  const showHeaderLeft = props.route.name === 'LoginPasswordScreen';

  return (
    <XStack
      justifyContent={'center'}
      alignItems={'center'}
      paddingTop={safeAreaInsets.top}
      paddingBottom={'$1'}
      backgroundColor={'$mono200'}
    >
      {showHeaderLeft ? <HeaderLeft /> : null}
      <YStack alignItems={'center'} backgroundColor={'$mono200'}>
        <Icon
          color={'$mono700'}
          width={props.desktop ? '$49' : '$33'}
          height={props.desktop ? '$12' : '$8'}
          iconName={'logoBlueIcon'}
        />
      </YStack>
    </XStack>
  );
};

const renderHeader = (desktop: boolean) => (props: NativeStackHeaderProps) =>
  <Header {...props} desktop={desktop} />;

const PublicStackNavigator = () => {
  const { desktop } = useMedia();

  return (
    <Navigator>
      <Screen
        name={'WelcomeScreen'}
        component={WelcomeScreen}
        options={{ headerShown: false }}
      />
      <Screen
        name={'LoginEmailScreen'}
        component={LoginEmailScreen}
        options={{
          header: renderHeader(desktop),
        }}
      />
      <Screen
        name={'LoginPasswordScreen'}
        component={LoginPasswordScreen}
        options={{
          header: renderHeader(desktop),
        }}
      />
      <Screen
        name={'LoginPhoneCodeScreen'}
        component={LoginPhoneCodeScreen}
        options={{
          header: renderHeader(desktop),
        }}
      />
      <Screen
        name={'ForgotPasswordEmailScreen'}
        component={ForgotPasswordEmailScreen}
        options={{
          header: renderHeader(desktop),
        }}
      />
      <Screen
        name={'SetPasswordScreen'}
        component={SetPasswordScreen}
        options={{
          header: renderHeader(desktop),
        }}
      />
    </Navigator>
  );
};

export { PublicStackNavigator };
