import { createFont } from 'tamagui';

const size = {
  1: 12,
  10: 36,
  11: 48,
  12: 64,
  2: 14,
  3: 15,
  4: 16,
  5: 18,
  6: 20,
  7: 24,
  8: 28,
  9: 32,
  true: 16,
};

const lineHeight = {
  1: 16,
  10: 44,
  11: 56,
  12: 72,
  2: 18,
  3: 20,
  4: 22,
  5: 24,
  6: 28,
  7: 32,
  8: 38,
  9: 40,
  true: 20,
};

const weight = {
  1: 400,
  10: 700,
  11: 700,
  12: 700,
  2: 600,
  3: 700,
  4: 700,
  5: 700,
  6: 700,
  7: 700,
  8: 700,
  9: 700,
  true: 600,
};

const letterSpacing = {
  1: -2,
  10: 0,
  11: 0,
  12: 0,
  2: -1,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  true: 0,
};

const inter = createFont({
  family: 'Inter',
  size,
  lineHeight,
  weight,
  letterSpacing,
  face: {
    400: { normal: 'InterRegular' },
    600: { normal: 'InterSemibold' },
    700: { normal: 'InterBold' },
  },
});

export { inter, size, lineHeight, weight, letterSpacing };
