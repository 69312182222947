import React from 'react';

import { XStack } from 'tamagui';

import { LabelL } from '@south-street-app/atoms';
import { Checkbox, InitialsCircle } from '@south-street-app/molecules';

type SectionListItemProps = {
  item: string;
  selected: boolean;
  onPress: () => void;
};

const SectionListItem = ({ item, selected, onPress }: SectionListItemProps) => (
  <XStack
    paddingHorizontal={'$4'}
    justifyContent={'space-between'}
    onPress={onPress}
    alignItems={'center'}
    cursor={'pointer'}
  >
    <XStack alignItems={'center'} flex={1} gap={'$2'}>
      <InitialsCircle usePadding={false} name={item} />
      <LabelL fontWeight={'$6'}>{item}</LabelL>
    </XStack>
    <Checkbox
      backgroundColor={'$brand300'}
      size={'$12'}
      onPress={onPress}
      pointerEvents={'none'}
      onCheckedChange={onPress}
      checked={selected}
      id={item}
    />
  </XStack>
);

export { SectionListItem };
