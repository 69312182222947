type CouponType = 'regular' | 'roll';

type ProductListItemData = {
  type: CouponType;
  coupon: string;
  month: string;
  quote: string;
};

export type { ProductListItemData, CouponType };
