import type { TradingStackNavigationProps } from '@south-street-app/navigation/types';
import type { ListAssociatedResponseDto } from '@utility-nyc/react-query-sdk';

import React, { useCallback, useMemo } from 'react';

import { SectionList, StyleSheet } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';
import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMedia, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { sectionUsers } from '@shared/utils';
import { BodyXL, HeadingL, Icon, LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { ButtonLarge } from '@south-street-app/molecules';
import { useMobileTradeStore } from '@south-street-app/stores';
import { useGetAnalyticFirmAssociates } from '@utility-nyc/react-query-sdk';

import { SectionListItem } from './SectionListItem';

type UsersFormValues = {
  users: Array<
    ListAssociatedResponseDto & {
      selected: boolean;
    }
  >;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: getColorTokenValue('$mono100'),
  },
  safeArea: {
    flex: 1,
    backgroundColor: getColorTokenValue('$mono100'),
  },
  pressStyle: {
    opacity: 0.7,
  },
});

type FirmSelectClientsScreenProps =
  TradingStackNavigationProps<'FirmSelectClientsScreen'>;

const Separator = () => (
  <YStack width={'$100%'} height={'$px'} backgroundColor={'$mono400'} />
);
const ItemSeparator = () => <YStack width={'$100%'} height={'$4'} />;
const SectionSeparator = () => (
  <YStack paddingVertical={'$4'} backgroundColor={'$mono100'}>
    <Separator />
  </YStack>
);

const FirmSelectClientsScreen = ({
  navigation,
}: FirmSelectClientsScreenProps) => {
  const { desktop } = useMedia();
  const { data: associates } = useGetAnalyticFirmAssociates();
  const { setFirmEndUsers, firmEndUsers, clearFirmEndUsers } =
    useMobileTradeStore(
      useShallow((state) => ({
        setFirmEndUsers: state.setFirmEndUsers,
        firmEndUsers: state.firmEndUsers,
        clearFirmEndUsers: state.clearFirmEndUsers,
      })),
    );

  const { control, handleSubmit, setValue, getValues, reset, watch } =
    useForm<UsersFormValues>({
      defaultValues: {
        users: associates?.data.associates.map((user) => ({
          name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
          id: user.associatedId,
          selected: false,
        })),
      },
    });
  const watchedUsers = watch('users');
  const { fields } = useFieldArray({
    control,
    name: 'users',
    keyName: 'fieldId',
  });

  useFocusEffect(
    useCallback(() => {
      reset({
        users: associates?.data.associates.map((user) => ({
          ...user,
          name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
          selected: firmEndUsers.some(
            (endUser) => endUser.id === user.associatedId,
          ),
        })),
      });
    }, [firmEndUsers, reset, associates]),
  );

  const onSubmit = (data: UsersFormValues) => {
    const selectedUsers = data.users
      .filter(({ selected }) => selected)
      .map((user) => ({
        name: user.firstName + ' ' + user.lastName,
        id: user.associatedId,
      }));

    setFirmEndUsers(selectedUsers);

    navigation.navigate('TradeOrder');
  };

  const toggleUserSelection = useCallback(
    (index: number) => {
      const currentValues = getValues(`users.${index}.selected`);

      setValue(`users.${index}.selected`, !currentValues);
    },
    [getValues, setValue],
  );

  const findFieldIndexById = useCallback(
    (id: string) => fields.findIndex((user) => user.associatedId === id),
    [fields],
  );

  const onClose = () => {
    clearFirmEndUsers();

    navigation.goBack();
  };

  const renderItem = useCallback(
    ({ item }: { item: ListAssociatedResponseDto }) => {
      const fieldIndex = findFieldIndexById(item.associatedId);

      return (
        <Controller
          control={control}
          name={`users.${fieldIndex}.selected`}
          render={({ field }) => (
            <SectionListItem
              item={item.firstName + ' ' + item.lastName}
              selected={field.value}
              onPress={() => toggleUserSelection(fieldIndex)}
            />
          )}
        />
      );
    },
    [control, findFieldIndexById, toggleUserSelection],
  );
  const sectionedUsers = useMemo(() => sectionUsers(fields), [fields]);

  return (
    <SafeAreaView style={styles.safeArea}>
      <YStack
        flex={1}
        {...(desktop && {
          justifyContent: 'flex-start',
          width: 800,
          gap: '$16',
          marginHorizontal: '$auto',
        })}
      >
        <YStack flex={1} paddingVertical={'$4'} backgroundColor={'$mono100'}>
          <YStack paddingHorizontal={'$4'}>
            <XStack justifyContent={'space-between'} alignItems={'center'}>
              <HeadingL>{en_US.selectClients}</HeadingL>
              <YStack
                paddingVertical={'$3'}
                paddingLeft={'$2'}
                onPress={onClose}
                pressStyle={styles.pressStyle}
              >
                <Icon color={'$mono700'} size={'$6'} iconName={'closeIcon'} />
              </YStack>
            </XStack>
            <BodyXL color={'$mono400'}>{en_US.selectClientsSubtitle}</BodyXL>
          </YStack>
          <YStack paddingVertical={'$4'} flex={1}>
            <SectionList
              sections={sectionedUsers}
              keyExtractor={(item, index) => `${item.associatedId}-${index}`}
              renderItem={renderItem}
              ItemSeparatorComponent={ItemSeparator}
              SectionSeparatorComponent={ItemSeparator}
              renderSectionHeader={({ section: { title } }) => (
                <>
                  <SectionSeparator />
                  <LabelL
                    backgroundColor={'$mono100'}
                    paddingHorizontal={'$4'}
                    fontWeight={'$6'}
                  >
                    {title}
                  </LabelL>
                </>
              )}
            />
          </YStack>
        </YStack>
        <YStack paddingHorizontal={'$4'}>
          <ButtonLarge
            disabled={watchedUsers.every(({ selected }) => !selected)}
            onPress={handleSubmit(onSubmit)}
          >
            {en_US.continue}
          </ButtonLarge>
        </YStack>
      </YStack>
    </SafeAreaView>
  );
};

export { FirmSelectClientsScreen };
