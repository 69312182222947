import type { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import type { IconName } from '@shared/assets';
import type { GetCurrentEndUser200 } from '@utility-nyc/react-query-sdk';

import { useEffect, useRef, useState } from 'react';

import { Platform, StyleSheet } from 'react-native';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { YStack, useMedia } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { Toast } from '@shared/utils';
import { BodyS, Icon } from '@south-street-app/atoms';
import { InitialsCircle } from '@south-street-app/molecules';
import {
  TradingScreen,
  PoolColorScreen,
  HistoryScreen,
} from '@south-street-app/screens';
import { useDevStore } from '@south-street-app/stores';
import { isAndroid, isWeb } from '@south-street-app/utils';
import { useGetCurrentEndUser } from '@utility-nyc/react-query-sdk';

type BottomTabNavigatorParamList = {
  TradingScreen: undefined;
  PoolColorScreen: undefined;
  HistoryScreen: undefined;
};

type BottomTabNavigatorRouteName = keyof BottomTabNavigatorParamList;

type BottomTabReactNavigationProps<S extends BottomTabNavigatorRouteName> =
  BottomTabScreenProps<BottomTabNavigatorParamList, S>;

const { Navigator, Screen } =
  createBottomTabNavigator<BottomTabNavigatorParamList>();

type TabOptionsProps = {
  focused: boolean;
};

type TimerReturnType = ReturnType<typeof setTimeout> | undefined;

const styles = StyleSheet.create({
  tabBarStyle: {
    backgroundColor: 'white',
    borderTopWidth: 0.5,
    borderColor: getColorTokenValue('$mono200'),
    height: isAndroid || isWeb ? 70 : 86,
    paddingTop: isAndroid ? undefined : 8,
    paddingBottom: isAndroid || isWeb ? 8 : 30,
  },
});

const getOptions = (
  iconName: IconName,
  tabBarText: string,
  onPress?: () => void,
  userData?: GetCurrentEndUser200,
  desktop?: boolean,
) => ({
  tabBarIcon: ({ focused }: TabOptionsProps) => (
    <Icon
      color={
        Platform.OS === 'web'
          ? focused
            ? '$mono100'
            : '$mono500'
          : focused
          ? '$mono700'
          : '$mono300'
      }
      size={'$5'}
      iconName={iconName}
    />
  ),
  tabBarShowLabel: true,
  headerStyle: {
    shadowColor: 'transparent',
    elevation: 0,
  },
  headerTitle:
    tabBarText === 'Pool Color'
      ? 'Pool Color'
      : tabBarText === 'History'
      ? 'Transaction History'
      : '',
  headerLeft: () =>
    tabBarText === 'Trading' ? (
      <YStack paddingLeft={'$4'} paddingBottom={desktop ? '$0' : '$4'}>
        <Icon
          color={'$mono700'}
          width={'$33'}
          height={'$8'}
          iconName={'logoBlueIcon'}
        />
      </YStack>
    ) : null,
  headerRight: () =>
    tabBarText === 'Trading' ? (
      <InitialsCircle onPress={onPress} name={userData?.data.name} />
    ) : null,
  tabBarLabel: ({ focused }: TabOptionsProps) => (
    <BodyS
      fontSize={'$2'}
      fontWeight={focused ? '$3' : '$1'}
      color={
        Platform.OS === 'web'
          ? focused
            ? '$mono100'
            : '$mono500'
          : focused
          ? '$brand500'
          : '$mono400'
      }
    >
      {tabBarText}
    </BodyS>
  ),
  animation: 'fade',
  animationDuration: 500,
});

const HomeTabNavigator = () => {
  const navigation = useNavigation();
  const { desktop } = useMedia();
  const { setDevData, state: devState } = useDevStore(
    useShallow((state) => ({
      setDevData: state.setDevData,
      state: state.state,
    })),
  );

  const [isHistoryTabPressed, setIsHistoryTabPressed] = useState(false);
  const [historyTabPressCounter, setHistoryTabPressCounter] = useState(0);

  const timeoutID = useRef<TimerReturnType>();

  const { data: userData } = useGetCurrentEndUser();

  const isProd =
    // eslint-disable-next-line dot-notation
    process.env['APP_IDENTIFIER_IOS'] === 'com.southstreetsecurities.app';

  useEffect(() => {
    if (isHistoryTabPressed) {
      timeoutID.current = setTimeout(() => {
        setHistoryTabPressCounter(0);

        setIsHistoryTabPressed(false);

        clearTimeout(timeoutID.current);
      }, 200);
    }
  }, [isHistoryTabPressed]);

  useEffect(() => {
    if (historyTabPressCounter === 2 && !devState.debugMode && !isProd) {
      Toast.showSuccessToast({
        text1: 'Debug mode is on',
      });

      setDevData({
        debugMode: true,
      });

      setHistoryTabPressCounter(0);
    } else if (historyTabPressCounter === 2 && devState.debugMode && !isProd) {
      Toast.showDebugToast({
        text1: 'Debug mode is off',
      });

      setDevData({
        debugMode: false,
      });

      setHistoryTabPressCounter(0);
    }
  }, [devState.debugMode, historyTabPressCounter, isProd, setDevData]);

  const handleUserProfileTabPress = () => {
    setIsHistoryTabPressed(true);

    setHistoryTabPressCounter((prev) => prev + 1);
  };

  return (
    <Navigator
      screenOptions={{
        headerShown: true,
        tabBarStyle: [
          styles.tabBarStyle,
          {
            backgroundColor:
              Platform.OS === 'web' ? getColorTokenValue('$brand500') : 'white',
          },
        ],
        tabBarLabelPosition: 'below-icon',
      }}
    >
      <Screen
        name={'TradingScreen'}
        component={TradingScreen}
        options={getOptions(
          'tradingIcon',
          'Trading',
          () => navigation.navigate('AccountScreen'),
          userData,
          desktop,
        )}
      />
      <Screen
        name={'PoolColorScreen'}
        component={PoolColorScreen}
        options={{
          ...getOptions('poolColorIcon', 'Pool Color'),
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
        }}
      />
      <Screen
        name={'HistoryScreen'}
        component={HistoryScreen}
        options={{
          ...getOptions('historyIcon', 'History'),
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
        }}
        listeners={{
          tabPress: handleUserProfileTabPress,
        }}
      />
    </Navigator>
  );
};

export { HomeTabNavigator };
export type { BottomTabNavigatorParamList, BottomTabReactNavigationProps };
