import { XStack, YStack } from 'tamagui';

import { BodyM } from '@south-street-app/atoms';

type CheckFieldValidationProps = {
  isValid: boolean;
  text: string;
};

const CheckFieldValidation = ({ isValid, text }: CheckFieldValidationProps) => (
  <XStack alignItems={'center'} space={'$3'} paddingTop={'$2'}>
    <YStack
      width={'$1.5'}
      height={'$1.5'}
      backgroundColor={isValid ? '$accentGreen500' : '$mono400'}
      borderRadius={'$xl'}
    />
    <BodyM color={isValid ? '$mono900' : '$mono400'}>{text}</BodyM>
  </XStack>
);

export { CheckFieldValidation };
