/* eslint-disable @typescript-eslint/no-var-requires */
import type { SvgProps } from 'react-native-svg';

import type { FC } from 'react';

const untypedIcons = {
  tradingIcon: require('./files/trading-icon.svg').default,
  poolColorIcon: require('./files/pool-color-icon.svg').default,
  historyIcon: require('./files/history-icon.svg').default,
  addIcon: require('./files/add-icon.svg').default,
  bookmarkIcon: require('./files/bookmark-icon.svg').default,
  chatIcon: require('./files/chat-icon.svg').default,
  checkIcon: require('./files/check-icon.svg').default,
  chevronDownIcon: require('./files/chevron-down-icon.svg').default,
  chevronLeftIcon: require('./files/chevron-left-icon.svg').default,
  chevronRightIcon: require('./files/chevron-right-icon.svg').default,
  chevronUpIcon: require('./files/chevron-up-icon.svg').default,
  closeIcon: require('./files/close-icon.svg').default,
  deleteIcon: require('./files/delete-icon.svg').default,
  externalLinkIcon: require('./files/external-link-icon.svg').default,
  heartIcon: require('./files/heart-icon.svg').default,
  infoIcon: require('./files/info-icon.svg').default,
  minusIcon: require('./files/minus-icon.svg').default,
  moreIcon: require('./files/more-icon.svg').default,
  profileIcon: require('./files/profile-icon.svg').default,
  searchIcon: require('./files/search-icon.svg').default,
  sendIcon: require('./files/send-icon.svg').default,
  settingsIcon: require('./files/settings-icon.svg').default,
  warningIcon: require('./files/warning-icon.svg').default,
  logoBlueIcon: require('./files/logo-blue-icon.svg').default,
  timerIcon: require('./files/timer-icon.svg').default,
  currencyIcon: require('./files/currency-icon.svg').default,
  swapIcon: require('./files/swap-icon.svg').default,
  noConnectionIcon: require('./files/no-connection-icon.svg').default,
};

type IconName = keyof typeof untypedIcons;

const icons: Record<IconName, FC<SvgProps>> = untypedIcons;

export { icons };
export type { IconName };
