import type { ColorTokens } from 'tamagui';

type ColorType = 'blue' | 'whiteBlack';

const getColorByIndexWithCycle = (
  index: number,
  cycle = 5,
  type: ColorType = 'blue',
) => {
  const blueColors = ['$brand100', '$brand200', '$brand300'];
  const whiteBlackColors = ['$mono900', '$mono900', '$mono100'];

  const colors = type === 'blue' ? blueColors : whiteBlackColors;

  const colorIndex = Math.floor(index / cycle) % colors.length;

  const selectedColor = colors[colorIndex] as ColorTokens;

  return selectedColor;
};

export { getColorByIndexWithCycle };
