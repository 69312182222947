import { Dimensions } from 'react-native';

import { useHeaderHeight } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { YStack } from 'tamagui';

import { en_US } from '@south-street-app/configs';
import { Spinner } from '@south-street-app/molecules';

const windowHeight = Dimensions.get('window').height;

const HistoryListLoadingComponent = () => {
  const { bottom, top } = useSafeAreaInsets();

  const headerHeight = useHeaderHeight();

  const height = windowHeight - bottom - top - headerHeight;

  return (
    <YStack alignItems={'center'} justifyContent={'center'} height={height}>
      <Spinner label={en_US.loadingPastTransactions} labelMaxWidth={'$44'} />
    </YStack>
  );
};

export { HistoryListLoadingComponent };
