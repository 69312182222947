import type { BubbleProps, IMessage } from 'react-native-gifted-chat';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { Bubble } from 'react-native-gifted-chat';

import { getSizeTokenValue } from '@south-street-app/theme';

const ChatBubble = (props: BubbleProps<IMessage>) => {
  const textStyle = {
    left: {
      color: getColorTokenValue('$mono100'),
      fontFamily: 'Inter-Regular',
    },
    right: {
      color: getColorTokenValue('$mono900'),
      fontFamily: 'Inter-Regular',
    },
  };

  const wrapperStyle = {
    left: {
      backgroundColor: getColorTokenValue('$brand500'),
      padding: getSizeTokenValue('$4'),
    },
    right: {
      backgroundColor: getColorTokenValue('$mono200'),
      padding: getSizeTokenValue('$4'),
    },
  };

  const dontRender = () => null;

  return (
    <Bubble
      {...props}
      textStyle={textStyle}
      wrapperStyle={wrapperStyle}
      renderTime={dontRender}
    />
  );
};

export { ChatBubble };
