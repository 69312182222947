import AsyncStorage from '@react-native-async-storage/async-storage';
import { createJSONStorage, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type BackendErrorStateVariables = {
  error: string;
};

type BackendErrorStateFunctions = {
  setBackendErrorData: (data: BackendErrorStateVariables) => void;
  resetBackendErrorData: () => void;
};

type BackendErrorState = BackendErrorStateVariables &
  BackendErrorStateFunctions;

const STORE_KEY = 'BackendError';

const useBackendError = createWithEqualityFn<BackendErrorState>()(
  persist(
    (set) => ({
      error: '',

      setBackendErrorData: (state) => set({ error: state.error }),
      resetBackendErrorData: () =>
        set({
          error: '',
        }),
    }),
    {
      name: STORE_KEY,
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
  shallow,
);

export { useBackendError };
