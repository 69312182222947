import { useEffect } from 'react';

import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import { YStack } from 'tamagui';

const AnimatedYStack = Animated.createAnimatedComponent(YStack);

type DotProps = {
  y?: number;
  x?: number;
  delay?: number;
};

const Dot = ({ y, x, delay = 0 }: DotProps) => {
  const animatedValue = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: animatedValue.value,
  }));

  useEffect(() => {
    animatedValue.value = withDelay(
      delay,
      withRepeat(withTiming(1, { duration: 1000 }), -1, true),
    );
  }, [animatedValue, delay]);

  return (
    <YStack position={'absolute'} x={x} y={y}>
      <AnimatedYStack
        backgroundColor={'$brand500'}
        width={'$2'}
        height={'$2'}
        borderRadius={'$xl'}
        style={animatedStyle}
      />
    </YStack>
  );
};

export { Dot };
