import { useCallback } from 'react';

import { Pressable } from 'react-native';

import { useAuthStore } from '@utility-nyc/react-auth-storage';
import { useMedia, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { Icon, HeadingM, LabelL } from '@south-street-app/atoms';
import { ButtonLarge } from '@south-street-app/molecules';
import { useGlobalBottomSheetStore } from '@south-street-app/stores';

const LogoutBottomSheetContent = () => {
  const { desktop } = useMedia();
  const { closeBottomSheet } = useGlobalBottomSheetStore(
    useShallow((state) => ({ closeBottomSheet: state.closeBottomSheet })),
  );

  const logout = useAuthStore((state) => state.handleLogout);

  const handleOnPressLogout = useCallback(() => {
    closeBottomSheet();

    logout();
  }, [closeBottomSheet, logout]);

  return (
    <YStack justifyContent={'center'} padding={'$4'} flex={1}>
      <XStack
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        paddingBottom={'$1'}
      >
        <HeadingM maxWidth={desktop ? undefined : '$80'} fontWeight={'$3'}>
          {'Are you sure you want to Log out?'}
        </HeadingM>
        <Pressable onPress={closeBottomSheet}>
          <Icon iconName={'closeIcon'} color={'$mono700'} size={'$6'} />
        </Pressable>
      </XStack>
      <XStack
        justifyContent={'space-between'}
        space={'$3'}
        paddingVertical={'$9'}
      >
        <ButtonLarge onPress={closeBottomSheet} variant={'secondary'} flex={1}>
          <LabelL fontWeight={'$3'} color={'$brand500'}>
            {'Cancel'}
          </LabelL>
        </ButtonLarge>
        <ButtonLarge onPress={handleOnPressLogout} variant={'primary'} flex={1}>
          <LabelL fontWeight={'$3'} color={'$mono100'}>
            {'Confirm'}
          </LabelL>
        </ButtonLarge>
      </XStack>
    </YStack>
  );
};

export { LogoutBottomSheetContent };
