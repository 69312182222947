import type { FirmEndUsers } from '@south-street-app/stores';
import type { ColorTokens } from 'tamagui';

import { StyleSheet, TouchableOpacity } from 'react-native';

import {
  getColorTokenValue,
  getSpaceTokenValue,
} from '@utility-nyc/react-native-ui-config';
import { YStack, XStack } from 'tamagui';

import { Icon, LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { InitialsCircle, MoneyInput } from '@south-street-app/molecules';

const styles = StyleSheet.create({
  choosePressStyle: {
    backgroundColor: getColorTokenValue('$brand600') as ColorTokens,
  },
});

type FirmTradeItemProps = {
  product: string;
  onChangeValue: (value: string) => void;
  onPressChooseProduct?: () => void;
  onPressChangeProduct?: () => void;
  disabled?: boolean;
  endUser: FirmEndUsers;
  onDeleteEndUser: (id: string) => void;
};

const FirmTradeItem = ({
  product,
  onChangeValue,
  onPressChooseProduct,
  disabled = false,
  endUser,
  onDeleteEndUser,
}: FirmTradeItemProps) => {
  const deleteEndUserById = () => {
    onDeleteEndUser(endUser.id);
  };

  return (
    <YStack borderWidth={'$px'} borderColor={'$mono200'} borderRadius={'$l'}>
      <XStack
        padding={'$2'}
        alignItems={'center'}
        borderBottomWidth={'$px'}
        justifyContent={'space-between'}
        borderBottomColor={'$mono200'}
      >
        <XStack gap={'$2'} alignItems={'center'}>
          <InitialsCircle usePadding={false} name={endUser.name} />
          <LabelL fontWeight={'$4'} color={'$black'}>
            {endUser.name}
          </LabelL>
        </XStack>
        <TouchableOpacity onPress={deleteEndUserById} activeOpacity={0.6}>
          <Icon iconName={'deleteIcon'} color={'$mono400'} size={'$5'} />
        </TouchableOpacity>
      </XStack>
      <YStack gap={'$3'} padding={'$2'}>
        {product !== '-' ? (
          <MoneyInput onChangeText={onChangeValue} />
        ) : (
          <XStack
            backgroundColor={disabled ? '$mono200' : '$brand500'}
            borderRadius={getSpaceTokenValue('$3')}
            padding={'$5'}
            justifyContent={'space-between'}
            onPress={disabled ? undefined : onPressChooseProduct}
            pressStyle={disabled ? undefined : styles.choosePressStyle}
          >
            <LabelL color={'$mono100'} fontWeight={'$2'}>
              {en_US.chooseProduct}
            </LabelL>
            <Icon size={'$6'} iconName={'chevronRightIcon'} />
          </XStack>
        )}
      </YStack>
    </YStack>
  );
};

export { FirmTradeItem };
