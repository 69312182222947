import type { PrivateStackNavigatorParamList } from './types';

import { useCallback } from 'react';

import { Platform, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AvoidSoftInput } from 'react-native-avoid-softinput';
import { useMedia, YStack } from 'tamagui';

import { Icon } from '@south-street-app/atoms';
import {
  AccountLinkScreen,
  AccountScreen,
  ChatScreen,
} from '@south-street-app/screens';
import { isAndroid } from '@south-street-app/utils';

import { HomeTabNavigator } from '../HomeTabNavigator';
import { TradingStackNavigator } from '../TradingStackNavigator';

const { Navigator, Screen } =
  createNativeStackNavigator<PrivateStackNavigatorParamList>();

const styles = StyleSheet.create({
  pressStyle: {
    opacity: 0.7,
  },
});

const HeaderLeft = () => {
  const { goBack } = useNavigation();
  const { desktop } = useMedia();
  const handleGoBack = useCallback(() => {
    // Workaround to avoid bottom sheet appearing on Android when using an input
    if (isAndroid) {
      AvoidSoftInput.setAdjustPan();
    }

    goBack();
  }, [goBack]);

  return (
    <YStack
      paddingVertical={'$3'}
      onPress={handleGoBack}
      {...(Platform.OS === 'web' ? { cursor: 'pointer' } : undefined)}
      pressStyle={styles.pressStyle}
      marginLeft={desktop ? 0 : -10}
      paddingLeft={desktop ? 16 : 0}
    >
      <Icon color={'$mono700'} size={'$6'} iconName={'chevronLeftIcon'} />
    </YStack>
  );
};

const PrivateStackNavigator = () => {
  const { desktop } = useMedia();

  return (
    <Navigator screenOptions={{ headerShown: false }}>
      <Screen name={'HomeTab'} component={HomeTabNavigator} />
      <Screen name={'TradeScreens'} component={TradingStackNavigator} />
      <Screen
        name={'AccountScreen'}
        component={AccountScreen}
        options={{
          headerShown: true,
          headerTitle: 'Account',
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
          headerLeft: HeaderLeft,
          headerShadowVisible: false,
        }}
      />
      <Screen
        name={'AccountLinkScreen'}
        component={AccountLinkScreen}
        options={{
          headerShown: true,
          headerTitle: '',
          headerLeft: HeaderLeft,
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
          headerShadowVisible: false,
        }}
      />
      <Screen
        name={'ChatScreen'}
        component={ChatScreen}
        options={{
          headerShown: desktop ? false : true,
          headerTitle: '',
          headerLeft: HeaderLeft,
          headerShadowVisible: false,
        }}
      />
    </Navigator>
  );
};

export { PrivateStackNavigator };
