import React, { useEffect } from 'react';

import { useWindowDimensions } from 'react-native';

import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import { YStack } from 'tamagui';

const AnimatedYStack = Animated.createAnimatedComponent(YStack);

type LoadingLineProps = {
  height?: number;
};

const LoadingLine = ({ height = 3 }: LoadingLineProps) => {
  const { width } = useWindowDimensions();
  const animatedWidth = useSharedValue(0);
  const animatedPosition = useSharedValue(0);

  useEffect(() => {
    animatedWidth.value = withRepeat(
      withTiming(width, { duration: 2000 }),
      -1,
      false,
    );

    animatedPosition.value = withRepeat(
      withTiming(width, { duration: 2000 }),
      -1,
      false,
    );
  }, [animatedPosition, animatedWidth, width]);

  const animatedStyle = useAnimatedStyle(() => ({
    width: animatedWidth.value,
    transform: [{ translateX: animatedPosition.value }],
  }));

  return (
    <YStack width={width} height={height} backgroundColor={'$mono200'}>
      <AnimatedYStack
        height={height}
        backgroundColor={'$brand300'}
        style={animatedStyle}
      />
    </YStack>
  );
};

export { LoadingLine };
