import type { TradingNavigatorParamList } from './types';

import { Platform, StyleSheet } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useMedia, YStack } from 'tamagui';

import { Icon } from '@south-street-app/atoms';
import {
  TradeOrderScreen,
  PreviewTradeOrderScreen,
  TradeConfirmationScreen,
  TradeTimeoutScreen,
  FirmSelectClientsScreen,
} from '@south-street-app/screens';
import { useGetAnalyticFirmAssociates } from '@utility-nyc/react-query-sdk';

import { TradeOrderHeader } from './TradeOrderHeader';

const { Navigator, Screen } = createStackNavigator<TradingNavigatorParamList>();

const styles = StyleSheet.create({
  pressStyle: {
    opacity: 0.7,
  },
});

const HeaderLeft = ({ isBackButton = false }: { isBackButton?: boolean }) => {
  const { goBack } = useNavigation();

  return (
    <YStack
      paddingVertical={'$3'}
      paddingLeft={'$2'}
      onPress={goBack}
      {...(Platform.OS === 'web' ? { cursor: 'pointer' } : undefined)}
      pressStyle={styles.pressStyle}
    >
      <Icon
        color={'$mono700'}
        size={'$6'}
        iconName={isBackButton ? 'chevronLeftIcon' : 'closeIcon'}
      />
    </YStack>
  );
};

const HeaderLeftClose = () => HeaderLeft({ isBackButton: false });
const HeaderLeftBack = () => HeaderLeft({ isBackButton: true });
const nullHeader = () => null;

const TradingStackNavigator = () => {
  const { data } = useGetAnalyticFirmAssociates();

  const isFirmTrade = (data?.data.associates ?? []).length >= 1;

  const { desktop } = useMedia();

  return (
    <Navigator
      initialRouteName={isFirmTrade ? 'FirmSelectClientsScreen' : 'TradeOrder'}
      screenOptions={{
        ...(desktop && {
          cardStyle: { flex: 1 },
        }),
        headerShown: true,
      }}
    >
      <Screen
        name={'TradeOrder'}
        options={{
          headerTitle: TradeOrderHeader,
          headerLeft: HeaderLeftClose,
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
          headerStyle: {
            shadowColor: 'transparent',
            elevation: 0,
          },
        }}
        component={TradeOrderScreen}
      />
      <Screen
        name={'FirmSelectClientsScreen'}
        options={{
          headerShown: false,
        }}
        component={FirmSelectClientsScreen}
      />
      <Screen
        name={'PreviewTradeOrder'}
        options={{
          headerTitle: 'Trade Order',
          headerLeft: HeaderLeftBack,
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
          headerStyle: {
            shadowColor: 'transparent',
            elevation: 0,
          },
        }}
        component={PreviewTradeOrderScreen}
      />
      <Screen
        name={'TradeConfirmation'}
        options={{
          headerTitle: 'Trade Information',
          ...(desktop ? { headerTitleAlign: 'center' } : undefined),
          headerStyle: {
            shadowColor: 'transparent',
            elevation: 0,
          },
          headerLeft: nullHeader,
        }}
        component={TradeConfirmationScreen}
      />
      <Screen
        name={'TradeTimeout'}
        options={{ headerShown: false }}
        component={TradeTimeoutScreen}
      />
    </Navigator>
  );
};

export { TradingStackNavigator };
