import { useCallback } from 'react';

import { useMedia, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { capitalizeFirstLetter } from '@shared/utils';
import { HeadingM } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { SlideButton } from '@south-street-app/molecules';
import {
  useGlobalBottomSheetStore,
  useMobileTradeStore,
} from '@south-street-app/stores';
import {
  useGetCurrentEndUser,
  type TradeComponentInputDto,
} from '@utility-nyc/react-query-sdk';

import { DisplayTradeInfo } from './DisplayTradeInfo';

const MultiplePreviewTradeOrder = () => {
  const { desktop } = useMedia();
  const openBottomSheet = useGlobalBottomSheetStore(
    (globalState) => globalState.openBottomSheet,
  );
  const { data } = useGetCurrentEndUser();

  const {
    createTradeRequest,
    setReceivedQuotedPrice,
    proposal,
    tradeType,
    isTwilioConnected,
  } = useMobileTradeStore(
    useShallow((state) => ({
      proposal: state.proposal,
      isTwilioConnected: state.isTwilioConnected,
      createTradeRequest: state.createTradeRequest,
      setReceivedQuotedPrice: state.setReceivedQuotedPrice,
      tradeType: state.tradeType,
    })),
  );

  const handleOnCompleted = useCallback(() => {
    if (data?.data.id) {
      const components: TradeComponentInputDto[] = proposal.components.map(
        (component) => ({
          productId: component.product.id,
          amount: component.amount,
          type: component.type,
        }),
      );

      createTradeRequest({
        components,
        type: tradeType,
        endUserId: data.data.id,
        shares: components.map((component) => ({
          amount: component.amount,
          type: component.type,
          associatedId: data.data.id,
        })),
      });

      setReceivedQuotedPrice(false);

      openBottomSheet({
        type: 'requestTrade',
      });
    }
  }, [
    createTradeRequest,
    proposal.components,
    data,
    openBottomSheet,
    setReceivedQuotedPrice,
    tradeType,
  ]);

  return (
    <YStack
      flex={1}
      justifyContent={'space-between'}
      {...(desktop && {
        justifyContent: 'flex-start',
        gap: '$16',
      })}
    >
      <YStack space={'$7'}>
        <HeadingM paddingTop={'$1'} fontWeight={'$3'}>
          {capitalizeFirstLetter(tradeType)}
        </HeadingM>
        <DisplayTradeInfo order={proposal.components[0]} isAlternativeLabel />
        <DisplayTradeInfo order={proposal.components[1]} isAlternativeLabel />
      </YStack>
      <SlideButton
        isLoading={!isTwilioConnected}
        label={
          isTwilioConnected
            ? en_US.submitTradeRequest
            : en_US.connectingToTradeNetwork
        }
        onCompleted={handleOnCompleted}
      />
    </YStack>
  );
};

export { MultiplePreviewTradeOrder };
