import { useRef, useEffect } from 'react';

import { AppState } from 'react-native';

import { useAuthStore } from '@utility-nyc/react-auth-storage';
import 'core-js/stable/atob';
import { jwtDecode } from 'jwt-decode';

import { useEndUserAccessToken } from '@utility-nyc/react-query-sdk';

const useRefreshTwilioAccessToken = () => {
  const appState = useRef(AppState.currentState);

  const setTokens = useAuthStore((state) => state.setTokens);
  const accessToken = useAuthStore((state) => state.accessToken);
  const refreshToken = useAuthStore((state) => state.refreshToken);
  const { data: tokens, refetch: refetchTokens } = useEndUserAccessToken({
    query: { enabled: !!refreshToken },
  });

  useEffect(() => {
    if (tokens?.data.twilioAccessToken) {
      setTokens({
        twilioAccessToken: tokens.data.twilioAccessToken,
        accessToken, //We are not updating the accessToken, but it is required by the setTokens
      });
    }
  }, [accessToken, setTokens, tokens]);

  useEffect(() => {
    const subscription = AppState.addEventListener(
      'change',
      async (nextAppState) => {
        if (
          appState.current.match(/inactive|background/) &&
          nextAppState === 'active'
        ) {
          if (tokens?.data.twilioAccessToken) {
            const decoded = jwtDecode(tokens.data.twilioAccessToken);

            const { exp } = decoded;

            if (exp && Date.now() >= exp * 1000) {
              await refetchTokens();
            }
          }
        }

        appState.current = nextAppState;
      },
    );

    return () => {
      subscription.remove();
    };
  }, [refetchTokens, tokens]);
};

export { useRefreshTwilioAccessToken };
