import { Platform, Pressable, StyleSheet, View } from 'react-native';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import Markdown from 'react-native-markdown-display';
import { useWindowDimensions, XStack, YStack, useMedia } from 'tamagui';

import { Icon } from '@south-street-app/atoms';
import { ScreenWrapperScroll } from '@south-street-app/molecules';
import { useGlobalBottomSheetStore } from '@south-street-app/stores';

const content = `**South Street Securities LLC**

**Terms and Conditions**

These Terms and Conditions (“**Terms**”), and the policies and other documents referenced in these Terms, together form an agreement (“**Agreement**”) between you (“**you**” and “**your**”) and South Street Securities LLC (“**South Street**,” “**us**,” or “**we**”).

If you are entering into this Agreement on behalf of an entity or organization, you represent and warrant to South Street that you are authorized to bind that entity or organization to this Agreement, and all references in this Agreement to “you” will include that entity or organization.

South Street provides registered users (“**Users**”) the opportunity to access and use information, data, and content (“**Content**”) as well as additional services, resources, and offerings (“**Offerings**”) through this mobile application and its other applications and websites (“**Platform**”). South Street is willing to allow you to become a User of the Platform and to permit you to access and use the Content and Offerings if you agree to be bound by this Agreement.

This Agreement will form a binding legal agreement between you and South Street as of the date you first accept this Agreement, including electronically, or otherwise access or use the Platform (“**Effective Date**”). You and South Street are each a party to this Agreement and together are the parties to this Agreement.

Please read this Agreement carefully. This Agreement forms a legally binding agreement between you and SOUTH STREET. BY ACCESSING AND USING THe Platform (including any Content or Offerings), YOU AGREE THAT YOU HAVE READ, UNDERSTAND, AND Agree TO COMPLY WITH AND BE BOUND BY this agreement.

By entering into this Agreement, YOU MAY BE WAIVING CERTAIN RIGHTS. In particular, this agreement contains PROVISIONS PROVIDING FOR MANDATORY BINDING arbitration AND WAIVER OF JURY TRIALS (IN THE SECTION BELOW titled “dispute REsOLUTION”), which limit your rights to bring an action in court and have disputes decided by a judge or jury, and other provisions that limit our liability to you.

ALL CLAIMS AND DISPUTES ARISING UNDER THESE TERMS MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER USER.

**sections**

- Scope
- [Definitions](#_Toc145080728)
- [Eligibility](#_Toc145080729)
- [Term](#_Toc145080730)
- [Modifications](#_Toc145080730)
- [Additional Terms](#_Toc145080731)
- [Account](#_Toc145080732)
- [Your Obligation to Provide Truthful Information](#_Toc145080733)
- [Access](#_Toc145080734)
- [Content](#_Toc145080736)
- [Content](#_Toc145080730) Restrictions
- [Ownership](#_Toc145080737)
- [Feedback](#_Toc145080738)
- [Additional Restrictions](#_Toc145080739)
- [Audit](#_Toc145080730) Rights
- [Fees](#_Toc145080740)
- [Right to Modify or Discontinue the Platform](#_Toc145080741)
- [Termination](#_Toc145080742)
- [Suspension](#_Toc145080743)
- [Representations and Warranties](#_Toc145080745)
- [No Professional Advice](#_Toc145080746)
- [No Additional Warranties](#_Toc145080747)
- [Indemnity](#_Toc145080748)
- [Limitations of Liability](#_Toc145080749)
- [Dispute Resolution](#_Toc145080750)
- [Injunctive](#_Toc145080744) Relief
- [Choice of Law, Jurisdiction and Venue](#_Toc145080751)
- [Waivers of Collective Action and Jury Trial](#_Toc145080752)
- [Statute of Limitations](#_Toc145080753)
- [Force Majeure](#_Toc145080754)
- [Privacy](#_Toc145080744) Policy
- [Third](#_Toc145080744) Party Beneficiaries
- [Links to Other Sites](#_Toc145080744)
- [General](#_Toc145080756)
- [Notices](#_Toc145080757)
- [Contact Us](#_Toc145080758)

**Scope**

This Agreement governs your access to and use of the Platform and all Content and Offerings. Unless otherwise specified in this Agreement, all access to and use of the Platform and all Content and Offerings by you or on your behalf is subject to this Agreement.

**Definitions**

Terms used in this Agreement have the definitions given in this Agreement or, if not defined in this Agreement, have their plain English meaning as commonly interpreted in the United States.

**Eligibility**

The Platform and all Offerings and Content are for use by individuals 18 years of age and older. Additional eligibility requirements for Offerings may be stated on the Platform. If you do not meet any such eligibility requirements, the Platform is not for you, and you are not permitted to establish an Account (as defined below) or become a User of the Platform and you may not access or use any Offerings or Content.

**term**

This Agreement is effective as of the Effective Date and will continue until terminated as set forth herein.

**Modifications**

South Street may, in its sole discretion, modify this Agreement from time to time. South Street will use commercially reasonable efforts to provide notice of any material modifications to this Agreement. Notice may be provided to you directly or to all Users through the Platform. Unless we make a change for legal or administrative reasons, any modification to this Agreement will be effective 5 days following posting of the modified version of this Agreement to the Platform. Your continued access to the Platform or use of the Offerings or Content following that date constitutes your acceptance of, and agreement to be bound by, any modified Agreement. Except for the foregoing, this Agreement may be amended or modified only by a writing signed by both parties.

**additional agreements**

Additional South Street Agreements. In addition to this Agreement, if you enter into any other agreement with South Street or any of its affiliates, this Agreement does not affect the relationship you have with South Street or such affiliate under your additional agreement with South Street, and any such additional agreement will remain applicable between you and South Street or such affiliate, as applicable, provided that the terms of this Agreement will control in all respects with regard to the Platform and all Content and Offerings and your access to and use thereof

Third Party Agreements. You may also be required to agree to additional terms and conditions applicable to Offerings or Content provided by third party providers of South Street. Any such third party terms and conditions shall control only as to your use of the third party Offering covered by those terms and conditions and the terms of this Agreement will continue to control in all other respects with respect to the Platform and all Content and Offerings

**Account**

Platform. Before you can become a User of the Platform, you will be required to establish an account on the Platform (your “Account”). All Accounts are issued at the sole discretion of South Street

Account ID. Your Account and the user name and password for your Account (“Account ID”) are personal in nature. Your Account is for your own business use and your Account ID may be used only by you alone. You may not transfer your Account to someone else. You also may not provide your Account ID to anyone else or give a third party access to your Account. You will ensure the security and confidentiality of your Account ID and will notify South Street immediately if any Account ID is lost, stolen or otherwise compromised

Responsibility. You are solely responsible for your Account and all use of the Platform and any Offerings or Content through your Account. You are fully responsible for all actions taken through your Account (or using your Account ID) and for any liabilities and damages incurred through the use of your Account (or your Account ID), whether lawful or unlawful

**Your Obligation to Provide Truthful Information**

In connection with establishing your Account and accessing the Platform, you may be asked to submit information to the Platform. If you submit information to South Street through the Platform, you agree that (1) the information you submit will be true, accurate, current and complete, and (2) you will promptly update your information to keep it accurate and current.

**Access**

All Offerings are provided for your convenience and you assume sole responsibility for your use of or reliance upon the Offerings and all Content made available through the Offerings. Subject to your compliance with this Agreement, during the term of this Agreement, South Street will permit you to access the Platform, including all Content and Offerings, solely in accordance with the terms of this Agreement (1) if you have entered this Agreement on behalf of an entity or organization, for the business purposes of that entity or organization, or (2) if you have entered this Agreement on your own behalf, for your own business purposes. Your right to access the Platform is personal to you and you may not distribute, sell, resell, lend, loan, lease, license, sublicense or transfer any of your rights to access or use the Platform or otherwise make the Platform available to any third party.

**Content**

The Content is provided by South Street and its third party suppliers, providers, and licensors. All Content is for informational purposes only. South Street does not take any responsibility for any interruptions or delays in Content or the unavailability of any Content. South Street is not responsible for any errors or omissions in any Content. You are solely responsible for verifying the accuracy and completeness of all Content, as well as the applicability and suitability of any Content to your intended use. Subject to your compliance with this Agreement, you may view the Content made available to you solely through the Platform and only for your own business use in connection with your use of the Platform.

**Content Restrictions**

You will not and will not permit any third party to: (1) access or attempt to access any Content or Offerings except through the Platform as expressly provided in this Agreement; (2) copy, duplicate, capture, replicate, store, or maintain any Content outside of the Platform; (3) transfer, sell, lease, license, sublicense, distribute, disclose divulge or make available to any third party any Content or Offerings (or any analysis thereof); (4) alter, copy, modify, or create derivative works of any Content or Offerings (including any data or information derived from any Content or Offerings) or use any Content or Offerings to create or contribute to any algorithm, product or service; (5) reverse engineer, disassemble, decompile, decode or otherwise derive or gain access to the source of any Content or Offerings or methods used to compile or create any Content or Offerings; (6) attempt to circumvent or overcome any technological protection measures intended to restrict access to any portion of any Content or Offerings; (7) use any Content or Offerings in any unlawful manner; (8) contest, challenge or otherwise make any claim or take any action adverse to South Street’s or its applicable supplier’s, provider’s, or licensor’s ownership of, or interest in, any Content or Offerings; (9) use any Content or Offerings in a manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any third party, or that violates applicable law; or (10) alter, obscure, or remove any copyright, trademark or any other notices that are provided on or in connection with any Content or Offerings.

**Ownership**

South Street and its applicable suppliers, providers, and licensors retain all right, title and interest, including all intellectual property rights, in and to the Platform, Offerings, and all Content, as well as all software, databases, hardware, and other technology used by or on behalf of South Street to operate the Platform and provide Content and Offerings (“**Technology**”), and any additions, improvements, updates and modifications thereto (collectively, “**South Street Property**”). You receive no ownership interest in or to the South Street Property and you are not granted any right or license to use the South Street Property itself, apart from your ability to access the Platform, Offerings, and Content under this Agreement. The name, logo and all product and service names of South Street and its suppliers, providers, and licensors that are associated with the Platform and Content are trademarks of South Street and its suppliers, providers, and licensors, and you are granted no right or license to use them.

**Feedback**

Any comments, feedback, suggestions, and other communications regarding the Platform (“**Feedback**”) is entirely voluntary. We will be free to use any Feedback as we see fit for any purpose and without any notice, payment, or other obligation to you. All use of any Feedback will be at our sole discretion.

**Additional Restriction**

You may only use the Platform and Content for lawful purposes in accordance with this Agreement. As a condition of your use of the Platform, you represent and warrant to us that you will not, and will not permit any third party to use the Platform or Content for any purpose that is unlawful or prohibited by this Agreement. Without limiting the foregoing, you will not and will not permit any third party to access (or attempt to access) or use the Platform:

- to impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age or your affiliation with any person or entity, or express or imply that we endorse any statement that you make;
- in a manner that violates any national, state, local or international law, rule or regulation;
- to further or promote any criminal or illegal activity;
- in a manner that interferes with, disables, disrupts, impairs or creates an undue burden on the Platform or Technology;
- to harvest or collect e-mail addresses or other contact information of other Users by electronic or other means for the purposes of sending unsolicited e-mails or other unsolicited communications;
- to transmit, distribute, store or destroy material in violation of any applicable law or regulation, in a manner that will infringe the copyright, trademark, trade secret or other intellectual property rights of any third party or violate the privacy, publicity or other personal rights of any third party, or that is defamatory, obscene, threatening, abusive or hateful; or
- in a manner that we determine, in our sole discretion, restricts or inhibits any other User from using or enjoying the Platform.

**Audit Rights**

With respect to the Platform, Content, and Offerings, at any time during the term of this Agreement and the 12 month period immediately thereafter (or such longer period as may be necessary for South Street to comply with applicable laws), South Street or its applicable third party suppliers, providers, and licensors, may appoint an agent or auditor (collectively, “**Auditors**”) to conduct an audit and inspection of your applicable books and records to confirm your compliance with the terms of this Agreement with respect to the Platform, Content, and Offerings. The Auditors will conduct these audits and inspections during regular business hours, and in a manner that does not interfere with your normal business operations. These audits and inspections may only occur once per every consecutive twelve-month period, and on at least 30 days’ prior notice to you, except that South Street may exercise its audit rights more frequently and with only reasonable prior notice if it reasonably believes you are in breach of this Agreement or if such audit is being conducted pursuant to an examination, request, or requirement of a governmental or regulatory authority. You shall cooperate with the Auditors in connection with any such audits and inspections. All access to your books and records by any Auditors is subject to your reasonable security and confidentiality agreements, policies and procedures. If any audit or inspection reveals that you are not in material compliance with this Agreement, you shall reimburse South Street for its reasonable costs and expenses in connection with conducting such audit or inspection.

**Fees**

You are responsible for paying any fees and charges applicable to your access to our use of the Platform or any Offerings or Content, as indicated on the Platform (“**Fees**”). All Fees will be due and payable as indicated on the Platform. You will pay all Fees when due. Unless otherwise indicated on the Platform, all Fees will be payable in U.S. Dollars. All Fees are non-refundable once paid. If any amounts should remain unpaid when due, such amounts will be subject to a charge of the lesser of 1.5% per month or the applicable legal maximum. Late charges are intended as reasonable estimates of the amounts necessary to compensate South Street for costs and losses associated with delays in payment, and not as a penalty. South Street may update any Fees applicable to this Agreement or any Offering at any time by making those modifications available to you as part of the Platform. You are solely responsible for all expenses you may incur under this Agreement or through your access to and use of the Platform or any Offerings or Content. All Fees are exclusive of, and you will be responsible for payment of, all taxes, assessments, fees, or other charges of any kind, including, any value-added tax, stamp (or other similar) tax, social security (or local equivalent), or income tax, required by applicable law that may be imposed on either party by way of this Agreement or any amounts you pay under this Agreement, excluding any tax based solely on the net income of South Street.

**Right to Modify or Discontinue the Platform**

We reserve the right to modify, or temporarily or permanently discontinue, all or any portion of the Platform, including any Offerings or Content, at any time with or without notice, by making those modifications through the Platform or by discontinuing access through the Platform. We will not be liable to you or to any third party for any such modification, suspension, or discontinuance.

**Termination**

This Agreement may be terminated by either party at any time, in that party’s sole discretion, upon notice to the other party. Upon any termination of this Agreement: (1) all rights and subscriptions granted to you under this Agreement will terminate; (2) all amounts due or payable by you under this Agreement will become due and payable; (3) you will immediately cease all use of and access to the Platform and all Offerings and Content; (4) you will return to South Street or (if so notified by South Street) destroy, all Content and other confidential information of South Street in your possession or control; and (5) South Street may, in its sole discretion, delete your Account. The relevant portions of the following Sections will survive termination of this Agreement for any reason: Sections 3 (Definitions), 7 (Additional Agreements), 8 (Account), 11 (Content), 12 (Content Restrictions), 13 (Ownership), 14 (Feedback), 17 (Fees), 19 (Termination), 21 (Representations and Warranties), 22 (No Professional Advice), 23 (No Additional Warranties), 24 (Indemnity), 25 (Limitations of Liability), 26 (Dispute Resolution), 28 (Choice of Law, Jurisdiction and Venue), 29 (Waivers of Collective Action and Jury Trial), 30 (Statute of Limitations), 29 (Claims of Infringement), 33 (Third Party Beneficiaries), 34 (Links to Other Sites), 35 (General), and 36 (Notices).

**Suspension**

Without limiting South Street’s right to terminate this Agreement, South Street may also suspend your access to your Account, the Platform, and the Content or Offerings, with or without notice to you, upon any actual, threatened, or suspected breach of this Agreement or any applicable laws, rules, or regulations, or upon any other conduct deemed by South Street to be inappropriate or detrimental to the Platform, South Street or any South Street provider, or any third party.

**Representations and Warranties**

You represent and warrant to South Street that: (1) you have the legal right and authority to enter into this Agreement; (2) this Agreement forms a binding legal obligation on your behalf; (3) you have the legal right and authority to perform your obligations under this Agreement and to grant the rights and licenses described in this Agreement; and (4) your use of and access to the Platform, including all Offerings and Content, will comply with all applicable laws, rules, and regulations and will not cause South Street to violate any applicable laws, rules, or regulations.

**no professional advice**

The Platform, Offerings and Content do not provide INVESTMENT, or other professional advice and are not intended to be a substitute for INVESTMENT, or other professional advice or recommendations. any reference to a particular investment or security or any observation concerning a security or investment provided in any content is not a RECOMMENDATION to buy, sell, or hold such investment or security or make any other investment decisions. All Content made available to You through the Platform is for informational purposes or general guidance only. your use of The platform or any Offerings or Content and any interactions you may have with SOUTH STREET or A third party through the Platform do not create a LEGAL OR OTHER professional relationship and do not create any privacy interests other than those described in the SOUTH STREET Privacy Policy. You should always seek the advice of a LIcensed professional with any questions You may have regarding your individual needs or SITUATION. Never disregard LEGAL, INVESTMENT, or OTHER professional advice (or REFRAIN FROM seeking it) because of any Content You have obtained through the Platform. Your reliance on any Content appearing on the Platform is solely at your own risk.

**no additional warranties**

South street and its third party suppliers, providers, and licensors MAKE NO REPRESENTATIONS OR WARRANTIES WHATSOEVER WITH RESPECT TO THE PLATFORM, INCLUDING ANY OFFERINGS OR CONTENT. THE PLATFORM AND ALL OF ITS CONTENT ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. SOUTH STREET and its THIRD PARTY SUPPLIERS, PROVIDERS, AND LICENSORS do not warrant or guarantee the accuracy, completeness, adequacy, or currency of any OFFERINGS OR Content and do not endorse the views or opinions that may be expressed in the Content or other data or information provided through the platform OR ANY OFFERING. WE CANNOT AND DO NOT REPRESENT THAT THE PLATFORM WILL OPERATE ERROR-FREE, UNINTERRUPTED, FREE FROM UNAUTHORIZED ACCESS, THAT THE PLATFORM AND OFFERINGS ARE FREE OF COMPUTER VIRUSES, MALWARE, OR OTHER HARMFUL MECHANISMS, OR OTHERWISE MEET YOUR REQUIREMENTS. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF PLATFORM OR ANY OFFERINGS OR CONTENT OR THE ACCURACY OR COMPLETENESS OF THEREOF. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, ARISING BY STATUTE, CUSTOM OR COURSE OF DEALING, COURSE OF PERFORMANCE OR IN ANY OTHER WAY, INCLUDING ANY IMPLIED WARRANTIES OF TITLE, NON–INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE ACCURACY, CORRECTNESS, RELIABILITY, COMPLETENESS OR USEFULNESS OF ANY CONTENT. YOU EXPRESSLY AGREE THAT YOUR USE OF THE CONTENT IS AT YOUR SOLE RISK.

Nothing in this Agreement will affect any statutory rights to which you may be entitled as a consumer to the extent your ability to alter or waive such rights by contract is limited by applicable law. Specifically, you acknowledge that you may have or may in the future have claims against us which you do not know or suspect to exist in your favor when you agreed to this Agreement and which if known, might materially affect your consent to this Agreement. You expressly waive all rights you may have under Section 1542 of the California Civil Code, which states:

“A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASING PARTY.”

IF THIS CLAUSE IS HELD TO BE UNENFORCEABLE IN WHOLE OR IN PART IN ANY JURISDICTION DUE TO RELEVANT LAWS, THEN IN NO EVENT WILL OUR OR THE RELEASED PARTIES’ (AS DEFINED BELOW) TOTAL LIABILITY TO YOU EXCEED THE TOTAL AMOUNT YOU HAVE PAID US OR THE RELEASED PARTIES DURING THE 6 MONTHS PRIOR TO THE INCIDENT. NOTHING IN THIS CLAUSE WILL LIMIT OR EXCLUDE ANY LIABILITY FOR DEATH OR PERSONAL INJURY RESULTING FROM NEGLIGENCE.

**Indemnity**

You hereby agree to indemnify, defend, and hold harmless South Street, its suppliers, providers, and licensors, and its and their respective officers, directors, affiliates, employees, agents, contractors, assigns, users, customers, providers, licensees, and successors in interest (“**Indemnified Parties**”) from any and all claims, losses, liabilities, damages, fees, expenses and costs (including attorneys' fees, court costs, damage awards, and settlement amounts) that result from any claim or allegation against any Indemnified Party arising in any manner from: (1) your access to or use of the Platforms, including any Offerings and Content; (2) your negligence or willful misconduct; and (3) your breach of any representation, warranty, or other provision of this Agreement. South Street will provide you with notice of any such claim or allegation, and South Street will have the right to participate in the defence of any such claim.

**Limitations of Liability**

UNDER NO CIRCUMSTANCES WILL SOUTH STREET, ITS suppliers, providers, and licensors, or any of its or their respective DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, SUPPLIERS, LICENSORS AND SERVICE PROVIDERS (COLLECTIVELY, THE “**RELEASED PARTIES**”) BE LIABLE TO YOU, OR ANY THIRD PARTY CLAIMING THROUGH YOU, FOR ANY LOSSES OR DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE PLATFORM, INCLUDING ANY OFFERINGS OR CONTENT, YOUR USE OF OR INABILITY TO USE THE PLATFORM OR ANY CONTENT OR OFFERINGS, ANY LOSS OR DAMAGE ON ACCOUNT OF YOUR USE OF THE PLATFORM OR ANY CONTENT OR OFFERINGS ON THE PLATFORM, OR ANY DELAYS, INTERRUPTIONS, OR OMISSIONS IN YOUR USE OF THE PLATFORM OR ANY CONTENT OR OFFERINGS.

THIS IS A COMPREHENSIVE LIMITATION OF LIABILITY THAT APPLIES TO ALL LOSSES AND DAMAGES OF ANY KIND (WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, CONSEQUENTIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, LOSS OF DATA, INCOME, PROFITS, SALES, TURNOVER, REPUTATION, GOODWILL, CUSTOMERS, DATA, INFORMATION, SOFTWARE, CONTRACTS, ANTICIPATED SAVINGS, WASTED EXPENDITURE, OR BUSINESS OPPORTUNITIES), WHETHER THE CLAIM IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR ANY OTHER LEGAL THEORY, EVEN IF AN AUTHORIZED REPRESENTATIVE OF SOUTH STREET OR ANOTHER RELEASED PARTY HAS BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES, AND WITHOUT REGARD TO THE SUCCESS OR EFFECTIVENESS OF OTHER REMEDIES.

IF ANY PART OF THIS LIMITATION OF LIABILITY IS FOUND TO BE INVALID, ILLEGAL OR UNENFORCEABLE FOR ANY REASON, THEN THE AGGREGATE LIABILITY OF THE RELEASED PARTIES UNDER SUCH CIRCUMSTANCES TO YOU OR ANY PERSON OR ENTITY CLAIMING THROUGH YOU FOR ANY LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED WILL NOT EXCEED ONE HUNDRED UNITED STATES DOLLARS ($100.00). YOU AGREE THAT WE WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS ON OUR LIABILITY.

THE ABOVE LIMITATIONS APPLY EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE OR SUCH DAMAGES OR LOSSES WERE FORESEEABLE. THE ABOVE LIMITATIONS ARE SEPARATE AND APART FROM AND DO NOT AFFECT ANY LIMITATION SPECIFIED IN ANY OTHER AGREEMENT BETWEEN THE PARTIES.

SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. ACCORDINGLY, SOME OF THE THESE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.

NOTHING IN THIS AGREEMENT WILL LIMIT OR EXCLUDE LIABILITY FOR LOSSES OR DAMAGES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW.

**dispute resolution**

Except as otherwise provided below, the parties will attempt to resolve all disputes, controversies, or claims arising under, out of, or relating to this Agreement, including the formation, validity, binding effect, interpretation, performance, breach or termination, of this Agreement and the arbitrability of the issues submitted to arbitration hereunder and non-contractual claims relating to this Agreement (each, a “**Dispute**”), in accordance with the procedures set forth in this Section.

If any Dispute cannot be resolved through negotiations between the parties within 5 days of notice from one party to the other of the Dispute, then at the request of either party such Dispute will be finally settled through binding arbitration under the arbitration of the American Arbitration Association (“**AAA**”) then in effect (the “**Rules**”). Either party may commence the arbitration by delivering a request for arbitration as specified in the Rules. The arbitration will be conducted before a sole neutral arbitrator selected by agreement of the parties. If the parties cannot agree on the appointment of a single arbitrator within 30 days (the “**Initial Period**”) after either party to this Agreement delivers a request for arbitration, a neutral arbitrator will be selected as provided in the Rules. The arbitration will be conducted exclusively in the English language at a site specified by South Street in New York, New York. The award of the arbitrator will be the exclusive remedy of the parties for all claims, counterclaims, issues or accountings presented or plead to the arbitrator. The award of the arbitrators will require payment of the costs, fees and expenses incurred by the prevailing party in any such arbitration by the non-prevailing party. Judgment upon the award may be entered in any court or governmental body having jurisdiction thereof. Any additional costs, fees or expenses incurred in enforcing the award may be charged against the party that resists its enforcement.

**injunctive relief**

Violation of the terms of this Agreement as such terms relate to the Platform or any Offerings or Content may cause serious damage to South Street and its suppliers, providers, and licensors. In such event, money damages may not constitute sufficient compensation to South Street or its suppliers, providers, and licensors. Consequently, you agree that in the event of any such violation of the terms of this Agreement, South Street and its applicable suppliers, providers, and licensors shall have the right to obtain injunctive relief in addition to any other legal or financial remedies to which South Street or its suppliers, providers, or licensors may be entitled to hereunder.

**Choice of Law, Jurisdiction and Venue**

This Agreement and the interpretation of the rights and obligations of the parties under this Agreement, including, to the extent applicable, any negotiations, arbitrations or other proceedings hereunder, will be governed exclusively by and construed in accordance with the federal laws of the United States of America and the laws of the State of New York, excluding conflict of laws provisions that would indicate the application of the laws of any other jurisdiction.

EACH PARTY WILL BRING ANY LEGAL ACTION OR PROCEEDING RELATING TO ANY DISPUTE OR OTHERWISE ARISING FORM THIS AGREEMENT OR YOUR ACCESS TO OR USE OF THE PLATFORM, ONLY IN A STATE OR FEDERAL COURT LOCATED IN NEW YORK, NEW YORK, U.S.A. YOU AND WE IRREVOCABLY AGREE TO CONSENT AND SUBMIT TO THE JURISDICTION AND VENUE OF SUCH COURTS.

You expressly waive any claim of improper venue and any claim that such courts are an inconvenient forum.

**Waivers of Collective Action and Jury Trial**

YOU AGREE THAT YOU WILL PURSUE ANY CLAIM OR LAWSUIT RELATED TO ANY DISPUTE OR OTHERWISE ARISING FROM OR IN ANY WAY RELATING TO THIS AGREEMENT, THE PLATFORM, CONTENT, OR OFFERINGS, OR YOUR USE THEREOF AS AN INDIVIDUAL, AND WILL NOT LEAD, JOIN, OR SERVE AS A REPRESENTATIVE OR MEMBER OF A CLASS OR GROUP OF PERSONS BRINGING SUCH A CLAIM OR LAWSUIT.

THE PARTIES DESIRE TO AVOID THE TIME AND EXPENSE RELATING TO A JURY TRIAL OF ANY DISPUTE. ACCORDINGLY, THE PARTIES, FOR THEMSELVES AND THEIR SUCCESSORS AND ASSIGNS, HEREBY WAIVE TRIAL BY JURY OF ANY DISPUTE. THE PARTIES ACKNOWLEDGE THAT THIS WAIVER IS KNOWINGLY, FREELY, AND VOLUNTARILY GIVEN, IS DESIRED BY BOTH PARTIES AND IS IN THE BEST INTERESTS OF BOTH PARTIES.

**Statute of Limitations**

You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Platform or this Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred to the extent permitted by applicable law.

**Force Majeure**

South Street will not be responsible for failure or delay in the performance of any obligation under this Agreement to the extent such failure or delay is due to acts of God or governmental authority, war, terrorism, strikes, boycotts, quarantine, pandemic, labor disputes, fire or other loss of facilities, accident, or any other event beyond its control. South Street will use commercially reasonable efforts to notify you of any such event.

**Privacy Policy**

While the South Street Privacy Policy is not part of this Agreement, you consent to the use and disclosure of your personally identifiable information and other data and information as described in the South Street Privacy Policy.

**third party beneficiaries**

Each third party supplier, provider, or licensor of any Content or Offering may enforce its rights against you as a User of that Content or Offering as a third party beneficiary of this Agreement, even though such third party supplier, provider, or licensor is not a party to this Agreement. Except as expressly stated in this Agreement, no term of this Agreement will be construed to confer any third party beneficiary rights on any non-party.

**links to other sites**

The Platform, Offerings, or Content may provide links to other sites or services that are not under the control of South Street. Your use of such sites and services is subject to the terms and policies of such sites and services and not this Agreement. South Street is not responsible for any content on any linked site or service. YOUR USE OF ANY THIRD-PARTY SITES OR SERVICES IS AT YOUR OWN RISK.

**General**

As used in this Agreement, the term “including” means “including, but not limited to.” Section headings are for reference purposes only. Our failure at any time to require performance of any provision of this Agreement or to exercise any right provided for herein will not be deemed a waiver of such provision or such right. All waivers must be in writing and signed in ink by the party to be bound. Unless the written waiver contains an express statement to the contrary, no waiver of any breach of any provision of this Agreement or of any right provided for herein will be construed as a waiver of any continuing or succeeding breach of such provision, a waiver of the provision itself, or a waiver of any right under this Agreement. If any provision of this Agreement is held to be invalid or unenforceable by a court of competent jurisdiction, such invalidity or unenforceability will not affect the validity or enforceability of the remaining provisions, and the court will substitute for such provision a valid and enforceable provision that most closely approximates the intent and economic effect of such provision. The remaining provisions of this Agreement will remain in full force and effect. The parties hereto are independent parties, not agents, employees or employers of the other, or joint ventures, and neither acquires hereunder any right or ability to bind or enter into any obligation on behalf of the other. You may not assign this Agreement or any of your rights or obligations under this Agreement, whether through operation of law or otherwise, without the prior written approval of South Street. Any assignment in violation of the foregoing will be null and void. We may freely assign this Agreement. Each and every right and remedy hereunder is cumulative with each and every other right and remedy herein or in any other agreement between the parties or under applicable Law. This Agreement may be amended only as set forth herein.

**Notices**

Unless otherwise specified in this Agreement, any notices required or allowed under this Agreement will be provided to South Street by postal mail to the address for South Street listed on the Platform. South Street may provide you with any notices required or allowed under this Agreement by sending you an email to any email address you provide to South Street in connection with your Account, provided that in the case of any notice applicable both to you and other Users of the Platform, South Street may instead provide such notice by posting the notice on the Platform. Notices provided to South Street will be deemed given when actually received by South Street. Notice provided to you will be deemed given 24 hours after posting to the Platform or sending via e-mail, unless (as to e-mail) the sending party is notified that the e-mail address is invalid.

**Contact Us**

If you have any questions or concerns regarding this Agreement, please contact us using the information provided below:

Email: [mbstrading@sssnyc.com](mailto:mbstrading@sssnyc.com)

Last updated: March 2024
`;

const styles = StyleSheet.create({
  markdownContainer: {
    paddingRight: 32,
  },
});

const TermsOfServiceBottomSheetContent = () => {
  const { height: windowHeight } = useWindowDimensions();
  const closeBottomSheet = useGlobalBottomSheetStore(
    (state) => state.closeBottomSheet,
  );
  const { desktop } = useMedia();

  return (
    <YStack flex={1} height={windowHeight - getSpaceTokenValue('$26')}>
      <ScreenWrapperScroll>
        <YStack padding={'$6'}>
          {desktop && (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <XStack
              {...(Platform.OS === 'web'
                ? { position: 'sticky', right: 0, top: 0, zIndex: 1000 }
                : undefined)}
              justifyContent={'flex-end'}
            >
              <Pressable onPress={closeBottomSheet}>
                <Icon iconName={'closeIcon'} color={'$mono700'} size={'$6'} />
              </Pressable>
            </XStack>
          )}
          <View style={desktop ? styles.markdownContainer : undefined}>
            <Markdown>{content}</Markdown>
          </View>
        </YStack>
      </ScreenWrapperScroll>
    </YStack>
  );
};

export { TermsOfServiceBottomSheetContent };
