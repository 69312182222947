import type { RootStackNavigatorParamList } from './types';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useAuthenticationStatus } from '@utility-nyc/react-auth-core';

import { PrivateStackNavigator } from '../PrivateStackNavigator/PrivateStackNavigator';
import { PublicStackNavigator } from '../PublicStackNavigator';

const Stack = createNativeStackNavigator<RootStackNavigatorParamList>();

const RootStackNavigator = () => {
  const status = useAuthenticationStatus();

  return (
    <Stack.Navigator>
      {status === 'logged in' ? (
        <Stack.Screen
          name={'PrivateStackNavigator'}
          component={PrivateStackNavigator}
          options={{ headerShown: false }}
        />
      ) : (
        <Stack.Screen
          name={'PublicStackNavigator'}
          component={PublicStackNavigator}
          options={{ headerShown: false }}
        />
      )}
    </Stack.Navigator>
  );
};

export { RootStackNavigator };
