import { Platform, StyleSheet } from 'react-native';

import { useMedia, XStack, YStack } from 'tamagui';

import { BodyL, Icon, LabelL } from '@south-street-app/atoms';

type DisplayItemProps = {
  label: string;
  value?: string;
  onPress?: () => void;
};

const styles = StyleSheet.create({
  pressStyle: {
    opacity: 0.7,
  },
});

const DisplayItem = ({ label, value, onPress }: DisplayItemProps) => {
  const { desktop } = useMedia();

  return (
    <YStack
      onPress={onPress}
      pressStyle={onPress ? styles.pressStyle : undefined}
      {...(Platform.OS === 'web' && onPress
        ? { cursor: 'pointer' }
        : undefined)}
    >
      <XStack
        justifyContent={'space-between'}
        paddingVertical={'$4'}
        paddingHorizontal={'$4'}
      >
        <LabelL fontWeight={'$2'}>{label}</LabelL>
        <BodyL>{value}</BodyL>
        {desktop && !value && onPress ? (
          <Icon iconName={'chevronRightIcon'} size={'$4'} color={'$black'} />
        ) : null}
      </XStack>
      <YStack height={'$px'} backgroundColor={'$mono300'} />
    </YStack>
  );
};

export { DisplayItem };
