import { StyleSheet } from 'react-native';

import { YStack } from 'tamagui';

import { Icon } from '@south-street-app/atoms';

const styles = StyleSheet.create({
  pressStyle: {
    opacity: 0.7,
  },
});

const SwapButtom = ({ onPress }: { onPress: () => void }) => (
  <YStack
    backgroundColor={'$mono200'}
    height={'$9'}
    width={'$9'}
    borderRadius={'$xl'}
    justifyContent={'center'}
    alignItems={'center'}
    onPress={onPress}
    pressStyle={styles.pressStyle}
  >
    <Icon size={'$6'} iconName={'swapIcon'} />
  </YStack>
);

export { SwapButtom };
