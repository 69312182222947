import type { TradingStackNavigationProps } from '@south-street-app/navigation/types';

import { KeyboardAvoidingView, SafeAreaView, StyleSheet } from 'react-native';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';
import { YStack } from 'tamagui';

import { isAndroid } from '@south-street-app/utils';

import { SwitchTradeRender } from './SwitchTradeRender';

const IOS_KEYBOARD_DELTA = 100;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: getColorTokenValue('$mono100'),
  },
  safeArea: {
    flex: 1,
  },
  pressStyle: {
    opacity: 0.7,
  },
});

type TradeOrderScreenProps = TradingStackNavigationProps<'TradeOrder'>;

const TradeOrderScreen = ({ navigation }: TradeOrderScreenProps) => (
  <KeyboardAvoidingView
    behavior={isAndroid ? 'height' : 'padding'}
    style={styles.container}
    keyboardVerticalOffset={IOS_KEYBOARD_DELTA}
  >
    <SafeAreaView style={styles.safeArea}>
      <YStack flex={1} padding={'$4'} backgroundColor={'$mono100'}>
        <SwitchTradeRender navigation={navigation} />
      </YStack>
    </SafeAreaView>
  </KeyboardAvoidingView>
);

export { TradeOrderScreen };
