import type { TradeRequestProposalDto } from '@utility-nyc/react-query-sdk';

import { useMemo } from 'react';

import { YStack } from 'tamagui';

import { stringifyQuoteNumberSpread } from '@shared/utils';
import { HeadingM } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';

import { DisplaySwapTradeInfo } from './DisplaySwapTradeInfo';
import { DisplayTradeItem } from '../PreviewTradeOrderScreen/DisplayTradeItem';

type DisplayMultipleTradeInfoProps = {
  counterProposal: TradeRequestProposalDto;
};

const DisplayMultipleTradeInfo = ({
  counterProposal,
}: DisplayMultipleTradeInfoProps) => {
  const formattedSpread = useMemo(
    () =>
      counterProposal.spread
        ? stringifyQuoteNumberSpread({
            point: counterProposal.spread.par,
            tick: counterProposal.spread.fraction,
          })
        : '',
    [counterProposal.spread],
  );

  return (
    <YStack>
      <HeadingM fontWeight={'$3'} paddingTop={'$6'}>
        {counterProposal.spread ? en_US.roll : en_US.swap}
      </HeadingM>
      {counterProposal.spread ? (
        <DisplayTradeItem label={en_US.spread} value={formattedSpread} />
      ) : null}
      {counterProposal.components.map((component) => (
        <DisplaySwapTradeInfo order={component} />
      ))}
    </YStack>
  );
};

export { DisplayMultipleTradeInfo };
