import type { TamaguiElement } from 'tamagui';

import { useCallback, useRef, useState } from 'react';

import { StyleSheet } from 'react-native';

import { useFocusEffect } from '@react-navigation/native';
import { useBottomSheetInputControls } from '@utility-nyc/react-native-bottom-sheet';
import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { Input, useMedia, XStack, YStack } from 'tamagui';

import { formatToMoney } from '@shared/utils';
import { Icon } from '@south-street-app/atoms';

const styles = StyleSheet.create({
  inputContainer: {
    borderColor: 'transparent',
    fontSize: getSpaceTokenValue('$5'),
    fontWeight: '600',
    height: getSpaceTokenValue('$7'),
    paddingLeft: getSpaceTokenValue('$1'),
  },
  pressStyle: {
    opacity: 0.7,
  },
});

type MoneyInputProps = {
  onChangeText: (text: string) => void;
};

const MoneyInput = ({ onChangeText }: MoneyInputProps) => {
  const inputRef = useRef<TamaguiElement>(null);
  const [textValue, setTextValue] = useState('');
  const { desktop } = useMedia();
  const { handleFocus, handleBlur } = useBottomSheetInputControls({});

  const handleOnChangeText = (text: string) => {
    setTextValue(text);

    onChangeText(text);
  };

  const resetTextValue = () => {
    setTextValue('');

    onChangeText('');
  };

  const handleOnPress = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (!desktop) {
        setTimeout(() => inputRef.current?.focus(), 500);
      }
    }, [desktop]),
  );

  return (
    <XStack
      alignItems={'center'}
      borderWidth={'$px'}
      borderColor={'$mono300'}
      backgroundColor={'$mono200'}
      borderRadius={'$m'}
      paddingHorizontal={'$2'}
      paddingVertical={'$4'}
      justifyContent={'space-between'}
      onPress={handleOnPress}
    >
      <XStack alignItems={'center'}>
        <Icon iconName={'currencyIcon'} size={'$6'} color={'$brand500'} />
        <Input
          ref={inputRef}
          placeholder={'0'}
          keyboardType={'numeric'}
          style={styles.inputContainer}
          onChangeText={handleOnChangeText}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={formatToMoney(textValue)}
          maxLength={20}
          unstyled
        />
      </XStack>
      {textValue.length > 0 ? (
        <YStack pressStyle={styles.pressStyle} onPress={resetTextValue}>
          <Icon iconName={'closeIcon'} size={'$6'} color={'$brand600'} />
        </YStack>
      ) : null}
    </XStack>
  );
};

export { MoneyInput };
