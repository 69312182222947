import type {
  TradeRequestProposalDto,
  TradeRequestComponentDto,
} from '@utility-nyc/react-query-sdk';

import {
  apiSpecToQuoteNumber,
  capitalizeFirstLetter,
  formatToMoney,
  getShortMonthName,
  stringifyQuoteNumber,
  stringifyQuoteNumberSpread,
} from '@shared/utils';

const monthFromProduct = (component: TradeRequestComponentDto) => {
  let month = component.product.month;

  if (component.product.leg1Product) {
    month = component.product.leg1Product.month;
  }

  return getShortMonthName(month);
};

const tradeCardItems = (counterProposal: TradeRequestProposalDto) => {
  const items = counterProposal.components.map(
    (component: TradeRequestComponentDto) => ({
      label: `${capitalizeFirstLetter(component.type)} $${formatToMoney(
        component.amount,
      )} ${component.product.name} ${
        component.product.coupon
      } ${monthFromProduct(component)}`,
      title: `${stringifyQuoteNumber(apiSpecToQuoteNumber(component.price))}`,
    }),
  );

  if (counterProposal.spread) {
    items.unshift({
      label: 'Spread',
      title: stringifyQuoteNumberSpread(
        apiSpecToQuoteNumber(counterProposal.spread),
      ),
    });
  }

  return items;
};

export { tradeCardItems };
