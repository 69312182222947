import type { ReactNode } from 'react';

import type { LayoutChangeEvent } from 'react-native';
import { StyleSheet, SafeAreaView } from 'react-native';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';

type ScreenWrapperProps = {
  children: ReactNode;
  onLayout?: (event: LayoutChangeEvent) => void;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: getColorTokenValue('$mono100'),
  },
});

const ScreenWrapper = ({ children, onLayout }: ScreenWrapperProps) => (
  <SafeAreaView onLayout={onLayout} style={styles.container}>
    {children}
  </SafeAreaView>
);

export { ScreenWrapper };
