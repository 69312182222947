import React from 'react';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { YStack } from 'tamagui';

import { getInitials } from '@shared/utils';
import { LabelS } from '@south-street-app/atoms';

type InitialsCircleProps = {
  onPress?: () => void;
  name?: string;
  usePadding?: boolean;
  size?: 'large' | 'small';
  hasBorders?: boolean;
};

const InitialsCircle = ({
  onPress,
  name,
  usePadding = true,
  size = 'large',
  hasBorders = false,
}: InitialsCircleProps) => (
  <YStack
    paddingRight={usePadding ? '$4' : undefined}
    paddingBottom={usePadding ? '$4' : undefined}
    onPress={onPress}
  >
    <YStack
      borderRadius={getSpaceTokenValue('$10')}
      backgroundColor={'$brand500'}
      justifyContent={'center'}
      alignItems={'center'}
      borderWidth={hasBorders ? 1 : 0}
      borderColor={'$mono100'}
      width={size === 'large' ? '$9' : '$7'}
      height={size === 'large' ? '$9' : '$7'}
      paddingBottom={name === '...' ? '$1.5' : undefined}
    >
      <LabelS color={'$mono100'} fontWeight={'$2'}>
        {name === '...' ? name : getInitials(name || '')}
      </LabelS>
    </YStack>
  </YStack>
);

export { InitialsCircle };
