import type { PoolColorRecordResponseDto } from '@utility-nyc/react-query-sdk';
import type { ColorTokens } from 'tamagui';

import { StyleSheet } from 'react-native';

import { getSpaceTokenValue } from '@utility-nyc/react-native-ui-config';
import { useMedia, XStack, YStack } from 'tamagui';

import { LabelL, LabelS } from '@south-street-app/atoms';

type PoolColorListItemProps = {
  item: PoolColorRecordResponseDto;
  index: number;
  backgroundColor: ColorTokens;
  fontColor: ColorTokens;
};

const styles = StyleSheet.create({
  columnWebWidth: { width: '57%' },
  columnNativeWidth: { flex: 4 },
  '25%': { width: '25%' },
  '75%': { width: '75%' },
  '43%': { width: '43%', alignItems: 'flex-end' },
  'flex-2': { flex: 2 },
  'flex-3': { flex: 3 },
  'flex-1': { flex: 1 },
});

const PoolColorListItem = ({
  item,
  backgroundColor,
  fontColor,
}: PoolColorListItemProps) => {
  const { desktop } = useMedia();

  return (
    <XStack
      backgroundColor={'$mono100'}
      paddingVertical={'$3.5'}
      paddingHorizontal={'$4'}
      alignItems={'center'}
    >
      <XStack
        style={desktop ? styles.columnWebWidth : styles.columnNativeWidth}
        alignItems={'center'}
      >
        <YStack style={desktop ? styles['75%'] : styles['flex-2']}>
          <LabelL fontWeight={'$2'}>{item.type}</LabelL>
        </YStack>
        <YStack
          style={desktop ? styles['25%'] : styles['flex-1']}
          width={'$15'}
          height={'$8'}
          borderRadius={getSpaceTokenValue('$10')}
          justifyContent={'center'}
          alignItems={'center'}
          backgroundColor={backgroundColor}
        >
          <LabelS fontSize={'$3'} fontWeight={'$2'} color={fontColor}>
            {item.coupon}
          </LabelS>
        </YStack>
      </XStack>
      <YStack
        style={desktop ? styles['43%'] : styles['flex-3']}
        paddingLeft={'$10'}
      >
        <LabelL fontWeight={'$2'}>{item.value}</LabelL>
      </YStack>
    </XStack>
  );
};

export { PoolColorListItem };
