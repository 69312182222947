import type { PrivateStackNavigationProps } from '@south-street-app/navigation/types';

import { useCallback } from 'react';

import { Platform } from 'react-native';

import { Link } from '@react-navigation/native';
import { useMedia, YStack } from 'tamagui';

import { account } from '@shared/services';
import { BodyL, LabelL } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import { ButtonLarge, ScreenWrapper } from '@south-street-app/molecules';
import { DisplayItem } from '@south-street-app/organisms';
import { useGlobalBottomSheetStore } from '@south-street-app/stores';
import { useGetCurrentEndUser } from '@utility-nyc/react-query-sdk';

const accountParams = (
  type: 'privacyPolicy' | 'termsAndConditions' | 'about',
) => ({
  headerTitle: account[type].headerTitle,
  title: account[type].title,
  linkLabel: account[type].linkLabel,
  description: account[type].description,
  url: account[type].url,
});

const AccountScreen = ({
  navigation,
}: PrivateStackNavigationProps<'AccountScreen'>) => {
  const { desktop } = useMedia();
  const openBottomSheet = useGlobalBottomSheetStore(
    (state) => state.openBottomSheet,
  );

  const { data } = useGetCurrentEndUser();

  const handleOpenPrivacyPolicy = useCallback(() => {
    navigation.navigate('AccountLinkScreen', accountParams('privacyPolicy'));
  }, [navigation]);
  const handleOpenTermsAndConditions = useCallback(() => {
    navigation.navigate(
      'AccountLinkScreen',
      accountParams('termsAndConditions'),
    );
  }, [navigation]);

  const handleOpenAbout = useCallback(() => {
    navigation.navigate('AccountLinkScreen', accountParams('about'));
  }, [navigation]);

  const handleLogout = useCallback(() => {
    openBottomSheet({ type: 'logout' });
  }, [openBottomSheet]);

  return (
    <ScreenWrapper>
      <YStack
        alignItems={desktop ? 'center' : undefined}
        flex={1}
        paddingTop={desktop ? 0 : '$6'}
        borderTopColor={'$mono300'}
        borderTopWidth={desktop ? '$px' : undefined}
        justifyContent={'space-between'}
      >
        <YStack
          borderBottomWidth={desktop ? '$px' : undefined}
          borderLeftWidth={desktop ? '$px' : undefined}
          borderRightWidth={desktop ? '$px' : undefined}
          borderColor={'$mono300'}
          width={desktop ? '$200' : undefined}
        >
          <DisplayItem label={en_US.name} value={data?.data.name} />
          <DisplayItem label={en_US.email} value={data?.data.email} />
          {/* <DisplayItem
            label={en_US.company}
            value={data?.data.analyticsFirm.name}
          /> */}
          <DisplayItem label={en_US.company} value={data?.data.companyName} />

          <DisplayItem
            label={en_US.privacyPolicy}
            onPress={handleOpenPrivacyPolicy}
          />
          <DisplayItem
            label={en_US.termsAndConditions}
            onPress={handleOpenTermsAndConditions}
          />
          <DisplayItem label={en_US.aboutSS} onPress={handleOpenAbout} />
          <BodyL padding={'$0'} paddingTop={'$4'} paddingHorizontal={'$4'}>
            {account.contactUs}
          </BodyL>
          <BodyL
            padding={'$0'}
            paddingTop={'$2'}
            paddingBottom={'$4'}
            paddingHorizontal={'$4'}
          >
            {account.contactUsEmailMessage}
            {!desktop ? (
              account.contactUsEmail
            ) : (
              <Link to={`mailto:${account.contactUsEmail}`}>
                <BodyL color={'$brand500'} textDecorationLine={'underline'}>
                  {account.contactUsEmail}
                </BodyL>
              </Link>
            )}
          </BodyL>
        </YStack>
        <YStack padding={'$4'} width={desktop ? '$200' : undefined}>
          {Platform.select({
            web: (
              <ButtonLarge
                onPress={handleLogout}
                variant={'secondary'}
                flex={1}
              >
                <LabelL fontWeight={'$3'} color={'$brand500'}>
                  {en_US.logout}
                </LabelL>
              </ButtonLarge>
            ),
            native: (
              <ButtonLarge
                variant={'secondary'}
                color={'$brand500'}
                onPress={handleLogout}
              >
                {en_US.logout}
              </ButtonLarge>
            ),
          })}
        </YStack>
      </YStack>
    </ScreenWrapper>
  );
};

export { AccountScreen };
