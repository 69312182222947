import { useCallback, useEffect, useRef, useState } from 'react';

import { Pressable } from 'react-native';

import { useAuthStore } from '@utility-nyc/react-auth-storage';

import { BodyL } from '@south-street-app/atoms';
import { getSizeTokenValue } from '@south-street-app/theme';
import { useSendOtp } from '@utility-nyc/react-query-sdk';

type ResendcodeButtonProps = {
  email: string;
  password: string;
  countdownTime?: number;
};

type TimerReturnType = ReturnType<typeof setInterval> | undefined;

const ResendcodeButton = ({
  email,
  password,
  countdownTime = 60,
}: ResendcodeButtonProps) => {
  const [countdown, setCountdown] = useState(countdownTime);

  const { mutate: sendCredentials } = useSendOtp();

  const timer = useRef<TimerReturnType>();

  useEffect(() => {
    timer.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 0) {
          return prev;
        }

        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer.current);
  }, [countdown]);

  const handleResend = useCallback(() => {
    clearInterval(timer.current);

    setCountdown(countdownTime);

    useAuthStore.getState().setTokens({
      accessToken: '',
    });

    sendCredentials({
      data: {
        identifier: email,
        credential: password,
      },
    });
  }, [countdownTime, email, password, sendCredentials]);

  return (
    <Pressable
      style={{ paddingTop: getSizeTokenValue('$4') }}
      onPress={countdown >= 0 ? handleResend : undefined}
      disabled={countdown >= 0}
    >
      <BodyL
        userSelect={'none'}
        color={countdown > 0 ? '$mono400' : '$brand500'}
      >
        {`Resend code ${countdown > 0 ? `in ${countdown}s` : ''}`}
      </BodyL>
    </Pressable>
  );
};

export { ResendcodeButton };
