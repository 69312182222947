import type { ReactNode } from 'react';

import type { LayoutChangeEvent } from 'react-native';
import { StyleSheet, SafeAreaView, KeyboardAvoidingView } from 'react-native';

import { getColorTokenValue } from '@utility-nyc/react-native-ui-config';

import { isAndroid } from '@south-street-app/utils';

type ScreenWrapperKAVProps = {
  children: ReactNode;
  onLayout?: (event: LayoutChangeEvent) => void;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: getColorTokenValue('$mono200'),
  },
});

const ScreenWrapperKAV = ({ children, onLayout }: ScreenWrapperKAVProps) => (
  <KeyboardAvoidingView
    behavior={isAndroid ? 'height' : 'padding'}
    style={styles.container}
    keyboardVerticalOffset={110}
  >
    <SafeAreaView onLayout={onLayout} style={styles.container}>
      {children}
    </SafeAreaView>
  </KeyboardAvoidingView>
);

export { ScreenWrapperKAV };
