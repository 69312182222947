const moneyStringToNumber = (moneyString: string) => {
  const cleanedString = moneyString.replace(/[$,]/g, '');

  const numberValue = parseFloat(cleanedString);

  if (isNaN(numberValue)) {
    return 0;
  }

  return numberValue;
};

export { moneyStringToNumber };
