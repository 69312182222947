import type { ForgotPasswordRequestDto } from '@utility-nyc/react-query-sdk';

import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { Controller, useForm } from 'react-hook-form';
import { YStack } from 'tamagui';

import { Toast } from '@shared/utils';
import { BodyL, BodyM, HeadingS } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import {
  ButtonLarge,
  ScreenWrapperKAV,
  TextInput,
} from '@south-street-app/molecules';
import { useForgotPassword } from '@utility-nyc/react-query-sdk';

const emailRules = {
  required: {
    value: true,
    message: en_US.emailIsRequired,
  },
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: en_US.pleaseEnterValidEmail,
  },
};

const ForgotPasswordEmailScreen = () => {
  const { mutate, isLoading } = useForgotPassword();
  const navigation = useNavigation();
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<ForgotPasswordRequestDto>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const handleOnSubmit = handleSubmit((data) => {
    mutate(
      { data },
      {
        onSuccess: () => {
          Toast.showCustomToast({
            text1: en_US.forgotPasswordSuccess,
            position: 'top',
            topOffset: 75,
          });

          setTimeout(() => {
            navigation.navigate('PublicStackNavigator', {
              screen: 'LoginEmailScreen',
            });
          }, 500);
        },
      },
    );
  });

  return (
    <ScreenWrapperKAV>
      <YStack
        flex={1}
        justifyContent={'space-between'}
        paddingTop={'$15'}
        paddingHorizontal={'$4'}
      >
        <YStack space={'$4'}>
          <HeadingS fontWeight={'$2'}>{en_US.resetPassword}</HeadingS>
          <BodyL>{en_US.resetPasswordSubtext}</BodyL>
          <Controller
            control={control}
            name={'email'}
            rules={emailRules}
            render={({
              field: { onChange, value },
              fieldState: { error, invalid },
            }) => (
              <>
                <TextInput
                  placeholder={en_US.email}
                  onChangeText={onChange}
                  value={value}
                  autoCapitalize={'none'}
                  returnKeyType={'done'}
                  onSubmitEditing={handleOnSubmit}
                  autoFocus={true}
                />
                {invalid ? (
                  <BodyM
                    paddingTop={'$1'}
                    fontWeight={'$2'}
                    color={'$accentRed300'}
                  >
                    {error?.message}
                  </BodyM>
                ) : null}
              </>
            )}
          />
        </YStack>
        <ButtonLarge
          disabled={!isValid || !isDirty}
          loading={isLoading}
          onPress={handleOnSubmit}
        >
          {en_US.sendEmail}
        </ButtonLarge>
      </YStack>
    </ScreenWrapperKAV>
  );
};

export { ForgotPasswordEmailScreen };
