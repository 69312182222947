import type { PublicStackNavigationProps } from '@south-street-app/navigation/types';
import type {
  ControllerRenderProps,
  ControllerFieldState,
} from 'react-hook-form';

import { StyleSheet, TouchableOpacity } from 'react-native';

import { useForm, Controller } from 'react-hook-form';
import { useMedia, YStack } from 'tamagui';

import { BodyM, HeadingS } from '@south-street-app/atoms';
import { en_US } from '@south-street-app/configs';
import {
  TextInput,
  ButtonLarge,
  ScreenWrapperKAV,
} from '@south-street-app/molecules';

type EmailFormType = {
  email: string;
};
const styles = StyleSheet.create({
  forgotPassword: {
    alignSelf: 'center',
  },
});

const emailRules = {
  required: {
    value: true,
    message: en_US.emailIsRequired,
  },
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: en_US.pleaseEnterValidEmail,
  },
};

const LoginEmailScreen = ({
  navigation,
}: PublicStackNavigationProps<'LoginEmailScreen'>) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<EmailFormType>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });
  const { desktop } = useMedia();
  const renderEmail = ({
    field: { onChange, value },
    fieldState: { error, invalid },
  }: {
    field: ControllerRenderProps<EmailFormType, 'email'>;
    fieldState: ControllerFieldState;
  }) => (
    <>
      <TextInput
        placeholder={en_US.email}
        onChangeText={onChange}
        value={value}
        autoCapitalize={'none'}
        keyboardType={'email-address'}
        autoCorrect={false}
        onSubmitEditing={handleOnSubmit}
        autoFocus={true}
      />
      {invalid ? (
        <BodyM paddingTop={'$1'} fontWeight={'$2'} color={'$accentRed300'}>
          {error?.message}
        </BodyM>
      ) : null}
    </>
  );

  const handleOnSubmit = handleSubmit((data) => {
    navigation.navigate('LoginPasswordScreen', { email: data.email });
  });

  const onForgot = () => {
    navigation.navigate('ForgotPasswordEmailScreen');
  };

  return (
    <ScreenWrapperKAV>
      <YStack
        flex={1}
        justifyContent={desktop ? 'center' : 'space-between'}
        paddingTop={desktop ? '$0' : '$15'}
        paddingHorizontal={'$4'}
        {...(desktop && {
          width: '$150',
          margin: '$auto',
          gap: '$7',
        })}
      >
        <YStack space={'$4'}>
          <HeadingS fontWeight={'$2'}>{en_US.welcomeBack}</HeadingS>
          <Controller
            control={control}
            name={'email'}
            rules={emailRules}
            render={renderEmail}
          />
        </YStack>
        <YStack gap={'$4'}>
          <TouchableOpacity onPress={onForgot} style={styles.forgotPassword}>
            <BodyM color={'$mono400'}>{en_US.forgotPassword}</BodyM>
          </TouchableOpacity>
          <ButtonLarge disabled={!isValid || !isDirty} onPress={handleOnSubmit}>
            {en_US.continue}
          </ButtonLarge>
        </YStack>
      </YStack>
    </ScreenWrapperKAV>
  );
};

export { LoginEmailScreen };
