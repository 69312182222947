import type { NavigationProp } from '@react-navigation/native';
import type { PrivateStackNavigatorParamList } from '@south-street-app/navigation/types';

import { useEffect, useMemo } from 'react';

import { useNavigation } from '@react-navigation/native';
import { useShallow } from 'zustand/react/shallow';

import { useGlobalBottomSheetStore } from '@south-street-app/stores';

const noHandlerContentTypes = [
  'productList',
  'requestTrade',
  'QuotedPrice',
  'tradeInProgress',
  'backendError',
];

const useGlobalBottomSheetControls = () => {
  const navigation =
    useNavigation<NavigationProp<PrivateStackNavigatorParamList>>();
  const { state: storeState, closeBottomSheet } = useGlobalBottomSheetStore(
    useShallow((globalState) => ({
      state: globalState.state,
      closeBottomSheet: globalState.closeBottomSheet,
    })),
  );

  useEffect(() => {
    if (storeState.type === 'tradeConfirmation') {
      closeBottomSheet();

      navigation.navigate('TradeScreens', {
        screen: 'TradeConfirmation',
      });
    }
  }, [closeBottomSheet, navigation, storeState.type]);

  useEffect(() => {
    if (storeState.type === 'tradeTimeout') {
      closeBottomSheet();

      if (storeState.subType === 'home') {
        return navigation.navigate('HomeTab', { screen: 'HomeScreen' });
      }

      navigation.navigate('TradeScreens', {
        screen: 'TradeTimeout',
        params: {
          timeoutType: storeState.subType || 'timeout',
        },
      });
    }
  }, [closeBottomSheet, navigation, storeState.subType, storeState.type]);

  const canDismissBottomSheet = useMemo(
    () => !noHandlerContentTypes.includes(storeState.type || ''),
    [storeState.type],
  );

  const showHandleComponent = useMemo(
    () =>
      noHandlerContentTypes.includes(storeState.type || '') ? null : undefined,
    [storeState.type],
  );

  return {
    canDismissBottomSheet,
    showHandleComponent,
  };
};

export { useGlobalBottomSheetControls };
