import type { ToastShowParams } from 'react-native-toast-message';

import { default as TToast } from 'react-native-toast-message';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useDevStore } from '@south-street-app/stores';

type ToastParams = {
  props?: {
    onPress?: () => void;
    isDismissVisible?: boolean;
  };
} & Omit<ToastShowParams, 'type' | 'props'>;

const showSuccessToast = (params: ToastParams) => {
  TToast.show({
    type: 'success',
    ...params,
  });
};

const showCustomToast = (params: ToastParams) => {
  TToast.show({
    type: 'tomatoToast',
    ...params,
  });
};

const showErrorToast = (params: ToastParams) => {
  TToast.show({
    type: 'error',
    ...params,
  });
};

const showWarningToast = (params: ToastParams) => {
  TToast.show({
    type: 'warning',
    ...params,
  });
};

const showInfoToast = (params: ToastParams) => {
  TToast.show({
    type: 'info',
    ...params,
  });
};

const showDebugToast = (params: ToastParams) => {
  const debugMode = useDevStore.getState().state.debugMode;

  if (debugMode) {
    TToast.show({
      type: 'info',
      ...params,
    });
  }
};

const hideToast = () => TToast.hide();

const Toast = {
  showSuccessToast,
  showErrorToast,
  showWarningToast,
  showInfoToast,
  showDebugToast,
  hideToast,
  showCustomToast,
};

export { Toast };
