import AsyncStorage from '@react-native-async-storage/async-storage';
import { createJSONStorage, persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type DevStateVariables = {
  debugMode: boolean;
};

type DevStateFunctions = {
  setDevData: (data: DevStateVariables) => void;
  resetDevData: () => void;
};

type DevState = {
  state: DevStateVariables;
} & DevStateFunctions;

const STORE_KEY = 'DevStore';

const useDevStore = createWithEqualityFn<DevState>()(
  persist(
    (set) => ({
      state: {
        debugMode: false,
      },
      setDevData: (newState) => set({ state: newState }),
      resetDevData: () =>
        set({
          state: {
            debugMode: false,
          },
        }),
    }),
    {
      name: STORE_KEY,
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
  shallow,
);

export { useDevStore };
