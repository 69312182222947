const themes = {
  light: {
    black: 'rgba(0,0,0,1.00)',
    gray: 'rgba(142,142,147,1.00)',
    white: 'rgba(255,255,255,1.00)',
    mono100: '#FFFFFF',
    mono200: '#EDF0F2',
    mono300: '#D3DCE5',
    mono400: '#9DA7B2',
    mono500: '#76818C',
    mono600: '#444E59',
    mono700: '#2C3640',
    mono800: '#171E26',
    mono900: '#000000',
    brand100: '#CAE1FF',
    brand200: '#65ACFF',
    brand300: '#0053BF',
    brand400: '#0B3880',
    brand500: '#1E3560',
    brand600: '#03183F',
    accentGreen100: '#DAF7D4',
    accentGreen200: '#B4F0A8',
    accentGreen300: '#8FE87D',
    accentGreen400: '#69E052',
    accentGreen500: '#44D926',
    accentGreen600: '#36AD1F',
    accentGreen700: '#298217',
    accentGreen800: '#1B570F',
    accentGreen900: '#0E2B08',
    accentYellow100: '#F7EBD4',
    accentYellow200: '#F0D8A8',
    accentYellow300: '#E8C47D',
    accentYellow400: '#E0B152',
    accentYellow500: '#D99D26',
    accentYellow600: '#AD7E1F',
    accentYellow700: '#825E17',
    accentYellow800: '#573F0F',
    accentYellow900: '#2B1F08',
    accentRed100: '#F7D4DA',
    accentRed200: '#F0A8B4',
    accentRed300: '#E87D8F',
    accentRed400: '#E05269',
    accentRed500: '#D92644',
    accentRed600: '#AD1F36',
    accentRed700: '#821729',
    accentRed800: '#570F1B',
    accentRed900: '#2B080E',
    transparent: 'rgba(0,0,0,0)',
  },
  dark: {
    black: 'rgba(0,0,0,1.00)',
    gray: 'rgba(142,142,147,1.00)',
    white: 'rgba(255,255,255,1.00)',
    mono100: '#FFFFFF',
    mono200: '#EDF0F2',
    mono300: '#D3DCE5',
    mono400: '#9DA7B2',
    mono500: '#76818C',
    mono600: '#444E59',
    mono700: '#2C3640',
    mono800: '#171E26',
    mono900: '#000000',
    brand100: '#CAE1FF',
    brand200: '#65ACFF',
    brand300: '#0053BF',
    brand400: '#0B3880',
    brand500: '#1E3560',
    brand600: '#03183F',
    accentGreen100: '#DAF7D4',
    accentGreen200: '#B4F0A8',
    accentGreen300: '#8FE87D',
    accentGreen400: '#69E052',
    accentGreen500: '#44D926',
    accentGreen600: '#36AD1F',
    accentGreen700: '#298217',
    accentGreen800: '#1B570F',
    accentGreen900: '#0E2B08',
    accentYellow100: '#F7EBD4',
    accentYellow200: '#F0D8A8',
    accentYellow300: '#E8C47D',
    accentYellow400: '#E0B152',
    accentYellow500: '#D99D26',
    accentYellow600: '#AD7E1F',
    accentYellow700: '#825E17',
    accentYellow800: '#573F0F',
    accentYellow900: '#2B1F08',
    accentRed100: '#F7D4DA',
    accentRed200: '#F0A8B4',
    accentRed300: '#E87D8F',
    accentRed400: '#E05269',
    accentRed500: '#D92644',
    accentRed600: '#AD1F36',
    accentRed700: '#821729',
    accentRed800: '#570F1B',
    accentRed900: '#2B080E',
    transparent: 'rgba(0,0,0,0)',
  },
};

export { themes };
