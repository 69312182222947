import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { YStack } from 'tamagui';

import { BodyL, Icon } from '@south-street-app/atoms';
import { ButtonLarge, ScreenWrapper } from '@south-street-app/molecules';
import { useHealthCheck } from '@utility-nyc/react-query-sdk';

const BackendInternalErrorProvider = ({
  children,
  isError,
  onRefetch,
}: {
  children: ReactNode;
  isError: boolean;
  onRefetch: () => void;
}) => {
  const { status, refetch } = useHealthCheck();

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        refetch();
      }, 1000);
    }
  }, [refetch, isError]);

  if (!isError || status !== 'error') {
    return <>{children}</>;
  }

  return (
    <ScreenWrapper>
      <YStack
        justifyContent={'space-between'}
        alignItems={'center'}
        flex={1}
        space={'$2'}
        paddingHorizontal={'$8'}
        backgroundColor={'$white'}
      >
        <YStack
          alignItems={'center'}
          space={'$4'}
          paddingTop={'$15'}
          paddingHorizontal={'$8'}
        >
          <YStack
            borderRadius={'$xl'}
            padding={'$3.5'}
            backgroundColor={'$mono200'}
          >
            <Icon size={'$6'} iconName={'infoIcon'} color={'$black'} />
          </YStack>
          <BodyL textAlign={'center'}>
            {
              'We re sorry. Something went wrong on our side. Please try again, or close and reload the app.'
            }
          </BodyL>
        </YStack>

        <ButtonLarge width={'$100%'} onPress={onRefetch}>
          {'Try again'}
        </ButtonLarge>
      </YStack>
    </ScreenWrapper>
  );
};

export { BackendInternalErrorProvider };
