import type { ToastShowParams } from 'react-native-toast-message';

import { Stack, XStack } from 'tamagui';

import { LabelS } from '@south-street-app/atoms';

type ToastParams = {
  props?: {
    onPress?: () => void;
    isDismissVisible?: boolean;
  };
} & Omit<ToastShowParams, 'type' | 'props'>;

const toastConfig = {
  success: ({ text1 }: ToastParams) => (
    <Stack width={'$100%'}>
      <XStack
        marginHorizontal={'$0.5'}
        padding={'$1'}
        justifyContent={'space-between'}
        backgroundColor={'$white'}
        alignItems={'center'}
      >
        <LabelS flex={0.8} fontWeight={'$2'} color={'$white'}>
          {text1}
        </LabelS>
      </XStack>
    </Stack>
  ),
};

export { toastConfig };
export type { ToastParams };
